
  
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import ApplicationReducer from './Reducers/Application/ApplicationReducer.js';
import candidateReducer from "./Reducers/Candidate/CandidateReducer.js";
import CompanyReducer from './Reducers/CompanyReducer.js';
import PositionReducer from './Reducers/PositionReducer.js';
import UserReducer from "./Reducers/UserReducer"


const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  candidates: candidateReducer,
  application: ApplicationReducer,
  user: UserReducer,
  positions : PositionReducer,
  company : CompanyReducer

});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(reducers, initialState, enhancer);

export default store;