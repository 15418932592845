import React, { useState, useEffect } from "react";
import { List, message, Avatar, Skeleton, Divider } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

import "../App.css";
import { CANDIDATE_ICON, NEW_EMAILS_ICON } from "../Resources/Icons";

function Notifications(props) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setNotifications(props.notifications);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    setNotifications(props.notifications);
    setLoading(false);
  }, [props.notifications]);

  useEffect(() => {
    loadMoreData();
  }, []);

  return (
    <div id="scrollableDiv" className="notifications-list">
      <InfiniteScroll
        dataLength={5}
        hasMore={notifications.length < 1}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={notifications}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              className={"notification-seen-" + item.seen}
            >
              <List.Item.Meta
                avatar={
                  item.type === "Candidate" ? CANDIDATE_ICON : NEW_EMAILS_ICON
                }
                title={item.title}
                description={item.created}
              />
              <div></div>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
}

export default Notifications;
