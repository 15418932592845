import { Layout, Tabs, Col, Row, Button } from "antd";
import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import CompanyEmail from "../Components/Admin/CompanyEmail";
import Departments from "../Components/Admin/Departments";
import GeneralInfo from "../Components/Admin/GeneralInfo";
import {
  editCompany,
  getCompanyById,
} from "../Redux/Actions/Company/CompanyAction";

const { TabPane } = Tabs;

function Admin(props) {
  const dispatch = useDispatch();

  const companyId = useSelector((state) => state.user.userCompany);
  const userCompanies = useSelector((state) => state.user.userCompanys);
  const [currentTab, setCurrentTab] = useState("1");

  const initialCompanyName = useSelector((state) => state.company.companyName);
  const initialTimeZone = useSelector((state) => state.company.timeZone);
  const initialDomains = useSelector((state) => state.company.domains);
  const initialJobsEmail = useSelector((state) => state.company.jobsEmail);
  const initialJobsPassword = useSelector(
    (state) => state.company.jobsPassword
  );
  const initialExecludedEmails = useSelector(
    (state) => state.company.execludedEmails
  );
  const initialImageLogo = useSelector((state) => state.company.logo);
  const initialSendingProtocol = useSelector(
    (state) => state.company.sendingProtocol
  );
  const initialReceivingProtocol = useSelector(
    (state) => state.company.receivingProtocol
  );
  const initialSendingPort = useSelector((state) => state.company.sendingPort);
  const initialReceivingPort = useSelector(
    (state) => state.company.receivingPort
  );
  const initialSendingServer = useSelector(
    (state) => state.company.sendingServer
  );
  const initialReceivingServer = useSelector(
    (state) => state.company.receivingServer
  );
  const initialDepartments = useSelector((state) => state.company.departments);

  const [companyName, setCompanyName] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [domains, setDomains] = useState("");
  const [jobsEmail, setJobsEmail] = useState("");
  const [jobsPassword, setJobsPassword] = useState("");
  const [execludedEmails, setExecludedEmails] = useState("");
  const [imageLogo, setImageLogo] = useState([{}]);
  const [sendingProtocol, setSendingProtocol] = useState("");
  const [receivingProtocol, setReceivingProtocol] = useState("");
  const [sendingPort, setSendingPort] = useState("");
  const [receivingPort, setReceivingPort] = useState("");
  const [sendingServer, setSendingServer] = useState("");
  const [receivingServer, setReceivingServer] = useState("");
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    if (initialCompanyName) setCompanyName(initialCompanyName);
    else setCompanyName("");
  }, [initialCompanyName]);

  useEffect(() => {
    if (initialTimeZone) setTimeZone(initialTimeZone);
    else setTimeZone("");
  }, [initialTimeZone]);

  useEffect(() => {
    if (initialDomains) setDomains(initialDomains);
    else setDomains([]);
  }, [initialDomains]);

  useEffect(() => {
    if (initialJobsEmail) setJobsEmail(initialJobsEmail);
    else setJobsEmail("");
  }, [initialJobsEmail]);

  useEffect(() => {
    if (initialJobsPassword) setJobsPassword(initialJobsPassword);
    else setJobsPassword("");
  }, [initialJobsPassword]);

  useEffect(() => {
    if (initialExecludedEmails) setExecludedEmails(initialExecludedEmails);
    else setExecludedEmails("");
  }, [initialExecludedEmails]);

  useEffect(() => {
    if (initialImageLogo) setImageLogo(initialImageLogo);
    else setImageLogo([{}]);
  }, [initialImageLogo]);

  useEffect(() => {
    if (initialSendingProtocol) setSendingProtocol(initialSendingProtocol);
    else setSendingProtocol("");
  }, [initialSendingProtocol]);

  useEffect(() => {
    if (initialReceivingProtocol)
      setReceivingProtocol(initialReceivingProtocol);
    else setReceivingProtocol("");
  }, [initialReceivingProtocol]);

  useEffect(() => {
    if (initialSendingPort) setSendingPort(initialSendingPort);
    else setSendingPort("");
  }, [initialSendingPort]);

  useEffect(() => {
    if (initialReceivingPort) setReceivingPort(initialReceivingPort);
    else setReceivingPort("");
  }, [initialReceivingPort]);

  useEffect(() => {
    if (initialSendingServer) setSendingServer(initialSendingServer);
    else setSendingServer("");
  }, [initialSendingServer]);

  useEffect(() => {
    if (initialReceivingServer) setReceivingServer(initialReceivingServer);
    else setReceivingServer("");
  }, [initialReceivingServer]);

  useEffect(() => {
    if (initialDepartments) setDepartments(initialDepartments);
    else setDepartments([]);
  }, [initialDepartments]);

  const changeCurrentTab = (event) => {
    setCurrentTab(event.key);
  };

  const saveChanges = (event) => {
    console.log("Admins page");
    console.log(imageLogo);
    dispatch(
      editCompany(
        companyId,
        companyName,
        domains,
        timeZone,
        jobsEmail,
        jobsPassword,
        execludedEmails,
        sendingServer,
        sendingPort,
        sendingProtocol,
        receivingProtocol,
        receivingPort,
        receivingServer,
        imageLogo
      )
    )
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout style={{ height: "100%" }}>
      <Row>
        <Col span={18} style={{ marginLeft: "40px" }}>
          <Tabs>
            <TabPane
              tab="GENERAL INFO"
              key="1"
              onClick={(e) => changeCurrentTab(e)}
            >
              <GeneralInfo
                companyName={companyName}
                setCompanyName={setCompanyName}
                timeZone={timeZone}
                setTimeZone={setTimeZone}
                domains={domains}
                setDomains={setDomains}
                jobsEmail={jobsEmail}
                setJobsEmail={setJobsEmail}
                jobsPassword={jobsPassword}
                setJobsPassword={setJobsPassword}
                execludedEmails={execludedEmails}
                setExecludedEmails={setExecludedEmails}
                imageLogo={imageLogo}
                setImageLogo={setImageLogo}
              />
            </TabPane>
            <TabPane tab="EMAIL" key="2" onClick={(e) => changeCurrentTab(e)}>
              <CompanyEmail
                sendingServer={sendingServer}
                setSendingServer={setSendingServer}
                sendingPort={sendingPort}
                setSendingPort={setSendingPort}
                sendingProtocol={sendingProtocol}
                setSendingProtocol={setSendingProtocol}
                receivingServer={receivingServer}
                setReceivingServer={setReceivingServer}
                receivingPort={receivingPort}
                setReceivingPort={setReceivingPort}
                receivingProtocol={receivingProtocol}
                setReceivingProtocol={setReceivingProtocol}
              />
            </TabPane>
            <TabPane
              tab="DEPARTMENTS"
              key="3"
              onClick={(e) => changeCurrentTab(e)}
            >
              <Departments
                departments={departments}
                setDepartments={setDepartments}
                initialDepartments={initialDepartments}
              />
            </TabPane>
            <TabPane
              tab="MEMBERSHIP PLAN"
              key="4"
              onClick={(e) => changeCurrentTab(e)}
            ></TabPane>
          </Tabs>
        </Col>
        <Col>
          {currentTab == "1" || currentTab == "2" ? (
            <Button onClick={saveChanges}>Save Changes</Button>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  positons: state.positions,
  company: state.company,
});

export default connect(mapStateToProps)(Admin);
