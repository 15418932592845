import { Row, Col, Input, Upload, message } from "antd";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import "../../App.css";
import { getCompanyById } from "../../Redux/Actions/Company/CompanyAction";
import FormInputField from "../FormInputField";

function GeneralInfo(props) {
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const [isFileLoading, setIsFileLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState(false);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    console.log("Haefz sucks");
    console.log(file);
    props.setImageLogo(file);

    return isJpgOrPng && isLt2M;
  };

  useEffect(() => {
    setCurrentImage(props.imageLogo.url);
    console.log(props.imageLogo);
  }, [props.imageLogo]);

  const onRemove = (file) => {
    props.setImageLogo([]);
  };

  const uploadButton = (
    <div>
      {isFileLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Row gutter={[15, 15]} className="admin-generalInfo">
      <Col span={12}>
        <FormInputField
          placeholder="comapany name"
          title={props.companyName ? "Company Name*" : null}
          value={props.companyName}
          onChange={props.setCompanyName}
        />
        <FormInputField
          placeholder="company domains"
          title={props.domains ? "Company domains" : null}
          value={props.domains}
          onChange={props.setDomains}
          compact="true"
        />
        <FormInputField
          placeholder="time zone"
          title={props.timeZone ? "Time Zone*" : null}
          value={props.timeZone}
          onChange={props.setTimeZone}
        />
        <FormInputField
          placeholder="jobs email"
          title={props.jobsEmail ? "Jobs Email*" : null}
          value={props.jobsEmail}
          onChange={props.setJobsEmail}
        />
        <FormInputField
          placeholder="email password"
          title={props.jobsPassword ? "Email Password*" : null}
          value={props.jobsPassword}
          onChange={props.setJobsPassword}
        />
        <FormInputField
          placeholder="excluded Emails"
          title={props.execludedEmails ? "Excluded Emails*" : null}
          value={props.execludedEmails}
          onChange={props.setExecludedEmails}
        />
      </Col>
      <Col span={12} style={{ paddingTop: "10px" }}>
        <Row>Company Logo</Row>
        <Row>
          <div style={{ width: "90%", height: "100%" }}>
            <Upload
              name="companyLogo"
              listType="picture-card"
              showUploadList={true}
              defaultFileList={currentImage}
              beforeUpload={beforeUpload}
              onRemove={onRemove}
              maxCount={1}
            >
              {props.imageLogo ? (
                <img src={currentImage} alt="company logo" />
              ) : (
                uploadButton
              )}
            </Upload>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  company: state.company,
});

export default connect(mapStateToProps)(GeneralInfo);
