
import { getUserNotification as getNotification, markAllNotificationAsRead, markNotificationAsRead } from "../../Api/Notification"
import { GET_USER_NOTIFICATION, MARK_ALL_NOTIFICATIONS_AS_READ, MARK_NOTIFICATION_AS_READ, SHOW_EMAIL, USER_ERROR } from "../types"

export const getUserNotification = (userEmail,companyId) => (dispatch) => {
    return getNotification(userEmail,companyId).then(res => {
        dispatch({
            type:GET_USER_NOTIFICATION,
            payload: res.data
        })
        return res
    }).catch(err => {
        dispatch({
            type:USER_ERROR,
            payload:err
        })
        throw err
    })
}


export const markNotificationRead = (userEmail,notificationId) => (dispatch) => {
    return markNotificationAsRead(userEmail,notificationId).then(res => {
        dispatch({
            type:MARK_NOTIFICATION_AS_READ,
            payload:res
        })
        return res
    }).catch(err => {
        dispatch({
            type:USER_ERROR,
            payload:err
        })
        throw err
    })
}

export const markAllNotificationsRead = (userEmail) => (dispatch) => {
    return markAllNotificationAsRead(userEmail).then(res => {
        dispatch({
            type:MARK_ALL_NOTIFICATIONS_AS_READ,
            payload:res
        })
        return res
    }).catch(err => {
        dispatch({
            type:USER_ERROR,
            payload:err
        })
        throw err
    })
}


export const showEmail = (applicationId,positionName) => (dispatch) => {
    dispatch({
        type:SHOW_EMAIL,
        payload: {applicationId:applicationId,positionName:positionName}
    })
}