import moment from "moment";
export const addTime = (addedTime, type, format = "hh:mm A") => {
  var newTime = moment().add(addedTime, type).format(format);
  return newTime;
};

export const formatTime = (time, format) => {
  return moment(time).format(format);
};

export const currentDate = () => {
  var now = moment();
  return now;
};

export const currentTime = () => {
  var now = moment();
  return moment(now).format("h:mm:ss a");
};
