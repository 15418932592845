import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { Menu, Row, Col, Dropdown, Divider, Card, Button } from "antd";

import LOGO from "../../Icons/icon_white_green_large.png";

import Notifications from "../../Components/Notifications";

import {
  getCompaniesByUserEmail,
  getCompanyById,
  setUserCompany,
} from "../../Redux/Actions/Company/CompanyAction";

import { getUserNotification } from "../../Redux/Actions/UserAction";

import {
  getActionsPerPosition,
  getAllPositions,
} from "../../Redux/Actions/PositionAction";
import {
  getAllNewApplicants,
  getCandidateById,
  getCandidatesByPosition,
} from "../../Redux/Actions/Candidate/CandidateActions";
import { getStages } from "../../Redux/Actions/Stage/StageAction";
import {
  getAllCompanyEmails,
  getApplicationEmails,
  getEmailsByPosition,
} from "../../Redux/Actions/EmailsAction";

function TopBar(props) {
  const DASH_ICON = (
    <svg
      width="18"
      height="12"
      style={{ verticalAlign: "middle" }}
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill="white" />
    </svg>
  );

  const HELP_ICON = (
    <svg
      width="20"
      height="20"
      style={{ verticalAlign: "middle" }}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
        fill="white"
      />
    </svg>
  );

  const NOTIFICATION_ICON = (
    <svg
      width="16"
      height="20"
      style={{ verticalAlign: "middle" }}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.9 20 8 20ZM14 14V9C14 5.93 12.37 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.64 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14ZM12 15H4V9C4 6.52 5.51 4.5 8 4.5C10.49 4.5 12 6.52 12 9V15Z"
        fill="white"
      />
    </svg>
  );

  const { given_name, family_name, name, email, picture } = props.googleUser;
  var logout = props.logout;
  const [companies, setCompanies] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const userCompany = useSelector((state) => state.user.userCompany);
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let companyId = -1;
    let a = searchParams.get("companyId");
    dispatch(getCompaniesByUserEmail(email))
      .then((res) => {
        setCompanies(res.data);
        companyId = props.user.userCompany;
        dispatch(getCompanyById(companyId));
        dispatch(getApplicationEmails(0, 10, companyId));

        /* Actions page */
        dispatch(getActionsPerPosition(companyId));
        dispatch(getAllNewApplicants(companyId));

        /* Candidates Page */
        dispatch(getCandidatesByPosition(null, companyId));
        dispatch(getStages(companyId));
        dispatch(getAllPositions(companyId));

        /* Get Notifications*/
        dispatch(getUserNotification(email, companyId))
          .then((nots) => {
            setNotifications(nots.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (userCompany != -1) {
      dispatch(getCandidatesByPosition(null, userCompany));
      dispatch(getStages(userCompany));
      dispatch(getAllPositions(userCompany));
      dispatch(getAllNewApplicants(userCompany));
      dispatch(getActionsPerPosition(userCompany));
      dispatch(getApplicationEmails(0, 10, userCompany));
      dispatch(getCompanyById(userCompany));
      dispatch(getUserNotification(email, userCompany))
        .then((res) => {
          setNotifications(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userCompany]);

  const changeUserCompany = (event) => {
    const companyId = event.key;
    dispatch(setUserCompany(companyId));
  };

  const LogoutUser = () => {
    logout({ returnTo: "http://localhost:3000" });
  };

  const menu = (
    <Menu>
      <Menu.Item key="emailItem" className="changeCursor">
        {email}
      </Menu.Item>
      <Divider className="menu-divider" />
      <Menu.Item>
        <a onClick={LogoutUser}>logout</a>
      </Menu.Item>
      <Menu.Item key="profileItem">
        <a href="http://localhost:3000/profile">Profile</a>
      </Menu.Item>
      <Divider className="menu-divider" />
      {companies.map((company) => {
        return (
          <Menu.Item key={company.id} onClick={changeUserCompany}>
            {company.companyName}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Menu
      mode="horizontal"
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["sub1"]}
      className="topbar"
    >
      <Row style={{ width: "100%", marginLeft: "1em" }}>
        <Col flex="20px">{DASH_ICON}</Col>

        <Col flex="10px" style={{ marginLeft: "2em" }}>
          <img
            src={LOGO}
            className="logo-img"
            style={{ verticalAlign: "middle" }}
          />
        </Col>
        <Col flex="auto"></Col>
        <Col flex="12px">{HELP_ICON}</Col>

        <Col flex="20 px" style={{ marginLeft: "2em" }}>
          <Dropdown
            overlay={<Notifications notifications={notifications} />}
            placement="bottomLeft"
            arrow
          >
            {NOTIFICATION_ICON}
          </Dropdown>
        </Col>

        <Col flex="80px" style={{ marginLeft: "2em" }}>
          <Dropdown overlay={menu} placement="bottomRight" arrow>
            <img
              src={picture}
              className="profile-image"
              style={{ verticalAlign: "middle" }}
            />
          </Dropdown>
        </Col>
      </Row>
    </Menu>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(TopBar);
