import { Tabs } from "antd";
import ApplicationEmails from "./ApplicationEmails";
import CandidateInformation from "./CandidateInformation";
import Documents from "./Documents";
const { TabPane } = Tabs;

function CandidateTabs(props) {
  return (
    <Tabs defaultActiveKey="2" type="line" a>
      {props.addAbout ? (
        <TabPane tab="About" key="1">
          <CandidateInformation
            currentApplication={props.currentApplication}
            setCurrentApplication={props.setCurrentApplication}
            applications={props.applications}
            name={props.name}
            educationLevel={props.educationLevel}
            emails={props.emails}
            nationality={props.nationality}
          />
        </TabPane>
      ) : null}
      <TabPane tab="Documents" key="2">
        <Documents applicationId={props?.currentApplication.id} />
      </TabPane>
      <TabPane tab="Activity" key="3"></TabPane>
      <TabPane tab="Comments" key="4"></TabPane>
      <TabPane tab="Emails" key="5">
        <ApplicationEmails
          applicationId={props?.currentApplication.id}
          position={props?.currentApplication?.position}
          candidateName={props?.name}
        />
      </TabPane>
      <TabPane tab="Events" key="6"></TabPane>
      <TabPane tab="Feedbacks" key="7"></TabPane>
    </Tabs>
  );
}

export default CandidateTabs;
