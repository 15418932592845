import { POST } from "../Constants/HttpRequest/HttpRequestType";
import { MARK_NOTIFICATION_READ_URL, NOTIFICATION_URL, READ_ALL_NOTIFICATION_URL } from "../Constants/Urls/Urls";
import HandleRequest from "../JSUtils/Http/HttpRequest";


export const getUserNotification = (userEmail,companyId) => {
    return new Promise((resolve,reject) => {
        let form = new FormData()
        form.append('userEmail', userEmail)
        form.append('companyId', companyId)
        const url = NOTIFICATION_URL
        HandleRequest({url:url,method:POST,data:form}).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
        
    })
}

export const markNotificationAsRead = (userEmail, notificationId) => {
    let form = new FormData()
    form.append('userEmail', userEmail)
    form.append('notificationId',notificationId)
    return new Promise((resolve,reject) => {
        const url = NOTIFICATION_URL.concat(MARK_NOTIFICATION_READ_URL)
        HandleRequest({url:url,method:POST,data:form}).then(res=>{
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const markAllNotificationAsRead = (userEmail) => {
    let form = new FormData()
    form.append('userEmail', userEmail)
    return new Promise((resolve,reject) => {
        const url = NOTIFICATION_URL.concat(READ_ALL_NOTIFICATION_URL)
        HandleRequest({url:url,method:POST,data:form}).then(res=>{
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}