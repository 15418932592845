import { Card, Col, Row, Button } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import "../../App.css";
import { editPositionDescription } from "../../Redux/Actions/PositionAction";
import { modules } from "../../Resources/EditorModules";
import { CANCEL_BUTTON, EDIT_PEN } from "../../Resources/Icons";
import TextEditor from "../TextEditor";

function PositionDescription(props) {
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [originalDescription, setOriginalDescription] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [jobId, setJobId] = useState(-1);

  useEffect(() => {
    setDescription(props.description);
    setOriginalDescription(props.description);
    setJobId(props.jobId);
  }, [props]);

  const edit = () => {
    setReadOnly(false);
  };
  const cancel = () => {
    setReadOnly(true);
    setDescription(originalDescription);
  };

  const save = () => {
    dispatch(editPositionDescription(jobId, description)).then((res) => {
      setOriginalDescription(description);
      setReadOnly(true);
    });
  };

  const descriptionTitle = (
    <Row gutter={[16, 8]}>
      <Col>
        <span className="position-description-title">Description</span>

        {readOnly ? (
          <span onClick={edit} className="position-description-edit">
            {" "}
            {EDIT_PEN}
          </span>
        ) : (
          <span onClick={cancel} className="position-description-edit">
            {CANCEL_BUTTON}
          </span>
        )}
      </Col>
    </Row>
  );

  return (
    <Card title={descriptionTitle}>
      {readOnly ? null : (
        <Button onClick={save} className="position-description-save">
          Save
        </Button>
      )}
      <TextEditor
        onChange={setDescription}
        value={description}
        readOnly={readOnly}
        modules={readOnly ? {} : modules}
      />
    </Card>
  );
}

export default PositionDescription;
