import { Layout, Row, Col, Card, Divider, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getAllCompanyEmails,
  getEmailById,
  getEmailsByPosition,
  getEmailsViewer,
} from "../Redux/Actions/EmailsAction";
import moment from "moment";
import "../App";

function Emails(props) {
  const POSITION_ICON = (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.666 6.00008V3.33341H11.3327V6.00008H16.666ZM3.33268 8.66675V23.3334H24.666V8.66675H3.33268ZM24.666 6.00008C26.146 6.00008 27.3327 7.18675 27.3327 8.66675V23.3334C27.3327 24.8134 26.146 26.0001 24.666 26.0001H3.33268C1.85268 26.0001 0.666016 24.8134 0.666016 23.3334L0.679349 8.66675C0.679349 7.18675 1.85268 6.00008 3.33268 6.00008H8.66602V3.33341C8.66602 1.85341 9.85268 0.666748 11.3327 0.666748H16.666C18.146 0.666748 19.3327 1.85341 19.3327 3.33341V6.00008H24.666Z"
        fill="#042C77"
      />
    </svg>
  );

  const dispatch = useDispatch();
  const originalPositionsList = useSelector((state) => state.user.positions);
  const [positionsList, setPositionsList] = useState([]);
  const [currentPosition, setCurrentPosition] = useState({});
  const [currentEmails, setCurrentEmails] = useState([]);
  const [searchText, setSearchText] = useState("");
  const companyId = useSelector((state) => state.user.userCompany);

  const modifyCurrentPosition = (position) => {
    if (!position) return;
    setCurrentPosition(position);

    dispatch(getEmailsByPosition(position.id)).then((res) => {
      setCurrentEmails(res.data);
    });
  };

  const changePosition = (event, position) => {
    modifyCurrentPosition(position);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    let filtered = [];
    let searchInput = e.target.value.toUpperCase();
    if (!e.target.value) {
      filtered = originalPositionsList;
    } else {
      originalPositionsList.forEach((position) => {
        if (position.name.toUpperCase().includes(searchInput)) {
          filtered.push(position);
        }
      });
    }
    setPositionsList(filtered);
  };

  useEffect(() => {
    if (originalPositionsList) {
      modifyCurrentPosition(originalPositionsList[0]);
      setPositionsList(originalPositionsList);
    }
  }, [originalPositionsList]);

  useEffect(() => {
    // if (companyId != -1)
    //   dispatch(getEmailsViewer(companyId)).then((res) => {
    //     console.log(res);
    //   });
  }, [companyId]);

  return (
    <Layout>
      <Col span={5} className="positions-left-column">
        <Card>
          <Row>
            <Input
              onChange={onSearch}
              placeholder="Search Position"
              value={searchText}
              className="candidates-search-bar"
              prefix={<SearchOutlined />}
            />
          </Row>
        </Card>

        {positionsList.map((position) => {
          let clicked = "positions-position-row";
          if (position?.id === currentPosition?.id)
            clicked = "positions-position-row-clicked";
          return (
            <Card className={clicked}>
              <Row
                style={{ paddingLeft: "30px" }}
                onClick={(event) => changePosition(event, position)}
              >
                <Divider
                  style={{ border: "0px", padding: "0px", margin: "20px" }}
                />
                <Col span={4}>{POSITION_ICON}</Col>

                <Col span={14}>
                  <Row>
                    <span className="positions-position-name">
                      {position.name}
                    </span>
                  </Row>

                  <Row>
                    <span className="positions-position-date">
                      {moment(position.date).fromNow()}{" "}
                    </span>
                  </Row>
                </Col>
              </Row>
            </Card>
          );
        })}
      </Col>
      <Col></Col>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  positions: state.positions,
  candidates: state.candidates,
});

export default connect(mapStateToProps)(Emails);
