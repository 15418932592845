//Application
export const EDIT_APPLICATION = "EDIT_APPLICATION";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const GET_APPLICATION_EVENTS = "GET_APPLICATION_EVENTS";
export const GET_APPLICATION_FEEDBACKS = "GET_APPLICATION_FEEDBACKS";
export const CREATE_APPLICATION_FEEDBACK = "CREATE_APPLICATION_FEEDBACK";
export const APPLICATION_ERROR = "APPLICATION_ERROR";

//Candidate
export const GET_CANDIDATE = "GET_CANDIDATE";
export const GET_CANDIDATES = "GET_CANDIDATES";
export const SET_CANDIDATE = "SET_CANDIDATE";
export const EDIT_CANDIDATE = "EDIT_CANDIDATE";
export const LINK_CANDIDATE = "LINK_CANDIDATE";
export const REJECT_CANDIDATE = "REJECT_CANDIDATE";
export const ADD_EMAIL_TO_CANDIDATE = "ADD_EMAIL_TO_CANDIDATE";
export const CANDIDATE_ERROR = "CANDIDATE_ERROR";
export const GET_NEW_APPLICANTS = "GET_NEW_APPLICANTS";
export const CANDIDATES_LOADING = "CANDIDATES_LOADING";
export const NEW_CANDIDATES_LOADING = "NEW_CANDIDATES_LOADING";
//Company
export const GET_COMPANY = "GET_COMPANY";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const ADD_COMPANY = "ADD_COMPANY";
export const EDIT_DEPARTMENT = "EDIT_DEPARTMENT";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const GET_COMPANY_LOGS = "GET_COMPANY_LOGS";
export const COMPANY_ERROR = "COMPANY_ERROR";
export const SET_COMPANY_LOADING = "SET_COMPANY_LOADING";

//Documents
export const GET_DOCUMENT = "GET_DOCUMENT";
export const EDIT_DOCUMENT = "EDIT_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const DOCUMENT_ERROR = "DOCUMENT_ERROR";

//EMAILS
export const GET_COMPANY_EMAILS = "GET_COMPANY_EMAILS";
export const GET_APPLICATION_EMAILS = "GET_APPLICATION_EMAILS";
export const GET_APPLICATION_DELETED_EMAILS = "GET_APPLICATION_DELETED_EMAILS";
export const CHANGE_EMAIL_TO_NEW_EMAIL = "CHANGE_EMAIL_TO_NEW_EMAIL";
export const CHANGE_EMAIL_STATE = "CHANGE_EMAIL_STATE";
export const GET_EMAILS_DATA = "GET_EMAILS_DATA";
export const GET_EMAIL = "GET_EMAIL";
export const DELETE_EMAIL = "DELETE_EMAIL";
export const RESTORE_EMAIL = "RESTORE_EMAIL";
export const SEND_EMAIL = "SEND_EMAIL";
export const EDIT_EMAIL = "EDIT_EMAIL";
export const START_NEW_EMAIL_PROCESS = "START_NEW_EMAIL_PROCESS";
export const RESCHEDULE_EMAIL_SEND_DATE = "RESCHEDULE_EMAIL_SEND_DATE";
export const CHANGE_EMAIL_JOB = "CHANGE_EMAIL_JOB";
export const EMAIL_ERROR = "EMAIL_ERROR";

//Position
export const GET_POSITIONS = "GET_POSITIONS";
export const GET_POSITION = "GET_POSITION";
export const CREATE_POSITION = "CREATE_POSITION";
export const EDIT_POSITION_NAME = "EDIT_POSTION_NAME";
export const EDIT_POSITION_DESC = "EDIT_POSTION_DESC";
export const EDIT_POSITION_OPENING = "EDIT_POSTION_OPENING";
export const GET_POSITION_EMAILS = "GET_POSITION_EMAILS";
export const ADD_EMAIL_TO_POSITION = "ADD_EMAIL_TO_POSITION";
export const GET_ACTIONS_PER_POSITION = "GET_ACTIONS_PER_POSITION";
export const POSITION_ERROR = "POSITION_ERROR";
export const SET_POSITIONS_LOADING = "SET_POSITIONS_LOADING";
export const SET_ACTIONS_LOADING = "SET_ACTIONS_LOADING";

//Support
export const START_SUPPORT = "START_SUPPORT";
export const FINISH_SUPPORT = "FINISH_SUPPORT";
export const SUPPORT_ERROR = "SUPPORT_ERROR";

//Stage
export const ADD_STAGE = "ADD_STAGE";
export const EDIT_STAGE = "EDIT_STAGE";
export const GET_STAGE = "GET_STAGE";
export const DELETE_STAGE = "DELETE_STAGE";
export const STAGE_ERROR = "STAGE_ERROR";

//User
export const GET_COMPANIES = "GET_COMPANIES";
export const SET_USER_COMPANY = "SET_USER_COMPANY";
export const SET_USER_COMPANYS = "SET_USER_COMPANYS";
export const GET_USER_NOTIFICATION = "GET_USER_NOTIFICATION";
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";
export const MARK_ALL_NOTIFICATIONS_AS_READ = "MARK_ALL_NOTIFICATIONS_AS_READ";
export const USER_ERROR = "USER_ERROR";
export const SHOW_EMAIL = "SHOW_EMAIL";

//TEMPLATE
export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const TEMPLATE_ERROR = "TEMPLATE_ERROR";

//----------------------------------------------------------------------------------------------------------------------
