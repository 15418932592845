import { GET, POST } from "../../Constants/HttpRequest/HttpRequestType";
import {
  APPLICATION_URL,
  DELETE_URL,
  DOCUMENTS_URL,
  EDIT_URL,
  UPLOAD_URL,
} from "../../Constants/Urls/Urls";
import HandleRequest from "../../JSUtils/Http/HttpRequest";

export const getDocuments = (applicationId) => {
  return new Promise((resolve, reject) => {
    const url = APPLICATION_URL.concat(DOCUMENTS_URL, applicationId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.info(err);
        reject(err);
      });
  });
};

export const editDocument = (id, type) => {
  let form = new FormData();
  form.append("documentId", id);
  form.append("type", type);
  return new Promise((resolve, reject) => {
    const url = APPLICATION_URL.concact(DOCUMENTS_URL, EDIT_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteCandidateDocument = (id) => {
  let form = new FormData();
  form.append("documentId", id);
  return new Promise((resolve, reject) => {
    const url = APPLICATION_URL.concat(DOCUMENTS_URL, DELETE_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadDocument = (applicationId, files, types) => {
  let form = new FormData();
  form.append("applicationId", applicationId);
  for (let i = 0; i < files.length; i++) {
    form.append("files", files[i]);
  }
  for (let i = 0; i < types.length; i++) {
    form.append(types[i].file, types[i].name);
  }

  return new Promise((resolve, reject) => {
    const url = APPLICATION_URL.concat(UPLOAD_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
