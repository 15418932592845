/* Admin Page */
export const SENDING_EMAIL_ICON = (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.01 3.03L9.52 6.25L2 5.25L2.01 3.03ZM9.51 11.75L2 14.97V12.75L9.51 11.75ZM0.00999999 0L0 7L15 9L0 11L0.00999999 18L21 9L0.00999999 0Z"
      fill="#042C77"
    />
  </svg>
);

export const RECEIVING_EMAIL_ICON = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 6H10.45V3H7.55V6H5L9 10L13 6ZM16 0H1.99C0.88 0 0 0.9 0 2V16C0 17.1 0.88 18 1.99 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V13H5.56C6.25 14.19 7.53 15 9.01 15C10.49 15 11.76 14.19 12.46 13H16V16ZM16 11H11.01C11.01 12.1 10.11 13 9.01 13C7.91 13 7.01 12.1 7.01 11H2L1.99 2H16V11Z"
      fill="#042C77"
    />
  </svg>
);

export const DEPARTMENT_ICON = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4V0H0V18H20V4H10ZM4 16H2V14H4V16ZM4 12H2V10H4V12ZM4 8H2V6H4V8ZM4 4H2V2H4V4ZM8 16H6V14H8V16ZM8 12H6V10H8V12ZM8 8H6V6H8V8ZM8 4H6V2H8V4ZM18 16H10V14H12V12H10V10H12V8H10V6H18V16ZM16 8H14V10H16V8ZM16 12H14V14H16V12Z"
      fill="#042C77"
    />
  </svg>
);
/* -----------------  */
/* CANDIDATE PAGE */
export const REPLY_ICON = (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 4V0L0 7L7 14V9.9C12 9.9 15.5 11.5 18 15C17 10 14 5 7 4Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
);
export const DELETE_ICON = (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
);

export function UPLOAD_ICON(props) {
  return (
    <svg
      width={props.width ? props.width : 16}
      height={props.height ? props.height : 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5455 0H1.45455C0.647273 0 0 0.791111 0 1.77778V14.2222C0 15.2089 0.647273 16 1.45455 16H14.5455C15.3527 16 16 15.2089 16 14.2222V1.77778C16 0.791111 15.3527 0 14.5455 0ZM14.5455 14.24H1.45455V1.76H14.5455V14.24ZM6.54545 8H5.09091L8 4.44444L10.9091 8H9.45455V11.5556H6.54545V8Z"
        fill={props.fill ? props.fill : "#FFF"}
      />
    </svg>
  );
}

export const DOCUMENT_ICON = (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 18V2H9V7H14V18H2Z"
      fill="#042C77"
    />
  </svg>
);
export const THREE_DOTS_ICON = (width = 4, height = 16) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 4 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
);

/* --------------------- */
/* Send Email and Delete Email modal   */

export const DOWN_ARROW = (
  <svg
    width="10"
    height="5"
    viewBox="0 0 10 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0L5 5L10 0H0Z" fill="#042C77" />
  </svg>
);

export const PLUS_BUTTON = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#042C77" />
  </svg>
);

export const ATTACHMENT_ICON = (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 18V2H9V7H14V18H2Z"
      fill="#042C77"
    />
  </svg>
);

/* Position page */
export const EDIT_PEN = (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.06 6.02L11.98 6.94L2.92 16H2V15.08L11.06 6.02ZM14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0ZM11.06 3.19L0 14.25V18H3.75L14.81 6.94L11.06 3.19Z"
      fill="#042C77"
    />
  </svg>
);

export const PEN_ICON = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71667 5.51667L10.4833 6.28333L2.93333 13.8333H2.16667V13.0667L9.71667 5.51667ZM12.7167 0.5C12.5083 0.5 12.2917 0.583333 12.1333 0.741667L10.6083 2.26667L13.7333 5.39167L15.2583 3.86667C15.5833 3.54167 15.5833 3.01667 15.2583 2.69167L13.3083 0.741667C13.1417 0.575 12.9333 0.5 12.7167 0.5ZM9.71667 3.15833L0.5 12.375V15.5H3.625L12.8417 6.28333L9.71667 3.15833Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
);

export const CANCEL_BUTTON = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="#042C77"
    />
  </svg>
);

/*  Link Tab */
export const LINK_ICON = (
  <svg
    width="20"
    height="12"
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4 10.6L2.8 6L7.4 1.4L6 0L0 6L6 12L7.4 10.6ZM12.6 10.6L17.2 6L12.6 1.4L14 0L20 6L14 12L12.6 10.6Z"
      fill="#042C77"
    />
  </svg>
);

export const COPY_ICON = (
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0.75H2C1.175 0.75 0.5 1.425 0.5 2.25V12.75H2V2.25H11V0.75ZM10.25 3.75H5C4.175 3.75 3.5075 4.425 3.5075 5.25L3.5 15.75C3.5 16.575 4.1675 17.25 4.9925 17.25H13.25C14.075 17.25 14.75 16.575 14.75 15.75V8.25L10.25 3.75ZM5 15.75V5.25H9.5V9H13.25V15.75H5Z"
      fill="#042C77"
    />
  </svg>
);

export const LINKEDIN_ICON = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H18.625C19.0398 19.375 19.375 19.0398 19.375 18.625V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM6.18672 16.6023H3.40469V7.65391H6.18672V16.6023ZM4.79688 6.43047C4.47795 6.43047 4.16619 6.3359 3.90102 6.15871C3.63584 5.98153 3.42917 5.72969 3.30712 5.43505C3.18507 5.1404 3.15314 4.81618 3.21536 4.50339C3.27758 4.19059 3.43115 3.90327 3.65667 3.67776C3.88218 3.45225 4.1695 3.29867 4.48229 3.23645C4.79509 3.17423 5.11931 3.20617 5.41395 3.32821C5.7086 3.45026 5.96044 3.65694 6.13762 3.92211C6.3148 4.18729 6.40937 4.49905 6.40937 4.81797C6.40703 5.70859 5.68516 6.43047 4.79688 6.43047ZM16.6023 16.6023H13.8227V12.25C13.8227 11.2117 13.8039 9.87812 12.3766 9.87812C10.9305 9.87812 10.7078 11.0078 10.7078 12.175V16.6023H7.93047V7.65391H10.5977V8.87734H10.6352C11.0055 8.17422 11.9125 7.43125 13.2672 7.43125C16.0844 7.43125 16.6023 9.28516 16.6023 11.6945V16.6023V16.6023Z"
      fill="#042C77"
    />
  </svg>
);

export const STACKOVERFLOW_ICON = (
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.989063 13.4736L10.297 15.3894L10.6908 13.5463L1.38281 11.6305L0.989063 13.4736ZM2.22047 9.10859L10.8384 13.0625L11.625 11.3638L3.00844 7.38688L2.22047 9.10859ZM4.60875 4.93672L11.9213 10.9264L13.1278 9.49578L5.81531 3.50609L4.60875 4.93672ZM9.375 0.5L7.80984 1.61562L13.4733 9.13297L15 8L9.375 0.5ZM0.75 17.75H10.3125V15.875H0.75V17.75Z"
      fill="#042C77"
    />
  </svg>
);

/* --------- */
export const POSITION_ICON = (
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.666 6.00008V3.33341H11.3327V6.00008H16.666ZM3.33268 8.66675V23.3334H24.666V8.66675H3.33268ZM24.666 6.00008C26.146 6.00008 27.3327 7.18675 27.3327 8.66675V23.3334C27.3327 24.8134 26.146 26.0001 24.666 26.0001H3.33268C1.85268 26.0001 0.666016 24.8134 0.666016 23.3334L0.679349 8.66675C0.679349 7.18675 1.85268 6.00008 3.33268 6.00008H8.66602V3.33341C8.66602 1.85341 9.85268 0.666748 11.3327 0.666748H16.666C18.146 0.666748 19.3327 1.85341 19.3327 3.33341V6.00008H24.666Z"
      fill="#042C77"
    />
  </svg>
);

export const LOCATION_ICON = (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00065 0.333252C2.42065 0.333252 0.333984 2.41992 0.333984 4.99992C0.333984 8.49992 5.00065 13.6666 5.00065 13.6666C5.00065 13.6666 9.66732 8.49992 9.66732 4.99992C9.66732 2.41992 7.58065 0.333252 5.00065 0.333252ZM5.00065 6.66659C4.08065 6.66659 3.33398 5.91992 3.33398 4.99992C3.33398 4.07992 4.08065 3.33325 5.00065 3.33325C5.92065 3.33325 6.66732 4.07992 6.66732 4.99992C6.66732 5.91992 5.92065 6.66659 5.00065 6.66659Z"
      fill="black"
      fill-opacity="0.36"
    />
  </svg>
);
/* ---------------------- */
/* Notification */
export const NEW_EMAILS_ICON = (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
      className="notifications-email-icon"
      fill-opacity="0.54"
    />
  </svg>
);

export const CANDIDATE_ICON = (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 7C8.93 7 10.5 5.43 10.5 3.5C10.5 1.57 8.93 0 7 0C5.07 0 3.5 1.57 3.5 3.5C3.5 5.43 5.07 7 7 7ZM7 2C7.83 2 8.5 2.67 8.5 3.5C8.5 4.33 7.83 5 7 5C6.17 5 5.5 4.33 5.5 3.5C5.5 2.67 6.17 2 7 2ZM7.05 12H2.77C3.76 11.5 5.47 11 7 11C7.11 11 7.23 11.01 7.34 11.01C7.68 10.28 8.27 9.68 8.98 9.2C8.25 9.07 7.56 9 7 9C4.66 9 0 10.17 0 12.5V14H7V12.5C7 12.33 7.02 12.16 7.05 12ZM14.5 9.5C12.66 9.5 9 10.51 9 12.5V14H20V12.5C20 10.51 16.34 9.5 14.5 9.5ZM15.71 7.68C16.47 7.25 17 6.44 17 5.5C17 4.12 15.88 3 14.5 3C13.12 3 12 4.12 12 5.5C12 6.44 12.53 7.25 13.29 7.68C13.65 7.88 14.06 8 14.5 8C14.94 8 15.35 7.88 15.71 7.68Z"
      className="notfications-candidate-icon"
      fill-opacity="0.54"
    />
  </svg>
);
/* ----------- */

export const FLAG_ICON = (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66634 6.50008L7.83301 4.83341H2.83301V3.76675C3.33301 3.48341 3.66634 2.95008 3.66634 2.33341C3.66634 1.41675 2.91634 0.666748 1.99967 0.666748C1.08301 0.666748 0.333008 1.41675 0.333008 2.33341C0.333008 2.95008 0.666341 3.48341 1.16634 3.76675V16.5001H2.83301V13.1667H6.99967L7.83301 14.8334H13.6663V6.50008H8.66634ZM11.9997 13.1667H8.66634L7.83301 11.5001H2.83301V6.50008H6.99967L7.83301 8.16675H11.9997V13.1667Z"
      fill="#042C77"
    />
  </svg>
);

export const MAIL_ICON = (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3337 1.99992C17.3337 1.08325 16.5837 0.333252 15.667 0.333252H2.33366C1.41699 0.333252 0.666992 1.08325 0.666992 1.99992V11.9999C0.666992 12.9166 1.41699 13.6666 2.33366 13.6666H15.667C16.5837 13.6666 17.3337 12.9166 17.3337 11.9999V1.99992ZM15.667 1.99992L9.00033 6.15825L2.33366 1.99992H15.667ZM15.667 11.9999H2.33366V3.66658L9.00033 7.83325L15.667 3.66658V11.9999Z"
      fill="#042C77"
    />
  </svg>
);

export const EDUCATION_ICON = (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99967 0.5L0.833008 5.5L4.16634 7.31667V12.3167L9.99967 15.5L15.833 12.3167V7.31667L17.4997 6.40833V12.1667H19.1663V5.5L9.99967 0.5ZM15.683 5.5L9.99967 8.6L4.31634 5.5L9.99967 2.4L15.683 5.5ZM14.1663 11.325L9.99967 13.6L5.83301 11.325V8.225L9.99967 10.5L14.1663 8.225V11.325Z"
      fill="#042C77"
    />
  </svg>
);

export const STAR_ICON = (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3337 6.70008L11.342 6.18341L9.00033 0.666748L6.65866 6.19175L0.666992 6.70008L5.21699 10.6417L3.85033 16.5001L9.00033 13.3917L14.1503 16.5001L12.792 10.6417L17.3337 6.70008ZM9.00033 11.8334L5.86699 13.7251L6.70033 10.1584L3.93366 7.75841L7.58366 7.44175L9.00033 4.08341L10.4253 7.45008L14.0753 7.76675L11.3087 10.1667L12.142 13.7334L9.00033 11.8334Z"
      fill="#042C77"
    />
  </svg>
);

export const DATE_ICON = (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.83333 8.16675H5.5V9.83342H3.83333V8.16675ZM15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H4.66667V2.33341H11.3333V0.666748H13V2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008ZM2.16667 5.66675H13.8333V4.00008H2.16667V5.66675ZM13.8333 15.6667V7.33342H2.16667V15.6667H13.8333ZM10.5 9.83342H12.1667V8.16675H10.5V9.83342ZM7.16667 9.83342H8.83333V8.16675H7.16667V9.83342Z"
      fill="#042C77"
    />
  </svg>
);

export const WORLD_ICON = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99199 0.666748C4.39199 0.666748 0.666992 4.40008 0.666992 9.00008C0.666992 13.6001 4.39199 17.3334 8.99199 17.3334C13.6003 17.3334 17.3337 13.6001 17.3337 9.00008C17.3337 4.40008 13.6003 0.666748 8.99199 0.666748ZM14.767 5.66675H12.3087C12.042 4.62508 11.6587 3.62508 11.1587 2.70008C12.692 3.22508 13.967 4.29175 14.767 5.66675ZM9.00033 2.36675C9.69199 3.36675 10.2337 4.47508 10.592 5.66675H7.40866C7.76699 4.47508 8.30866 3.36675 9.00033 2.36675ZM2.55033 10.6667C2.41699 10.1334 2.33366 9.57508 2.33366 9.00008C2.33366 8.42508 2.41699 7.86675 2.55033 7.33342H5.36699C5.30033 7.88342 5.25033 8.43341 5.25033 9.00008C5.25033 9.56675 5.30033 10.1167 5.36699 10.6667H2.55033ZM3.23366 12.3334H5.69199C5.95866 13.3751 6.34199 14.3751 6.84199 15.3001C5.30866 14.7751 4.03366 13.7167 3.23366 12.3334ZM5.69199 5.66675H3.23366C4.03366 4.28341 5.30866 3.22508 6.84199 2.70008C6.34199 3.62508 5.95866 4.62508 5.69199 5.66675ZM9.00033 15.6334C8.30866 14.6334 7.76699 13.5251 7.40866 12.3334H10.592C10.2337 13.5251 9.69199 14.6334 9.00033 15.6334ZM10.9503 10.6667H7.05033C6.97533 10.1167 6.91699 9.56675 6.91699 9.00008C6.91699 8.43341 6.97533 7.87508 7.05033 7.33342H10.9503C11.0253 7.87508 11.0837 8.43341 11.0837 9.00008C11.0837 9.56675 11.0253 10.1167 10.9503 10.6667ZM11.1587 15.3001C11.6587 14.3751 12.042 13.3751 12.3087 12.3334H14.767C13.967 13.7084 12.692 14.7751 11.1587 15.3001ZM12.6337 10.6667C12.7003 10.1167 12.7503 9.56675 12.7503 9.00008C12.7503 8.43341 12.7003 7.88342 12.6337 7.33342H15.4503C15.5837 7.86675 15.667 8.42508 15.667 9.00008C15.667 9.57508 15.5837 10.1334 15.4503 10.6667H12.6337Z"
      fill="#042C77"
    />
  </svg>
);

export const USER_ICON = (width, height) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9997 2.86659C12.5463 2.86659 13.7997 4.11992 13.7997 5.66658C13.7997 7.21325 12.5463 8.46659 10.9997 8.46659C9.45301 8.46659 8.19967 7.21325 8.19967 5.66658C8.19967 4.11992 9.45301 2.86659 10.9997 2.86659ZM10.9997 14.8666C14.9597 14.8666 19.133 16.8133 19.133 17.6666V19.1333H2.86634V17.6666C2.86634 16.8133 7.03967 14.8666 10.9997 14.8666ZM10.9997 0.333252C8.05301 0.333252 5.66634 2.71992 5.66634 5.66658C5.66634 8.61325 8.05301 10.9999 10.9997 10.9999C13.9463 10.9999 16.333 8.61325 16.333 5.66658C16.333 2.71992 13.9463 0.333252 10.9997 0.333252ZM10.9997 12.3333C7.43967 12.3333 0.333008 14.1199 0.333008 17.6666V21.6666H21.6663V17.6666C21.6663 14.1199 14.5597 12.3333 10.9997 12.3333Z"
      fill="#042C77"
    />
  </svg>
);

export const EYE_ICON = (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1.875C11.8425 1.875 14.3775 3.4725 15.615 6C14.3775 8.5275 11.85 10.125 9 10.125C6.15 10.125 3.6225 8.5275 2.385 6C3.6225 3.4725 6.1575 1.875 9 1.875ZM9 0.375C5.25 0.375 2.0475 2.7075 0.75 6C2.0475 9.2925 5.25 11.625 9 11.625C12.75 11.625 15.9525 9.2925 17.25 6C15.9525 2.7075 12.75 0.375 9 0.375ZM9 4.125C10.035 4.125 10.875 4.965 10.875 6C10.875 7.035 10.035 7.875 9 7.875C7.965 7.875 7.125 7.035 7.125 6C7.125 4.965 7.965 4.125 9 4.125ZM9 2.625C7.14 2.625 5.625 4.14 5.625 6C5.625 7.86 7.14 9.375 9 9.375C10.86 9.375 12.375 7.86 12.375 6C12.375 4.14 10.86 2.625 9 2.625Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
);
