import {
  getCompanyLogs as getLogs,
  getCompanyById as getById,
  editCompany as edit,
  addNewCompany as add,
  getUserCompanies,
  editDepartmentById as editDepById,
  addDepartment as addDep,
} from "../../../Api/Company/Company";
import {
  ADD_COMPANY,
  COMPANY_ERROR,
  EDIT_COMPANY,
  EDIT_DEPARTMENT,
  ADD_DEPARTMENT,
  GET_COMPANY,
  GET_COMPANY_LOGS,
  SET_USER_COMPANY,
  SET_USER_COMPANYS,
  SET_COMPANY_LOADING,
} from "../../types";

export const getCompanyLogs = () => (dispatch) => {
  return getLogs()
    .then((res) => {
      dispatch({
        type: GET_COMPANY_LOGS,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: COMPANY_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const getCompanyById = (companyId) => (dispatch) => {
  dispatch({
    type: SET_COMPANY_LOADING,
    payload: true,
  });
  return getById(companyId)
    .then((res) => {
      dispatch({
        type: GET_COMPANY,
        payload: res.data,
      });
      dispatch({
        type: SET_COMPANY_LOADING,
        payload: false,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: COMPANY_ERROR,
        payload: err,
      });
      dispatch({
        type: SET_COMPANY_LOADING,
        payload: false,
      });
      throw err;
    });
};

export const editCompany =
  (
    companyId,
    name,
    domains,
    timeZone,
    jobsEmail,
    emailsPassword,
    execludedEmails,
    sendingServer,
    sendingPort,
    sendingProtocol,
    receivingProtocol,
    receivingPort,
    receivingServer,
    attachments
  ) =>
  (dispatch) => {
    return edit(
      companyId,
      name,
      domains,
      timeZone,
      jobsEmail,
      emailsPassword,
      execludedEmails,
      sendingServer,
      sendingPort,
      sendingProtocol,
      name,
      receivingProtocol,
      receivingPort,
      receivingServer,
      attachments
    )
      .then((res) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: {
            id: companyId,
            companyName: name,
            domains: domains,
            timeZone: timeZone,
            jobsEmail: jobsEmail,
            jobsPassword: emailsPassword,
            execludedEmails: execludedEmails,
            sendingServer: sendingServer,
            sendingPort: sendingPort,
            sendingProtocol: sendingProtocol,
            jobsEmailUsername: name,
            receivingProtocol: receivingProtocol,
            receivingPort: receivingPort,
            receivingServer: receivingServer,
            logo: attachments,
          },
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ERROR,
          payload: err,
        });
        console.log(err);
        throw err;
      });
  };

export const addCompany =
  (name, domain, email, location, time_zone) => (dispatch) => {
    return add(name, domain, email, location, time_zone)
      .then((res) => {
        dispatch({
          type: ADD_COMPANY,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ERROR,
          payload: err,
        });
        throw err;
      });
  };

export const getCompaniesByUserEmail = (userEmail) => (dispatch) => {
  return getUserCompanies(userEmail)
    .then((res) => {
      // dispatch({
      //      type:GET_COMPANIES,
      //      payload: res.data
      // })
      dispatch({
        type: SET_USER_COMPANYS,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      // dispatch({
      //     type:COMPANY_ERROR,
      //     payload: err
      // })
      throw err;
    });
};

export const setUserCompany = (companyId) => (dispatch) => {
  dispatch({
    type: SET_USER_COMPANY,
    payload: companyId,
  });
};

export const editDepartmentById = (id, name, location) => (dispatch) => {
  return editDepById(id, name, location)
    .then((res) => {
      dispatch({
        type: EDIT_DEPARTMENT,
        payload: { id: id, name: name, location: location },
      });
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const addDepartment = (companyId, name, location) => (dispatch) => {
  return addDep(companyId, name, location)
    .then((res) => {
      dispatch({
        type: ADD_DEPARTMENT,
        payload: { name: name, location: location },
      });
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
