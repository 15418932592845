import { DELETE_APPLICATION, APPLICATION_ERROR, EDIT_APPLICATION } from "../../types";

const initialState ={};

export default function (state = initialState, action) {
    switch (action.type) {
        case EDIT_APPLICATION:
            return {
                ...state,
                ...action.payload
            };
        
        case DELETE_APPLICATION:
            return {
                ...state,
                ...action.payload
            }

        case APPLICATION_ERROR:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}