import {
  getRejectTemplate as getReject,
  getAutomatedTemplate as getAutomated,
  getRequestTemplate as getRequestTemp,
  getTemplates,
  editTemplate as edit,
  createTemplate as create,
} from "../../Api/Template";
import {
  CREATE_TEMPLATE,
  EDIT_TEMPLATE,
  GET_ALL_TEMPLATES,
  GET_TEMPLATE,
  TEMPLATE_ERROR,
} from "../types";

export const getRejectTemplate = (companyId) => (dispatch) => {
  return getReject(companyId)
    .then((res) => {
      dispatch({
        type: GET_TEMPLATE,
        payload: res["data"],
      });
      return res["data"];
    })
    .catch((err) => {
      dispatch({
        type: TEMPLATE_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const getAutomatedTemplate = (companyId) => (dispatch) => {
  return getAutomated(companyId)
    .then((res) => {
      dispatch({
        type: GET_TEMPLATE,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: TEMPLATE_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const getRequestTemplate = (companyId) => (dispatch) => {
  return getRequestTemp(companyId)
    .then((res) => {
      dispatch({
        type: GET_TEMPLATE,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: TEMPLATE_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const getAllTemplates =
  (companyId, force = false) =>
  (dispatch) => {
    return getTemplates(companyId)
      .then((res) => {
        dispatch({
          type: GET_ALL_TEMPLATES,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: TEMPLATE_ERROR,
          payload: err,
        });
        throw err;
      });
  };

export const editTemplate =
  (
    id,
    name,
    content,
    withEvent,
    position,
    stage,
    type,
    attachments,
    timeDuration,
    templatesAttachmentsToDelete,
    companyId
  ) =>
  (dispatch) => {
    return edit(
      id,
      name,
      content,
      withEvent,
      position,
      stage,
      type,
      attachments,
      timeDuration,
      templatesAttachmentsToDelete,
      companyId
    )
      .then((res) => {
        dispatch({
          type: EDIT_TEMPLATE,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: TEMPLATE_ERROR,
          payload: err,
        });
        throw err;
      });
  };

export const createTemplate =
  (name, content, withEvent, position, stage, type, timeDuration, companyId) =>
  (dispatch) => {
    return create(
      name,
      content,
      withEvent,
      position,
      stage,
      type,
      timeDuration,
      companyId
    )
      .then((res) => {
        dispatch({
          type: CREATE_TEMPLATE,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: TEMPLATE_ERROR,
          payload: err,
        });
        throw err;
      });
  };
