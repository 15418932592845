import { Table, Skeleton } from "antd";
import { useEffect, useState } from "react";

function TableSkeletons(props) {
  const [numberOfRows, setNumberOfRows] = useState(
    props.numberOfRows ? props.numberOfRows : 6
  );
  const [widthArray, setWidthArray] = useState([]);
  const [sourceArray, setSourceArray] = useState([]);
  useEffect(() => {
    if (props.numberOfRows) setNumberOfRows(props.numberOfRows);
    let tempWidthArray = [];
    let tempSourceArray = [];
    for (let i = 0; i < numberOfRows; i++) {
      tempWidthArray.push("100%");
      tempSourceArray.push(1);
    }
    setSourceArray([...tempSourceArray]);
    setWidthArray([...tempWidthArray]);
  }, [props]);
  return (
    <Table
      columns={props.items}
      dataSource={sourceArray}
      locale={{
        emptyText: (
          <Skeleton
            round={true}
            paragraph={{
              rows: 6,
              width: widthArray,
            }}
          />
        ),
      }}
    />
  );
}

export default TableSkeletons;
