import ButtonSkeleton from "../../Components/Skeletons/Button Skeleton";
import InputSkeleton from "../../Components/Skeletons/InputSkeleton";
import TableSkeletons from "../../Components/Skeletons/TableSkeletons";
import { Row, Col, Skeleton } from "antd";
function CandidatesTableSkeleton(props) {
  const colums = [
    {
      title: "Candidate",
      key: "candidate__name",
      width: "30%",
      render: (text, record) => (
        <Skeleton paragraph={{ rows: 0 }} active={true} />
      ),
    },
    {
      title: "Position",
      key: "position__name",
      width: "30%",
      render: (text, record) => (
        <Skeleton paragraph={{ rows: 0 }} active={true} />
      ),
    },
    {
      title: "Stage",
      key: "stage__name",
      width: "15%",
      render: (text, record) => (
        <Skeleton paragraph={{ rows: 0 }} active={true} />
      ),
    },
    {
      title: "Applied",
      key: "last_updated",
      width: "10%",
      render: (text, record) => (
        <Skeleton paragraph={{ rows: 0 }} active={true} />
      ),
      // ...getColumnSearchProps('last_updated'),
    },
    {
      title: "Status",
      key: "statusFormat",
      width: "10%",
      render: (text, record) => (
        <Skeleton paragraph={{ rows: 0 }} active={true} />
      ),
    },
    {
      title: "Action",
      key: "Operation",
      fixed: "right",
      width: "5%",
      render: (text, record) => (
        <Skeleton paragraph={{ rows: 0 }} active={true} />
      ),
    },
  ];

  return (
    <div>
      {" "}
      <Row>
        <Col span={24}>
          <TableSkeletons items={colums} numberOfRows={props.numberOfRows} />
        </Col>
      </Row>
    </div>
  );
}

export default CandidatesTableSkeleton;
