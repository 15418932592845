import { Row, Input } from "antd";
import { inputStyle, labelStyle } from "../Resources/Styles";

export default function FormInputField(props) {
  return (
    <div style={{ padding: "10px" }}>
      <Row>
        <p style={labelStyle}>{props.title}</p>
      </Row>
      <Row>
        <Input
          placeholder={props.placeholder}
          style={{ ...inputStyle, marginBottom: "15px" }}
          value={props.value}
          suffix={props.suffix}
          prefix={props.prefix}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          type={props.type}
        />
      </Row>
    </div>
  );
}
