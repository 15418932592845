import {
  GET_ACTIONS_PER_POSITION,
  SHOW_EMAIL,
  GET_POSITION_EMAILS,
  SET_ACTIONS_LOADING,
} from "../types";

const initialState = {
  actions: {},
  isActionsLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACTIONS_PER_POSITION:
      // console.log(action.payload)
      state.actions = {};
      state.actions = action.payload;
      return {
        ...state,

        // actions: action.payload
      };
    case SHOW_EMAIL:
      const positionName = action.payload.positionName;
      const applicationId = action.payload.applicationId;
      for (
        let i = 0;
        i < state.actions[positionName].Candidates.emailsInfo.length;
        i++
      ) {
        if (
          state.actions[positionName].Candidates.emailsInfo[i].applicationId ===
          applicationId
        ) {
          if (state.actions[positionName].Candidates.emailsInfo[i].show)
            state.actions[positionName].Candidates.emailsInfo[i].show =
              !state.actions[positionName].Candidates.emailsInfo[i].show;
          else state.actions[positionName].Candidates.emailsInfo[i].show = true;

          break;
        }
      }
      return {
        ...state,
      };
    case GET_POSITION_EMAILS:
      return {
        ...state,
      };
    case SET_ACTIONS_LOADING:
      state.isActionsLoading = action.payload;
      return {
        ...state,
      };
    default:
      return { ...state };
  }
}
