import { Skeleton, Row, Col, Card, Divider } from "antd";
import InputSkeleton from "../../Components/Skeletons/InputSkeleton";
import { POSITION_ICON } from "../../Resources/Icons";

function PositionsSkeletons(props) {
  const positionsList = [1, 2, 3, 4, 5];
  return (
    <Row>
      {positionsList.map((_) => {
        return (
          <Col xl={24} l={24} md={12} sm={24} xs={24}>
            <Card>
              <Divider
                style={{
                  border: "0px",
                  padding: "0px",
                  margin: "20px",
                }}
              />
              <Col>{POSITION_ICON}</Col>

              <Col>
                <Row>
                  <span className="positions-position-name">
                    <InputSkeleton size={"small"} active={true} />
                  </span>
                </Row>
                <Row>
                  <span className="positions-location-icon">
                    <span className="positions-position-location">
                      <InputSkeleton size={"small"} active={true} />
                    </span>
                  </span>
                </Row>
                <Row>
                  <span className="positions-position-date">
                    <InputSkeleton size={"small"} active={true} />
                  </span>
                </Row>
              </Col>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}

export default PositionsSkeletons;
