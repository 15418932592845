const rejectionReasons = [
  "Candidate did not meet basic qualifications",
  "Candidate is ineligible to work in location",
  "More qualified candidate selected",
  "Candidate withdrew",
  "Candidate not considered",
  "Reason not specified",
];

const sources = [
  "Linked In",
  "Online",
  "AUB Careers",
  "Bayt",
  "Hirelebanese",
  "AlgoTraders",
];

export const getSources = () => {
  return sources;
};
export const getRejectionReasons = () => {
  return rejectionReasons;
};

export const getTemplateTypes = () => {
  return [
    "Automated",
    "Request",
    "Confirmation",
    "Scheduled",
    "Rejection",
    "Hired",
  ];
};
