import { Skeleton } from "antd";
function LineSkeleton(props) {
  return (
    <Skeleton
      paragraph={{ rows: props.rows ? props.rows : 0 }}
      active={props.active ? props.active : true}
      size={props.size ? props.size : "default"}
      block={props.block ? props.block : true}
      className={props.className ? props.className : null}
    />
  );
}

export default LineSkeleton;
