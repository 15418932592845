import {
  CANDIDATES_LOADING,
  GET_CANDIDATES,
  GET_NEW_APPLICANTS,
  SET_CANDIDATE,
} from "../../types";

/*
informations contains : name,email,position applied

*/
const initialState = {
  newApplicants: {},
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NEW_APPLICANTS:
      state.newApplicants = action.payload;
      return {
        ...state,
      };
    case CANDIDATES_LOADING:
      state.isLoading = action.payload;
      return { ...state };
    case SET_CANDIDATE:
      return {
        ...state,
      };

    default:
      return state;
  }
}
