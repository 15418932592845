//Common Urls
export const EDIT_URL = "edit/";
export const DELETE_URL = "delete/";
export const DELETED_URL = "deleted/";
export const UPLOAD_URL = "upload/";
export const CREATE_URL = "create/";
export const ADD_URL = "add/";
export const RETRY_URL = "retry/";
export const ALL_URL = "all/";
export const REJECT_URL = "reject/";
export const REQUEST_URL = "request/";
export const CONSTANTS_URL = "constants/";
export const ACTIONS_URL = "actions/";
//Application Urls
export const APPLICATION_URL = "application/";
export const DOCUMENTS_URL = "documents/";
export const START_SUPPORT_URL = "startSupport/";
export const FINISH_SUPPORT_URL = "finishSupport/";
//Candidate Urls
export const LINK_URL = "link/";
export const CANDIDATE_URL = "candidate/";
export const MATCHING_URL = "matching/";
export const STATUS_URL = "status/";
export const STATISTICS_URL = "statistics/";
export const NEW_APPLICANTS_URL = "newApplicants/";
//Company Urls
export const COMPANY_URL = "company/";
export const COMPANY_LOG_URL = "log/company/";
export const CONSTANT_COMPANY_URL = "constants/company/";
export const ADD_NEW_COMPANY_URL = "addNewCompany/";
export const USER_URL = "user/";
//Department Urls
export const DEPARTMENT_URL = "department/";
//Stage Urls
export const STAGE_URL = "stage/";
export const CONSTANT_STAGE_URL = "constants/stage/";
//Email Urls
export const EMAIL_URL = "email/";
export const NEW_EMAILS_URL = "newEmails/";
export const EMAILS_VIEWER_URL = "emailsViewer/";
export const MOVE_TO_NEW_URL = "moveToNew/";
export const INFO_URL = "info/";
export const LINK_TO_APPLICATION_URL = "linkToApplication/";
export const START_NEW_PROCESS_URL = "startNewProcess/";
export const LINK_TO_POSITION_URL = "linkToPosition/";
export const LINK_TO_CANDIDATE_URL = "linkToNewCandidate/";
export const RESTORE_URL = "restore/";
export const RESCHEDULE_URL = "reschedule/";
export const CHANGE_JOB_URL = "changeJob/";
//Event Urls
export const EVENT_URL = "event/";
//Feedback Urls
export const FEEDBACK_URL = "feedback/";
//Notification Urls
export const NOTIFICATION_URL = "notification/";
export const MARK_NOTIFICATION_READ_URL = "markAsReadByUserNotification/";
export const READ_ALL_NOTIFICATION_URL = "markAsReadByUser/";
//Position Urls
export const POSITION_URL = "position/";
export const CHANGE_NAME_URL = "changeName/";
export const CHANGE_JOB_OPENING_URL = "ChangeJobOpenings/";
//Template Urls
export const TEMPLATE_URL = "template/";
export const AUTOMATED_URL = "automated/";
