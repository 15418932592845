import {
  getAllEmails,
  getDeletedEmails,
  getEmailsData,
  getNewEmails,
  moveEmailtoNewEmails,
  retrySendingEmail as sendEmailAgain,
  getEmailById as getById,
  getEmailsByPosition as getByPosition,
  getEmailsByApplication as getByApplication,
  StartNewProcess,
  linkToPosition,
  deleteEmailById as deleteById,
  restoreEmailById as restoreById,
  sendEmail as send,
  editEmail as edit,
  linkEmailToNewCandidate,
  rescheduleSendDate,
  changeEmailJob as changeJob,
} from "../../Api/Email";
import {
  CHANGE_EMAIL_STATE,
  DELETE_EMAIL,
  EMAIL_ERROR,
  GET_APPLICATION_DELETED_EMAILS,
  GET_APPLICATION_EMAILS,
  GET_COMPANY_EMAILS,
  GET_EMAIL,
  GET_EMAILS_DATA,
  SEND_EMAIL,
  START_NEW_EMAIL_PROCESS,
  CHANGE_EMAIL_JOB,
  RESCHEDULE_EMAIL_SEND_DATE,
  ADD_EMAIL_TO_CANDIDATE,
  EDIT_EMAIL,
  GET_POSITION_EMAILS,
} from "../types";

export const getAllCompanyEmails = (companyId) => (dispatch) => {
  return getAllEmails(companyId)
    .then((res) => {
      dispatch({
        type: GET_EMAILS_DATA,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      // dispatch({
      //     type:EMAIL_ERROR,
      //     payload:err
      // })
      throw err;
    });
};

export const getApplicationEmails = (fromI, toI, companyId) => (dispatch) => {
  return getNewEmails(fromI, toI, companyId)
    .then((res) => {
      dispatch({
        type: GET_APPLICATION_EMAILS,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const getApplicationDeletedEmails = (fromI, toI) => (dispatch) => {
  return getDeletedEmails(fromI, toI)
    .then((res) => {
      dispatch({
        type: GET_APPLICATION_DELETED_EMAILS,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const getEmailsViewer = (companyId) => (dispatch) => {
  return getEmailsData(companyId)
    .then((res) => {
      dispatch({
        type: GET_EMAILS_DATA,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const changeEmailToNewEmails = (emailId) => (dispatch) => {
  return moveEmailtoNewEmails(emailId)
    .then((res) => {
      dispatch({
        type: CHANGE_EMAIL_TO_NEW_EMAIL,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const retrySending = (emailId) => (dispatch) => {
  return sendEmailAgain(emailId)
    .then((res) => {
      dispatch({
        type: CHANGE_EMAIL_STATE,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });

      throw err;
    });
};

export const getEmailById = (emailId) => (dispatch) => {
  return getById(emailId)
    .then((res) => {
      dispatch({
        type: GET_EMAIL,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });

      throw err;
    });
};

export const getEmailsByPosition = (positionId) => (dispatch) => {
  return getByPosition(positionId)
    .then((res) => {
      dispatch({
        type: GET_POSITION_EMAILS,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });

      throw err;
    });
};

export const getEmailsByApplication = (applicationId) => (dispatch) => {
  return getByApplication(applicationId)
    .then((res) => {
      // dispatch({
      //   type: GET_APPLICATION_EMAILS,
      //   payload: res,
      // });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });

      throw err;
    });
};

export const startNewProcess =
  (emailId, positionId, candidateId, linkAction, emailContent, sendRejection) =>
  (dispatch) => {
    return StartNewProcess(
      emailId,
      positionId,
      candidateId,
      linkAction,
      emailContent,
      sendRejection
    )
      .then((res) => {
        dispatch({
          type: START_NEW_EMAIL_PROCESS,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: EMAIL_ERROR,
          payload: err,
        });
        throw err;
      });
  };

export const linkEmailToPosition = (emails, positionId) => (dispatch) => {
  return linkToPosition(emails, positionId)
    .then((res) => {
      dispatch({
        type: ADD_EMAIL_TO_POSITION,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const deleteEmailById = (emails, reasonToDeleted) => (dispatch) => {
  return deleteById(emails, reasonToDeleted)
    .then((res) => {
      dispatch({
        type: DELETE_EMAIL,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });
      return err;
    });
};

export const restoreEmailById = (emails) => (dispatch) => {
  return restoreById(emails)
    .then((res) => {
      dispatch({
        type: GET_EMAILS_DATA,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const sendEmail =
  (
    emailId,
    applicationId,
    toEmail,
    subject,
    templateId,
    stageId,
    messageContent,
    attachments,
    sendNow,
    sendDate,
    sendTime,
    createEvent,
    eventLocation,
    eventDuration,
    companyId
  ) =>
  (dispatch) => {
    return send(
      emailId,
      applicationId,
      toEmail,
      subject,
      templateId,
      stageId,
      messageContent,
      attachments,
      sendNow,
      sendDate,
      sendTime,
      createEvent,
      eventLocation,
      eventDuration,
      companyId
    )
      .then((res) => {
        dispatch({
          type: SEND_EMAIL,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: EMAIL_ERROR,
          payload: err,
        });
        throw err;
      });
  };

export const editEmail =
  (
    emailId,
    applicationId,
    toEmail,
    subject,
    templateId,
    stageId,
    messageContent,
    attachments,
    sendNow,
    sendDate,
    sendTime,
    createEvent
  ) =>
  (dispatch) => {
    return edit(
      emailId,
      applicationId,
      toEmail,
      subject,
      templateId,
      stageId,
      messageContent,
      attachments,
      sendNow,
      sendDate,
      sendTime,
      createEvent
    )
      .then((res) => {
        dispatch({
          type: EDIT_EMAIL,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: EMAIL_ERROR,
          payload: err,
        });
        throw err;
      });
  };

export const linkEmailToCandidate =
  (
    emailId,
    positionId,
    candidateName,
    candidateEmail,
    stageId,
    appliedDate,
    source,
    attachmentsTypes,
    linkAction,
    emailContent,
    sendRejection,
    rejectionReason
  ) =>
  (dispatch) => {
    return linkEmailToNewCandidate(
      emailId,
      positionId,
      candidateName,
      candidateEmail,
      stageId,
      appliedDate,
      source,
      attachmentsTypes,
      linkAction,
      emailContent,
      sendRejection,
      rejectionReason
    )
      .then((res) => {
        dispatch({
          type: ADD_EMAIL_TO_CANDIDATE,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: EMAIL_ERROR,
          payload: err,
        });
        throw err;
      });
  };

export const rescheduleEmailSendDate =
  (emailId, sendDate, sendTime) => (dispatch) => {
    return rescheduleSendDate(emailId, sendDate, sendTime)
      .then((res) => {
        dispatch({
          type: RESCHEDULE_EMAIL_SEND_DATE,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: EMAIL_ERROR,
          payload: err,
        });
        throw err;
      });
  };

export const changeEmailJob = (emailId, jobId) => (dispatch) => {
  return changeJob(emailId, jobId)
    .then((res) => {
      dispatch({
        type: CHANGE_EMAIL_JOB,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: EMAIL_ERROR,
        payload: err,
      });
      throw err;
    });
};
