import {
  ADD_DEPARTMENT,
  EDIT_COMPANY,
  EDIT_DEPARTMENT,
  GET_COMPANY,
  SET_COMPANY_LOADING,
} from "../types";

const initialState = {
  departments: [],
  isCompanyLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY:
      state = action.payload;
      return { ...state };
    case EDIT_COMPANY:
      return {
        ...action.payload,
      };
    case EDIT_DEPARTMENT:
      state.departments[action.payload.index].name = action.payload.name;
      state.departments[action.payload.index].location =
        action.payload.location;
      return {
        ...state,
      };
    case SET_COMPANY_LOADING:
      state.isCompanyLoading = action.payload;
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
}
