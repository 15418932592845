import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getEmailsByApplication } from "../../Redux/Actions/EmailsAction";
import { Row, Col, Card, Button } from "antd";
import EmailModal from "../Email/EmailModal";
import { getAllTemplates } from "../../Redux/Actions/TemplateAction";
import DeleteEmailModal from "../Email/DeleteEmailModal";
import { DELETE_ICON, REPLY_ICON } from "../../Resources/Icons";
import { buttonStyle } from "../../Resources/Styles";
function ApplicationEmails(props) {
  const dispatch = useDispatch();
  const [emailId, setEmailId] = useState(-1);
  const [emails, setEmails] = useState([]);
  const [emailModal, setEmailModal] = useState(false);
  const [deleteEmailModal, setDeleteEmailModal] = useState(false);
  const [senderEmail, setSenderEmail] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [templates, setTemplates] = useState([]);
  const [subject, setSubject] = useState("");
  const companyId = useSelector((state) => state.user.userCompany);

  const showEmail = (event, email, i) => {
    var newEmails = emails;
    newEmails[i].show = !newEmails[i].show;
    setEmails([...newEmails]);
  };

  const replyToEmail = (event, email) => {
    event.preventDefault();
    event.stopPropagation();
    setSenderEmail(email.from_email);
    setEmailId(email.id);
    setReceiverEmail(email.to_email);
    setSubject(email.subject);
    setEmailModal(true);
  };

  const deleteEmail = (email, i) => {
    setDeleteEmailModal(true);
    setEmailId(email.id);
    setSubject(email.subject);
  };

  useEffect(() => {
    if (props.applicationId) {
      dispatch(getEmailsByApplication(props.applicationId)).then((res) => {
        for (let i in res.data) {
          res.data[i].show = false;
        }
        setEmails(res.data);
      });
    }
  }, [props.applicationId]);

  useEffect(() => {
    if (!props.position) return;

    if (companyId != -1) {
      dispatch(getAllTemplates(companyId)).then((res) => {
        var templatesList = res;
        templatesList = templatesList.filter(
          (template) => template.position.position_id === props.position.id
        );

        setTemplates(templatesList);
      });
    }
  }, [companyId, props.position]);

  return (
    <>
      {emailModal ? (
        <EmailModal
          applicationId={props.applicationId}
          emailId={emailId}
          receiverEmail={senderEmail}
          senderEmail={receiverEmail}
          setEmailModal={setEmailModal}
          templates={templates}
          setTemplates={setTemplates}
          position={props.position}
          candidateName={props.candidateName}
          subject={subject}
          visible={emailModal}
          setEmails={setEmails}
        />
      ) : null}
      {deleteEmailModal ? (
        <DeleteEmailModal
          emailId={emailId}
          setDeleteEmailModal={setDeleteEmailModal}
          visible={deleteEmailModal}
          subject={subject}
          emails={emails}
          setEmails={setEmails}
        />
      ) : null}
      {emails.length > 0 ? (
        emails.map((email, i) => {
          return (
            <Card onClick={(event) => showEmail(event, email, i)}>
              <Row
                justify="space-between"
                style={
                  email.show
                    ? {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                        padding: "10px",
                        margin: "0",
                      }
                    : null
                }
              >
                <Col>
                  <span>{email.from_name}</span>
                  <span style={{ marginLeft: "7px" }}>{"-"}</span>
                  <span style={{ marginLeft: "7px" }}>{email.subject}</span>
                </Col>
                <Col>
                  <span
                    style={{ verticalAlign: "middle" }}
                    className="date-grey"
                  >
                    {email.sent_date}
                  </span>
                  {email.show ? (
                    <>
                      <span
                        style={{ marginLeft: "17px" }}
                        onClick={(e) => {
                          replyToEmail(e, email);
                        }}
                      >
                        {REPLY_ICON}
                      </span>
                      <span
                        style={{ marginLeft: "17px" }}
                        onClick={() => deleteEmail(email, i)}
                      >
                        {DELETE_ICON}
                      </span>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row style={{ marginTop: "20px" }}>
                {email.show ? (
                  <Row>
                    <span dangerouslySetInnerHTML={{ __html: email.message }} />
                  </Row>
                ) : null}
              </Row>
            </Card>
          );
        })
      ) : (
        <Button title="Compose" block style={buttonStyle}>
          Compose
        </Button>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ApplicationEmails);
