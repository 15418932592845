import { connect } from "react-redux";

import React, { useEffect, useState } from "react";
import "../../App.css";
import { Row, Col } from "antd";
import FormInputField from "../FormInputField";
import {
  RECEIVING_EMAIL_ICON,
  SENDING_EMAIL_ICON,
} from "../../Resources/Icons";

function CompanyEmail(props) {
  const textStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#042C77",
    marginLeft: "10px",
  };

  return (
    <Row gutter={[24, 24]} className="admin-generalInfo">
      <Col span={12}>
        <div style={{ padding: "10px" }}>
          <Row>
            {SENDING_EMAIL_ICON} <p style={textStyle}>Sending Email Info</p>
          </Row>
        </div>
        <FormInputField
          placeholder="sending server"
          title={props.sendingServer ? "Sending Server*" : null}
          value={props.sendingServer}
          onChange={props.setSendingServer}
        />
        <FormInputField
          placeholder="sending port"
          title={props.sendingPort ? "Sending Port*" : null}
          value={props.sendingPort}
          onChange={props.setSendingPort}
        />
        <FormInputField
          placeholder="sending protocol"
          title={props.sendingProtocol ? "Sending Protocol*" : null}
          value={props.sendingProtocol}
          onChange={props.setSendingProtocol}
        />
      </Col>
      <Col span={12}>
        <div style={{ padding: "10px" }}>
          <Row>
            {RECEIVING_EMAIL_ICON} <p style={textStyle}>Receiving Email Info</p>
          </Row>
        </div>
        <FormInputField
          placeholder="receiving server"
          title={props.receivingServer ? "Receiving Server*" : null}
          value={props.receivingServer}
          onChange={props.setReceivingServer}
        />
        <FormInputField
          placeholder="receiving port"
          title={props.receivingPort ? "Receiving Port*" : null}
          value={props.receivingPort}
          onChange={props.setReceivingPort}
        />
        <FormInputField
          placeholder="receiving protocol"
          title={props.receivingProtocol ? "Receiving Protocol*" : null}
          value={props.receivingProtocol}
          onChange={props.setReceivingProtocol}
        />
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(CompanyEmail);
