import { Table, Button, Menu, Dropdown, Input, Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getStatus } from "../Constants/Status";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

import "../App.css";

import AddCandidateModal from "../Components/AddCandidateModal";
import { getCandidateById } from "../Redux/Actions/Candidate/CandidateActions";
import RejectCandidateModal from "../Components/Modals/RejectCandidateModal";
import CandidateTabs from "../Components/Candidate/CandidateTabs";

import CandidatesTableSkeleton from "./Skeletons/CandidatesTableSkeleton";

function Candidates(props) {
  const threeDots = (
    <svg
      width="12"
      height="24"
      viewBox="0 0 4 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
        className="candidates-dropdown-button"
        fill-opacity="0.54"
      />
    </svg>
  );
  const navigate = useNavigate();
  const [candidatesList, setCandidatesList] = useState([]);
  const originalCandidatesList = useSelector(
    (state) => state.user.allCandidates
  );
  const dispatch = useDispatch();
  const params = useParams();
  const stateCompanyId = useSelector((state) => state.user.userCompany);
  const [companyId, setCompanyId] = useState(-1);
  const stages = useSelector((state) => state.user.stages);
  const positions = useSelector((state) => state.user.positions);
  const [positionsList, setPositionsList] = useState([]);
  const [stagesList, setStagesList] = useState([]);
  const isLoading = useSelector((state) => state.user.isCandidateLoading);
  const [status, setStatus] = useState([]);
  const [searchText, setSearchtext] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const [rejectCandidateModal, setRejectCandidateModal] = useState(false);
  const [candidateId, setCandidateId] = useState(-1);
  const [candidateName, setCandidateName] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [positionName, setPositionName] = useState("");
  const [applicationId, setApplicationId] = useState(-1);
  const [appliedDate, setAppliedDate] = useState("");

  const [applications, setApplications] = useState([]);
  const [currentApplication, setCurrentApplication] = useState({});
  const [showCurrentApplication, setShowCurrentApplication] = useState(false);
  const [emails, setEmails] = useState([]);
  const [nationality, setNationality] = useState("");
  const [name, setName] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [age, setAge] = useState(-1);

  const dropdDownMenu = (text, record) => {
    return (
      <Menu className="candidates-action-menu">
        <Menu.Item
          key="Reject"
          onClick={(event) => rejectApplication(event, record)}
        >
          Reject
        </Menu.Item>
        <Menu.Item key="edit Application">Edit application</Menu.Item>
      </Menu>
    );
  };

  const colums = [
    {
      title: "Candidate",
      dataIndex: "candidate__name",
      key: "candidate__name",
      width: "30%",
      sorter: (a, b) => {
        return a.candidate__name.localeCompare(b.candidate__name);
      },
      // ...getColumnSearchProps('candidate__name'),
    },
    // {
    //   title: "Email",
    //   dataIndex: "candidate__emails",
    //   key: "candidate__emails",
    //   width: "25%",
    //   sorter: (a, b) => {
    //     return a.candidate__emails.localeCompare(b.candidate__emails);
    //   },
    //   // ...getColumnSearchProps('candidate__emails'),
    // },
    {
      title: "Position",
      dataIndex: "position__name",
      key: "position__name",
      width: "30%",
      sorter: (a, b) => {
        return a.position__name.localeCompare(b.position__name);
      },
      filters: positionsList,
      filterMode: "tree",
      onFilter: (value, record) => record.position__name.startsWith(value),
    },
    {
      title: "Stage",
      dataIndex: "stage__name",
      key: "stage__name",
      width: "15%",
      sorter: (a, b) => {
        return a.stage__name.localeCompare(b.stage__name);
      },
      filters: stagesList,
      filterMode: "tree",
      onFilter: (value, record) => record.stage__name.startsWith(value),
    },
    {
      title: "Applied",
      dataIndex: "last_updated",
      key: "last_updated",
      width: "10%",
      sorter: (a, b) => {
        const c = new Date(a.last_updated);
        const d = new Date(b.last_updated);
        return c - d;
      },
      // ...getColumnSearchProps('last_updated'),
    },
    {
      title: "Status",
      dataIndex: "statusFormat",
      key: "statusFormat",
      defaultFilteredValue: ["In Process"],
      width: "10%",
      filters: status,
      filterMode: "tree",
      onFilter: (value, record) => record.status.startsWith(value),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Dropdown
            overlay={dropdDownMenu(text, record)}
            placement="bottomLeft"
            arrow
          >
            <span className="candidates-dropdown-button">{threeDots}</span>
          </Dropdown>
        </div>
      ),
    },
  ];
  const rejectApplication = (event, record) => {
    event.domEvent.stopPropagation();
    setCandidateName(record.candidate__name);
    setCandidateEmail(record.candidate__emails);
    setPositionName(record.position__name);
    setAppliedDate(record.applied_date);
    setApplicationId(record.id);
    setCandidateId(record.candidate__id);
    setRejectCandidateModal(true);
  };

  const onSearch = (e) => {
    setSearchtext(e.target.value);
    let filtered = [];
    let searchInput = e.target.value.toUpperCase();
    if (!e.target.value) {
      filtered = originalCandidatesList;
    } else {
      originalCandidatesList.forEach((candidate) => {
        if (candidate.candidate__name.toUpperCase().includes(searchInput)) {
          filtered.push(candidate);
        }
      });
    }
    setCandidatesList(filtered);
  };

  useEffect(() => {
    var statusList = [];
    getStatus().map((status) => {
      statusList.push({ text: status, value: status });
    });
    setStatus(statusList);
    console.log(params);
    if (params) {
      setCompanyId(parseInt(params.companyId));
      setCandidateId(params.candidateId);
      setApplicationId(params.applicationId);
      getCandidate(parseInt(params.candidateId));
    }
  }, []);

  useEffect(() => {
    setCandidatesList([...originalCandidatesList]);
  }, [originalCandidatesList]);

  useEffect(() => {
    var currentStages = [];
    for (let i in Object.entries(stages)) {
      currentStages.push({ text: stages[i].name, value: stages[i].name });
    }
    setStagesList(currentStages);
  }, [stages]);

  useEffect(() => {
    var currentPositions = [];
    for (let i in Object.entries(positions)) {
      currentPositions.push({
        text: positions[i].name,
        value: positions[i].name,
      });
    }
    setPositionsList(currentPositions);
  }, [positions]);

  useEffect(() => {
    if (params.companyId != -1) {
      setCompanyId(parseInt(params.companyId));
      getCandidate(params.candidateId);
    }
  }, [params]);

  useEffect(() => {
    if (stateCompanyId != -1) {
      setCompanyId(stateCompanyId);
    }
  }, [stateCompanyId]);

  const addCandidate = (event) => {
    setModalVisible(true);
  };

  const getCandidate = (candidateId) => {
    console.log("here");
    console.log(companyId);
    console.log(candidateId);
    dispatch(getCandidateById(candidateId, params.companyId)).then((res) => {
      setAge(res.data.age);
      setApplications([...res.data.applications]);
      for (let i in res.data.applications) {
        console.log(parseInt(res.data.applications[i].id));
        if (
          parseInt(res.data.applications[i].id) ===
          parseInt(params.applicationId)
        ) {
          setCurrentApplication({ ...res.data.applications[i] });
          break;
        }
      }
      //setCurrentApplication({ ...res.data.applications[0] });
      setShowCurrentApplication(true);
      setEducationLevel(res.data.educationLevel);
      setEmails(res.data.emails);
      setName(res.data.name);
      setNationality(res.data.nationality);
    });
  };

  const goToCandidate = (record, rowIndex) => {
    return {
      onClick: () => {
        navigate(
          `/candidate/${companyId}/${record.candidate__id}/${record.id}`
        );
      }, // click row
    };
  };

  return isLoading ? (
    <CandidatesTableSkeleton tableItems={colums} />
  ) : (
    <div>
      <Row justify="start" gutter={10} style={{ marginBottom: "10px" }}>
        <Col>
          <Button className="candidates-button-add" onClick={addCandidate}>
            + ADD CANDIDATE
          </Button>
        </Col>
        {modalVisible ? (
          <AddCandidateModal
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
          />
        ) : null}
        {rejectCandidateModal ? (
          <RejectCandidateModal
            visible={rejectCandidateModal}
            setVisible={setRejectCandidateModal}
            applicationId={applicationId}
            candidateName={candidateName}
            candidateEmail={candidateEmail}
            positionName={positionName}
            appliedDate={appliedDate}
          />
        ) : null}

        <Col>
          <Input
            onChange={onSearch}
            placeholder="Search Records"
            value={searchText}
            className="candidates-search-bar"
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row>

      <Table
        rowClassName={"default-cursor"}
        columns={colums}
        onRow={goToCandidate}
        dataSource={candidatesList}
        pagination={{
          defaultPageSize: 6,
          pageSizeOptions: ["6", "10", "25", "50"],
        }}
      />

      <Row>
        <Col span={24}>
          {showCurrentApplication ? (
            <CandidateTabs
              currentApplication={currentApplication}
              setCurrentApplication={setCurrentApplication}
              applications={applications}
              name={name}
              educationLevel={educationLevel}
              emails={emails}
              nationality={nationality}
              addAbout={true}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    positions: state.positions,
    candidates: state.candidates,
  };
};

export default connect(mapStateToProps)(Candidates);
