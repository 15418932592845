import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { Modal, Row, Col, Divider, Menu, Card } from "antd";
import { getRejectionReasons } from "../../Constants/Constants";
import { getRejectTemplate } from "../../Redux/Actions/TemplateAction";
import { titleStyle, blackTextStyle } from "../../Resources/Styles";
import MySwitch from "../MySwitch";
import DropDownInputField from "../DropDownInputField";
import { rejectCandidate } from "../../Redux/Actions/Candidate/CandidateActions";

function RejectCandidateModal(props) {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.user.userCompany);
  const [rejectionsReasonsList, setRejectionsReasonsList] = useState([]);
  const [rejectTemplate, setRejectTemplate] = useState({});
  const [applicationId, setApplicationId] = useState(-1);
  const [candidateId, setCandidateId] = useState(-1);
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [positionName, setPositionName] = useState("");
  const [appliedDate, setAppliedDate] = useState("");
  const [sendRejectionEmail, setSendRejectionEmail] = useState(true);
  const [rejectionReason, setRejectionReason] = useState("");
  const [content, setContent] = useState("");
  const rejectionsReasonsMenu = (
    <Menu>
      {rejectionsReasonsList.map((rejectReason) => {
        return (
          <Menu.Item
            key={rejectReason}
            onClick={() => setRejectionReason(rejectReason)}
          >
            {rejectReason}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const closeModal = () => {
    setSendRejectionEmail(true);
    setContent("");
    setRejectionReason(rejectionsReasonsList[0]);
    props.setVisible(!props.visible);
  };

  const onCancel = () => {
    closeModal();
  };

  useEffect(() => {
    setRejectionsReasonsList([...getRejectionReasons()]);
    setRejectionReason(rejectionsReasonsList[0]);
  }, []);

  const confirmRejection = () => {
    dispatch(
      rejectCandidate(
        candidateId,
        applicationId,
        content,
        sendRejectionEmail,
        rejectionReason
      )
    ).then((res) => {
      console.log(res);

      closeModal();
    });
  };

  useEffect(() => {
    if (companyId != -1) {
      dispatch(getRejectTemplate(companyId)).then((res) => {
        console.log(res);
        setRejectTemplate(res);
        setContent(res.content.replace("${Name}", candidateName));
      });
    }
  }, [companyId, props]);

  useEffect(() => {
    setCandidateName(props.candidateName);
    setCandidateEmail(props.candidateEmail);
    setPositionName(props.positionName);
    setApplicationId(props.applicationId);
    setAppliedDate(props.appliedDate);
    setCandidateId(props.candidateId);
  }, [props]);

  return (
    <Modal
      visible={props.visible}
      onCancel={onCancel}
      okText="Confirm"
      onOk={confirmRejection}
      title={<span style={titleStyle}>Reject this Candidate?</span>}
      width={"50%"}
      height={"50%"}
    >
      <Row
        style={{ ...blackTextStyle, padding: "20px" }}
        justify="space-between"
      >
        <Col>
          <span>{candidateName}</span>
        </Col>
        <Col>
          <span>{candidateEmail}</span>
        </Col>
      </Row>
      <Row
        style={{ ...blackTextStyle, padding: "20px" }}
        justify="space-between"
      >
        <Col>
          <span>{positionName}</span>
        </Col>
        <Col>
          <span>{appliedDate}</span>
        </Col>
      </Row>
      <Row style={{ padding: "19px" }}>
        <MySwitch
          isCheckedText="Send rejection email"
          isNotCheckedText=" "
          checked={sendRejectionEmail}
          onChange={() => setSendRejectionEmail(!sendRejectionEmail)}
        />
      </Row>
      <Card>
        <Row>
          <DropDownInputField
            width={200}
            overlay={rejectionsReasonsMenu}
            value={rejectionReason}
            placeholder="Select a Rejection Reason"
            title="Rejection reason"
          />
        </Row>
        <Divider />
        <Row>
          <span dangerouslySetInnerHTML={{ __html: content }} />
        </Row>
      </Card>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(RejectCandidateModal);
