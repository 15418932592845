import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { Modal, Row, Col } from "antd";
import { deleteEmailById } from "../../Redux/Actions/EmailsAction";
import {
  blackTextStyle,
  buttonTextStyle,
  greyTextStyle,
  labelStyle,
  titleStyle,
} from "../../Resources/Styles";
import FormInputField from "../FormInputField";

function DeleteEmailModal(props) {
  const DEFAULT_REASON = "Not Recruitment";
  const dispatch = useDispatch();
  const [reasonToDeleted, setReasonToDeleted] = useState(DEFAULT_REASON);
  const [emails, setEmails] = useState([]);
  const companyName = useSelector((state) => state.company.companyName);
  const companyEmail = useSelector((state) => state.company.jobsEmail);

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    props.setDeleteEmailModal(!props.visible);
    setReasonToDeleted(DEFAULT_REASON);
  };

  const onOk = () => {
    dispatch(deleteEmailById(emails, reasonToDeleted)).then((res) => {
      console.log(res);
      var tempEmails = props.emails;
      var index = -1;
      for (let i in tempEmails) {
        if (tempEmails[i].id === props.emailId) {
          index = i;
          break;
        }
      }
      tempEmails.splice(index, 1);
      props.setEmails([...tempEmails]);

      closeModal();
    });
  };

  useEffect(() => {
    var emailsList = [];
    emailsList.push(props.emailId);
    setEmails([...emailsList]);
  }, [props.emailId]);

  return (
    <Modal
      visible={props.visible}
      title={
        <span style={titleStyle}>
          Are you sure you want to delete this email?
        </span>
      }
      width={"50%"}
      onCancel={onCancel}
      okText="Confirm"
      onOk={onOk}
    >
      <Row justify="space-between" gutter={[15, 15]}>
        <Col>
          <span style={blackTextStyle}>{props.subject}</span>
        </Col>

        <Col>
          <span style={blackTextStyle}>{companyName}</span>
          <span style={blackTextStyle}>{"<" + companyEmail + ">"}</span>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <span style={greyTextStyle}>
            The Reason why You want to delete this Email
          </span>
        </Col>
      </Row>
      <FormInputField value={reasonToDeleted} onChange={setReasonToDeleted} />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  company: state.company,
});

export default connect(mapStateToProps)(DeleteEmailModal);
