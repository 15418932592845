import { GET, POST } from "../../Constants/HttpRequest/HttpRequestType";
import { ADD_URL, CONSTANT_STAGE_URL, DELETE_URL, EDIT_URL, STAGE_URL } from "../../Constants/Urls/Urls";
import HandleRequest from "../../JSUtils/Http/HttpRequest";

/*
todo:
  -getStorageStages
  -getCompany

*/

export const getStages = (companyId,force = false) => {
  
        //console.log("getting stages from server");
  return new Promise((resolve, reject) => {      
    const url = STAGE_URL.concat( companyId, "/");
    HandleRequest({url : url, method : GET})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  })
};

export const addNewStage = (companyId, name, location, order) => {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append("name", name);
      form.append("location", location);
      form.append("companyId", companyId);
      form.append("order", order);
  
      const url = CONSTANT_STAGE_URL +ADD_URL
        HandleRequest({url:url,method:POST,data:form})
        .then((res) => {
          //console.log("Company Stage added ");
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };


export const editStageById = (id, name, location, order) => {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append("id", id);
      form.append("name", name);
      form.append("location", location);
      form.append("order", order);
  
      const url = CONSTANT_STAGE_URL + EDIT_URL
      HandleRequest({url : url,method:POST,data:form})
        .then((res) => {
          //console.log("Company Department edited ");
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
};


export const deleteStage = (deleteStageId, newStageId) => {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append("deleteStageId", deleteStageId);
      form.append("newStageId", newStageId);
  
      for (let k in fields) {
        form.append(k, fields[k]);
      }
      const url = CONSTANT_STAGE_URL + DELETE_URL
      HandleRequest({url : url, method : POST, data : form})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };