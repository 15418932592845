import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function TextEditor(props) {
  return (
    <ReactQuill
      style={{
        width: props.width ? props.width : "100%",
        height: props.height ? props.height : "100%",
      }}
      theme={props.theme ? props.theme : "snow"}
      value={props.value}
      onChange={props.onChange}
      modules={props.modules}
      readOnly={props.readOnly}
    />
  );
}

export default TextEditor;
