export const StatusMapping = {
    In_Process: "In Process",
    REJECTED: "Rejected",
    ON_HOLD: "On Hold",
    HIRED: "Hired",
  };
  
const status = [StatusMapping.In_Process, StatusMapping.REJECTED, StatusMapping.ON_HOLD, StatusMapping.HIRED];
  
export const getStatus = () => {
    return status;
  };


export const getColorByStatus = (status) => {
  if (status == In_Progress) {
    return "green";
  }
  if (status == REJECTED) {
    return "red";
  }
  if (status == ON_HOLD) {
    return "orange";
  }
  return "primary";
};