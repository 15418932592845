import { Modal, Row, Col, Menu } from "antd";

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import FormInputField from "./FormInputField";
import DropDownInputField from "./DropDownInputField";
import TextEditor from "./TextEditor";
import { titleStyle } from "../Resources/Styles";
import MySwitch from "./MySwitch";
import {
  createPosition,
  getAllPositions,
  getPositionById,
} from "../Redux/Actions/PositionAction";
import { modules } from "../Resources/EditorModules";

function AddPositionModal(props) {
  const dispatch = useDispatch();
  const departments = useSelector((state) => state.company.departments);
  const companyId = useSelector((state) => state.user.userCompany);
  const [positionTitle, setPositionTitle] = useState("");
  const [currentDepartment, setCurrentDepartment] = useState({});
  const [testDuration, setTestDuration] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [positionDescription, setPositionDescription] = useState("");

  const closeModal = () => {
    props.setPositionModal(!props.visible);
    setPositionTitle("");
    setTestDuration(null);
    setIsOpen(false);
    setPositionDescription("");
  };

  const onCancel = () => {
    closeModal();
  };

  const onOk = () => {
    dispatch(
      createPosition(
        positionTitle,
        positionDescription,
        testDuration,
        currentDepartment.id,
        isOpen,
        companyId
      )
    ).then((res) => {
      dispatch(getAllPositions(companyId));
      closeModal();
    });
  };

  const changeTestDuration = (value) => {
    setTestDuration(value[0]);
  };

  const departmentsMenu = (
    <Menu>
      {departments.map((department) => {
        return (
          <Menu.Item
            key={department.id}
            onClick={() => setCurrentDepartment(department)}
          >
            {department.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  useEffect(() => {
    if (departments && departments.length > 0) {
      setCurrentDepartment(departments[0]);
    }
  }, [departments]);

  return (
    <Modal
      title={<span style={titleStyle}>Add position</span>}
      visible={props.visible}
      onCancel={onCancel}
      okText="Confirm"
      onOk={onOk}
    >
      <Row>
        <FormInputField
          placeholder="Position Title"
          title="Position title"
          value={positionTitle}
          onChange={setPositionTitle}
        />
      </Row>
      <Row justify="space-between" gutter={[15, 15]}>
        <Col span={8}>
          <FormInputField
            placeholder="Test Duration"
            title="Test Duration"
            value={testDuration}
            suffix="hours"
            onChange={setTestDuration}
          />
        </Col>
        <Col span={16}>
          <DropDownInputField
            title="Select a department"
            value={currentDepartment?.name}
            onChange={setCurrentDepartment}
            overlay={departmentsMenu}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MySwitch
            checked={isOpen}
            onChange={() => setIsOpen(!isOpen)}
            isNotCheckedText=" "
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <TextEditor
          value={positionDescription}
          onChange={setPositionDescription}
          modules={modules}
        />
      </Row>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  company: state.company,
});

export default connect(mapStateToProps)(AddPositionModal);
