import {
  getPositions,
  createPosition as create,
  chagneJobOpenings,
  editDescription,
  editPositionName as editName,
  getPositionById as getById,
  getActionsPerPosition as getActionsPerPos,
} from "../../Api/Position";
import {
  CREATE_POSITION,
  EDIT_POSITION_DESC,
  EDIT_POSITION_NAME,
  EDIT_POSITION_OPENING,
  GET_ACTIONS_PER_POSITION,
  GET_POSITION,
  GET_POSITIONS,
  POSITION_ERROR,
  SET_ACTIONS_LOADING,
  SET_POSITIONS_LOADING,
} from "../types";

export const getAllPositions = (companyId) => (dispatch) => {
  dispatch({
    type: SET_POSITIONS_LOADING,
    payload: true,
  });
  return getPositions(companyId)
    .then((res) => {
      dispatch({
        type: GET_POSITIONS,
        payload: res.data,
      });
      dispatch({
        type: SET_POSITIONS_LOADING,
        payload: false,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: POSITION_ERROR,
        payload: err,
      });
      dispatch({
        type: SET_POSITIONS_LOADING,
        payload: false,
      });
      throw err;
    });
};

export const createPosition =
  (name, description, timeDuration, departmentId, isOpen, companyId) =>
  (dispatch) => {
    return create(
      name,
      description,
      timeDuration,
      departmentId,
      isOpen,
      companyId
    )
      .then((res) => {
        dispatch({
          type: CREATE_POSITION,
          payload: {
            res: res,
            name: name,
            description: description,
            timeDuration: timeDuration,
            departmentId: departmentId,
          },
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: POSITION_ERROR,
          payload: err,
        });
        throw err;
      });
  };

export const editPositionOpening = (id, isOpen) => (dispatch) => {
  return chagneJobOpenings(id, isOpen)
    .then((res) => {
      dispatch({
        type: EDIT_POSITION_OPENING,
        payload: { id: id, isOpen: isOpen, res: res },
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: POSITION_ERROR,
        payload: err,
      });

      throw err;
    });
};

export const editPositionDescription = (jobId, content) => (dispatch) => {
  return editDescription(jobId, content)
    .then((res) => {
      dispatch({
        type: EDIT_POSITION_DESC,
        payload: { res: res, jobId: jobId, content: content },
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: POSITION_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const editPositionName = (positionId, name) => (dispatch) => {
  return editName(positionId, name)
    .then((res) => {
      dispatch({
        type: EDIT_POSITION_NAME,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: POSITION_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const getPositionById = (id) => (dispatch) => {
  return getById(id)
    .then((res) => {
      dispatch({
        type: GET_POSITION,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: POSITION_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const getActionsPerPosition = (companyId) => (dispatch) => {
  dispatch({
    type: SET_ACTIONS_LOADING,
    payload: true,
  });
  return getActionsPerPos(companyId)
    .then((res) => {
      dispatch({
        type: GET_ACTIONS_PER_POSITION,
        payload: res.data,
      });
      dispatch({
        type: SET_ACTIONS_LOADING,
        payload: false,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: POSITION_ERROR,
        payload: err,
      });
      dispatch({
        type: SET_ACTIONS_LOADING,
        payload: false,
      });
      throw err;
    });
};
