import { useEffect, useState } from "react";
import {
  EDIT_PEN,
  POSITION_ICON,
  PLUS_BUTTON,
  USER_ICON,
  MAIL_ICON,
  EDUCATION_ICON,
  FLAG_ICON,
  STAR_ICON,
  WORLD_ICON,
  DATE_ICON,
} from "../../Resources/Icons";
import { Card, Row, Col, Divider } from "antd";
import {
  StatusDotFormatter,
  StatusFormatter,
} from "../../JSUtils/Candidates/CandidateFormatter";
import { useParams, useNavigate } from "react-router-dom";

function CandidateInformation(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [currentApplication, setCurrentApplication] = useState({});
  const [applications, setApplications] = useState([]);
  const [name, setName] = useState("");
  const [nationality, setNationality] = useState("");
  const [emails, setEmails] = useState([]);
  const [educationLevel, setEducationLevel] = useState("");
  useEffect(() => {
    setCurrentApplication(props.currentApplication);
  }, [props.currentApplication]);

  useEffect(() => {
    setName(props.name);
    setNationality(props.nationality);
    setEmails(props.emails);
    setEducationLevel(props.educationLevel);
    setApplications(props.applications);
  }, [props]);

  const changeCurrentApplication = (application) => {
    props.setCurrentApplication({ ...application });
    navigate(
      `/candidate/${params.companyId}/${params.candidateId}/${application.id}`
    );
  };

  return (
    <Card>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle">
            <Col style={{ marginRight: "50px" }}>{USER_ICON(37, 37)}</Col>
            <Col>
              <span
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "24px",
                  lineHeight: "28px",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
              >
                {name}
                <span style={{ marginLeft: "20px" }}>{EDIT_PEN}</span>
              </span>
            </Col>
          </Row>
        </Col>
        <Col>
          <span className="candidate-desc">
            {StatusFormatter(currentApplication.status)}
          </span>
        </Col>
      </Row>
      <Divider
        style={{
          border: "1px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          height: "100%",
        }}
      />
      <Row justify="space-around">
        <Col>
          <Row align="middle" style={{ marginBottom: "27px" }}>
            <Col style={{ marginRight: "50px" }} className="candidate-title">
              About
            </Col>
            <Col>
              <span>{EDIT_PEN}</span>
            </Col>
          </Row>
          <Col>
            <Row style={{ marginBottom: "16px" }}>
              <Col>{MAIL_ICON}</Col>
              <Col>
                <span className="candidate-desc" style={{ marginLeft: "18px" }}>
                  {emails}
                </span>
              </Col>
            </Row>
            <Row style={{ marginBottom: "16px" }}>
              <Col>{FLAG_ICON}</Col>
              <Col>
                <span className="candidate-desc" style={{ marginLeft: "18px" }}>
                  {"Nationality: "}
                  {nationality}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>{EDUCATION_ICON}</Col>
              <Col>
                <span className="candidate-desc" style={{ marginLeft: "18px" }}>
                  {"Education Level: "}
                  {educationLevel}
                </span>
              </Col>
            </Row>
          </Col>
        </Col>
        <Col>
          <Divider
            style={{
              border: " 1px solid rgba(0, 0, 0, 0.05)",
              height: "100%",
            }}
            type="vertical"
          />
        </Col>
        <Col>
          <Row align="middle" style={{ marginBottom: "27px" }}>
            <Col style={{ marginRight: "50px" }} className="candidate-title">
              Current Position
            </Col>
            <Col>
              <span>{EDIT_PEN}</span>
            </Col>
          </Row>
          <Col>
            <Row style={{ marginBottom: "16px" }}>
              <Col>{POSITION_ICON}</Col>
              <Col>
                <span className="candidate-desc" style={{ marginLeft: "18px" }}>
                  {currentApplication?.position?.name}
                </span>
              </Col>
            </Row>
            <Row style={{ marginBottom: "16px" }}>
              <Col>{STAR_ICON}</Col>
              <Col>
                <span className="candidate-desc" style={{ marginLeft: "18px" }}>
                  {currentApplication?.stage?.name}
                </span>
              </Col>
            </Row>
            <Row style={{ marginBottom: "16px" }}>
              <Col>{DATE_ICON}</Col>
              <Col>
                <span className="candidate-desc" style={{ marginLeft: "18px" }}>
                  {currentApplication?.applied_date}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>{WORLD_ICON}</Col>
              <Col>
                <span className="candidate-desc" style={{ marginLeft: "18px" }}>
                  {"Source: "}
                  {currentApplication?.source}
                </span>
              </Col>
            </Row>
          </Col>
        </Col>
        <Col>
          <Divider
            style={{
              border: " 1px solid rgba(0, 0, 0, 0.05)",
              height: "100%",
            }}
            type="vertical"
          />
        </Col>
        <Col>
          <Row align="middle" style={{ marginBottom: "27px" }}>
            <Col style={{ marginRight: "50px" }} className="candidate-title">
              Other Applied Positions
            </Col>
            <Col>
              <span>{PLUS_BUTTON}</span>
            </Col>
          </Row>
          <Col>
            {applications?.map((application) => {
              if (application.id == currentApplication?.id) return;
              return (
                <>
                  <Row style={{ marginBottom: "16px" }}>
                    <Row>
                      <Col
                        onClick={(e) => changeCurrentApplication(application)}
                      >
                        {POSITION_ICON}
                      </Col>
                      <Col>
                        <span
                          className="candidate-desc"
                          style={{ marginLeft: "18px" }}
                          onClick={(e) => changeCurrentApplication(application)}
                        >
                          {application.position.name}
                        </span>

                        <span
                          style={{ marginLeft: "10px" }}
                          className="candidate-desc"
                        >
                          {StatusDotFormatter(application.status)}
                        </span>
                      </Col>
                    </Row>
                    <Row justify="space-around" style={{ marginLeft: "10px" }}>
                      {EDIT_PEN}
                    </Row>
                  </Row>
                </>
              );
            })}
          </Col>
        </Col>
      </Row>
    </Card>
  );
}

export default CandidateInformation;
