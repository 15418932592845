import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Row, Col, Divider, Card, Tabs } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import { getCandidateById } from "../Redux/Actions/Candidate/CandidateActions";
import { setUserCompany } from "../Redux/Actions/Company/CompanyAction";
import { StatusDotFormatter } from "../JSUtils/Candidates/CandidateFormatter";
import { blackTextStyle } from "../Resources/Styles";
import CandidateTabs from "../Components/Candidate/CandidateTabs";
import CandidateInformation from "../Components/Candidate/CandidateInformation";

const { TabPane } = Tabs;
function Candidate(props) {
  const USER_ICON = (width, height) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9997 2.86659C12.5463 2.86659 13.7997 4.11992 13.7997 5.66658C13.7997 7.21325 12.5463 8.46659 10.9997 8.46659C9.45301 8.46659 8.19967 7.21325 8.19967 5.66658C8.19967 4.11992 9.45301 2.86659 10.9997 2.86659ZM10.9997 14.8666C14.9597 14.8666 19.133 16.8133 19.133 17.6666V19.1333H2.86634V17.6666C2.86634 16.8133 7.03967 14.8666 10.9997 14.8666ZM10.9997 0.333252C8.05301 0.333252 5.66634 2.71992 5.66634 5.66658C5.66634 8.61325 8.05301 10.9999 10.9997 10.9999C13.9463 10.9999 16.333 8.61325 16.333 5.66658C16.333 2.71992 13.9463 0.333252 10.9997 0.333252ZM10.9997 12.3333C7.43967 12.3333 0.333008 14.1199 0.333008 17.6666V21.6666H21.6663V17.6666C21.6663 14.1199 14.5597 12.3333 10.9997 12.3333Z"
        fill="#042C77"
      />
    </svg>
  );

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const candidatesList = useSelector((state) => state.user.allCandidates);
  const companyId = useSelector((state) => state.user.userCompany);
  const [age, setAge] = useState("");
  const [applications, setApplications] = useState([]);
  const [educationLevel, setEducationLevel] = useState("");
  const [emails, setEmails] = useState([]);
  const [name, setName] = useState("");
  const [nationality, setNationality] = useState("");
  const [currentApplication, setCurrentApplication] = useState({});

  useEffect(() => {
    if (params.companyId != companyId) {
      setUserCompany(params.companyId);
    }
    if (params.companyId != -1) {
      dispatch(getCandidateById(params.candidateId, params.companyId)).then(
        (res) => {
          setAge(res.data.age);
          setApplications(res.data.applications);
          for (let i in res.data.applications) {
            if (
              parseInt(res.data.applications[i].id) ===
              parseInt(params.applicationId)
            ) {
              setCurrentApplication({ ...res.data.applications[i] });
              break;
            }
          }
          setEducationLevel(res.data.educationLevel);
          setEmails(res.data.emails);
          setName(res.data.name);
          setNationality(res.data.nationality);
        }
      );
    }
  }, [companyId, params]);

  const goToCandidate = (candidate) => {
    window.location.assign(
      `/candidate/${companyId}/${candidate.candidate__id}/${candidate.id}`
    );
  };

  return (
    <>
      <Row gutter={[15, 15]}>
        <Col span={4}>
          {candidatesList.slice(0, 4).map((candidate) => {
            let clicked = "positions-position-row";
            console.log(candidate);
            if (params?.candidateId === candidate?.candidate__id)
              clicked = "positions-position-row-clicked";
            console.log(clicked);
            return (
              <Col xl={24} l={24} md={12} sm={24} xs={24}>
                <Link
                  to={`/candidate/${companyId}/${candidate.candidate__id}/${candidate.id}`}
                >
                  <Card className={clicked}>
                    <Row gutter={[15, 15]}>
                      <Col style={{ paddingLeft: "30px" }}>
                        <Divider
                          style={{
                            border: "0px",
                            padding: "0px",
                            margin: "20px",
                          }}
                        />
                        <Col>{USER_ICON(22, 22)}</Col>
                        <Col>
                          <span
                            style={{
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineheight: "19px",
                              color: "#042C77",
                            }}
                          >
                            {candidate.candidate__name}
                          </span>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ paddingLeft: "30px" }}>
                        <span>{StatusDotFormatter(candidate.status)}</span>
                        <span
                          style={{
                            marginLeft: "7px",
                            ...blackTextStyle,
                            fontSize: "14px",
                          }}
                        >
                          {candidate.position__name}
                        </span>
                      </Col>
                    </Row>
                  </Card>
                </Link>
              </Col>
            );
          })}
        </Col>
        <Col span={20}>
          <Col span={24}>
            <CandidateInformation
              currentApplication={currentApplication}
              setCurrentApplication={setCurrentApplication}
              applications={applications}
              name={name}
              emails={emails}
              educationLevel={educationLevel}
              nationality={nationality}
            />
            <CandidateTabs
              name={name}
              currentApplication={currentApplication}
              addAbout={false}
            />
          </Col>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  positions: state.positions,
  candidates: state.candidates,
});

export default connect(mapStateToProps)(Candidate);
