import { useState, useEffect } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { useDispatch } from "react-redux";
import {
  deleteDoc,
  getDoc,
} from "../../Redux/Actions/Application/DocumentAction";
import { Row, Button, Col, Card, Popover, Menu, Divider } from "antd";

// Plugins

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { getDocumentTypes } from "../../Constants/Documents";
import { DownloadFile } from "../../JSUtils/File";
import DocumentViewer from "../Viewers/DocumentViewer";
import {
  DOCUMENT_ICON,
  THREE_DOTS_ICON,
  UPLOAD_ICON,
} from "../../Resources/Icons";

// Create new plugin instance

function Documents(props) {
  const buttonStyle = {
    background: "#042C77",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const [currentDocument, setCurrentDocument] = useState({});
  const [documentType, setDocumentType] = useState("");
  const [noDocumentFound, setNoDocumentFound] = useState(false);
  const [documentsTypes, setDocumentsTypes] = useState([]);
  const content = (document) => (
    <Menu
      className="pointer-hover"
      style={{ height: "100%", width: "100%", backgroundColor: "#FFFFFF" }}
    >
      <Menu.Item onClick={(event) => documentDownload(event, document)}>
        Download
      </Menu.Item>
      <Divider />
      <Menu.Item onClick={(event) => documentEdit(event, document)}>
        Edit
      </Menu.Item>
      <Divider />
      <Menu.Item onClick={(event) => documentDelete(event, document)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const documentDownload = (event, doc) => {
    DownloadFile(doc.url, doc.name);
  };
  const documentEdit = (document) => {};
  const documentDelete = (event, documentToDelete) => {
    dispatch(deleteDoc(documentToDelete.id)).then((res) => {
      var docs = documents;
      docs = docs.filter((doc) => documentToDelete.id !== doc.id);
      setDocuments(docs);
      if (documentToDelete.id === currentDocument.id) {
        for (let i in docs) {
          if (docs[i].type === "Resume") {
            if (docs[i].name.endsWith("pdf")) setDocumentType("pdf");
            else if (docs[i].name.endsWith("docx")) setDocumentType("document");
            setCurrentDocument(docs[i]);
            return;
          }
        }
        for (let i in docs) {
          if (docs[i].name.endsWith("pdf")) {
            setCurrentDocument(docs[i]);
            setDocumentType("pdf");
            return;
          } else if (
            docs[i].name.endsWith("docx") ||
            docs[i].name.endsWith("xlsx") ||
            docs[i].name.endsWith("csv")
          ) {
            setCurrentDocument(docs[i]);
            setDocumentType("document");
            return;
          }
        }
      }
    });
  };

  const changeCurrentDocument = (event, document) => {
    if (currentDocument.id === document.id) return;
    if (document.name.endsWith("pdf")) {
      setCurrentDocument(document);
      setDocumentType("pdf");
    }
  };

  useEffect(() => {
    var docTypes = getDocumentTypes();
    setDocumentsTypes(docTypes);
    if (props.applicationId) {
      dispatch(getDoc(props.applicationId)).then((res) => {
        setDocuments(res.data);
        for (let i in res.data) {
          if (res.data[i].type === "Resume") {
            setCurrentDocument(res.data[i]);
            setDocumentType("pdf");
            return;
          }
        }
        for (let i in res.data) {
          if (res.data[i].name.endsWith("pdf")) {
            setCurrentDocument(res.data[i]);
            setDocumentType("pdf");
            return;
          }
        }
        console.log("here");
        setNoDocumentFound(true);
      });
    }
  }, [props.applicationId]);
  return (
    <>
      <Row>
        <Button style={buttonStyle} className="button-text">
          <span>
            <UPLOAD_ICON />
          </span>
          <span style={{ marginLeft: "7px" }}>Upload File</span>
        </Button>
      </Row>
      <Row gutter={[10, 10]}>
        {documents.map((document) => {
          const clicked = document.id === currentDocument.id;
          return (
            <Col style={{ width: "100%" }} span={6}>
              <Row style={{ width: "100%" }} justify="space-between">
                <Card
                  className="document-card-style"
                  style={
                    clicked
                      ? {
                          backgroundColor: "rgba(70, 84, 167, 0.05)",
                          width: "100%",
                        }
                      : { backgroundColor: "#FFFFFF", width: "100%" }
                  }
                  onClick={(event) => changeCurrentDocument(event, document)}
                >
                  <Row justify="space-between">
                    <Col>
                      <span>{DOCUMENT_ICON}</span>
                      <span
                        style={{ marginLeft: "26px" }}
                        className="document-card-name"
                      >
                        {document.name}
                      </span>
                      <Row>
                        <span
                          style={{ marginLeft: "42px", marginTop: "7px" }}
                          className="document-card-type"
                        >
                          {document.type}
                        </span>
                      </Row>
                    </Col>
                    <Col align="middle">
                      <Popover
                        content={content(document)}
                        placement="bottomRight"
                      >
                        {THREE_DOTS_ICON()}
                      </Popover>
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Col>
          );
        })}
      </Row>

      <div
        style={{
          height: "100%",
        }}
      >
        {documentType === "pdf" ? (
          <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.worker.min.js">
            {currentDocument?.url ? (
              <Viewer
                fileUrl={currentDocument.url}
                plugins={[
                  // Register plugins
                  defaultLayoutPluginInstance,
                ]}
              ></Viewer>
            ) : null}
          </Worker>
        ) : // <DocumentViewer documents={[currentDocument.url]} />
        null}
      </div>
    </>
  );
}
export default Documents;
