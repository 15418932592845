export const BAD_REQUEST = 400
export const INTERNAL_SERVER_ERROR = 500
export const NOT_FOUND = 404
export const OK = 200
export const TOO_MANY_REQUESTS = 429
export const UNAUTHORIZED = 401
export const DESCRIPTION_BY_STATUS_CODE = {
    BAD_REQUEST: "The server cannot process the request. Check if you are sending the expected data.",
    INTERNAL_SERVER_ERROR: "The server failed to process the request.",
    NOT_FOUND: "The resource that you are trying to reach is unavailable. Check if the URL you are using is valid.",
    OK: "Request has been processed successfully",
    TOO_MANY_REQUESTS: "The api rate limit has been exceeded. Wait and try again later.",
    UNAUTHORIZED: "The request that has been sent was not authorized. The used access token maybe expired or authorization parameters were not included in header.",
}