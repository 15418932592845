import {
  editDocument,
  deleteCandidateDocument,
  getDocuments,
  uploadDocument,
} from "../../../Api/Application/Documents";
import {
  DELETE_DOCUMENT,
  DOCUMENT_ERROR,
  EDIT_DOCUMENT,
  GET_DOCUMENT,
  UPLOAD_DOCUMENT,
} from "../../types";

export const editDoc = (documentId, documentType) => (dispatch) => {
  return editDocument(documentId, documentType)
    .then((res) => {
      dispatch({
        type: EDIT_DOCUMENT,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: DOCUMENT_ERROR,
        payload: err,
      });

      throw err;
    });
};

export const getDoc = (applicationId) => (dispatch) => {
  return getDocuments(applicationId)
    .then((res) => {
      dispatch({
        type: GET_DOCUMENT,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: DOCUMENT_ERROR,
        payload: err,
      });

      throw err;
    });
};

export const deleteDoc = (documentId) => (dispatch) => {
  return deleteCandidateDocument(documentId)
    .then((res) => {
      //   dispatch({
      //     type: DELETE_DOCUMENT,
      //     payload: documentId,
      //   });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: DOCUMENT_ERROR,
        payload: err,
      });

      throw err;
    });
};

export const uploadDoc = (applicationId, files, types) => (dispatch) => {
  return uploadDocument(applicationId, files, types)
    .then((res) => {
      dispatch({
        type: UPLOAD_DOCUMENT,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: DOCUMENT_ERROR,
        payload: err,
      });

      throw err;
    });
};
