import { Button, Card, Col, Divider, Row, Switch, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import CandidateByPosition from "../Components/Position/CandidateByPosition";
import { editPositionOpening } from "../Redux/Actions/PositionAction";
import "../App.css";
import PositionDescription from "../Components/Position/PositionDescription";
import PositionLink from "../Components/Position/PositionLink";
import AddPositionModal from "../Components/AddPositionModal";
import { LOCATION_ICON, POSITION_ICON } from "../Resources/Icons";
import PositionsSkeletons from "./Skeletons/PositionsSkeletons";

const { TabPane } = Tabs;

function Positions(props) {
  const dispatch = useDispatch();
  const positionsList = useSelector((state) => state.user.positions);
  const companyId = useSelector((state) => state.user.userCompany);
  const userCompanies = useSelector((state) => state.user.userCompanys);
  const candidatesList = useSelector((state) => state.user.allCandidates);
  const positionsLoading = useSelector(
    (state) => state.user.isPositionsLoading
  );

  const [companyName, setCompanyName] = useState("");
  const [currentPosition, setCurrentPosition] = useState({});
  const [currentCandidatesList, setCurrentCandidatesList] = useState([]);
  const [positionModal, setPositionModal] = useState(false);

  useEffect(() => {
    setCurrentPosition(positionsList[0]);

    let newCandidatesList = candidatesList.filter(
      (x) => x.position__id == positionsList[0].id
    );
    setCurrentCandidatesList(newCandidatesList);
  }, [positionsList]);

  const changePosition = (event, position) => {
    setCurrentPosition(position);

    let newCandidatesList = candidatesList.filter(
      (x) => x.position__id == position.id
    );
    setCurrentCandidatesList(newCandidatesList);
  };

  const changeJobOpening = (checked) => {
    dispatch(editPositionOpening(currentPosition.id, checked)).then((res) => {
      if (res.data.Code == 1) {
        currentPosition.isOpen = checked;
        setCurrentPosition(currentPosition);
      }
    });
  };

  const addPosition = () => {
    setPositionModal(true);
  };

  useEffect(() => {
    setCurrentPosition(positionsList[0]);

    let newCandidatesList = candidatesList.filter(
      (x) => x.position__id == positionsList[0].id
    );
    setCurrentCandidatesList(newCandidatesList);
  }, []);

  useEffect(() => {
    if (currentPosition && currentPosition.id && candidatesList.length > 0) {
      let newCandidatesList = candidatesList.filter(
        (x) => x.position__id == currentPosition.id
      );
      setCurrentCandidatesList(newCandidatesList);
    } else {
      setCurrentCandidatesList(candidatesList);
    }
  }, [candidatesList, currentPosition]);

  useEffect(() => {
    for (let i in userCompanies) {
      if (userCompanies[i].id == companyId) {
        setCompanyName(userCompanies[i].companyName);
        break;
      }
    }
  }, [companyId]);

  const onTabChanged = (key) => {};

  return (
    <>
      {positionModal ? (
        <AddPositionModal
          visible={positionModal}
          setPositionModal={setPositionModal}
        />
      ) : null}

      <Row gutter={[15, 15]} justify="space-evenly">
        <Col
          xl={5}
          l={5}
          md={24}
          sm={24}
          xs={24}
          className="positions-left-column"
        >
          <Card>
            <Row justify="space-between">
              <Col>
                <Row>
                  <Col span={24}>{"Positions"}</Col>
                  <Col span={24}>{positionsList.length} positions</Col>
                </Row>
              </Col>
              <Col>
                <Button
                  className="positions-add-button"
                  onClick={() => addPosition()}
                >
                  + Add Position
                </Button>
              </Col>
            </Row>
          </Card>

          {positionsLoading ? (
            <PositionsSkeletons />
          ) : (
            <Row>
              {positionsList.map((position) => {
                let clicked = "positions-position-row";
                if (position?.id === currentPosition?.id)
                  clicked = "positions-position-row-clicked";
                return (
                  <Col xl={24} l={24} md={12} sm={24} xs={24}>
                    <Card className={clicked}>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        onClick={(event) => changePosition(event, position)}
                      >
                        <Divider
                          style={{
                            border: "0px",
                            padding: "0px",
                            margin: "20px",
                          }}
                        />
                        <Col>{POSITION_ICON}</Col>

                        <Col>
                          <Row>
                            <span className="positions-position-name">
                              {position.name}
                            </span>
                          </Row>
                          <Row>
                            <span className="positions-location-icon">
                              {LOCATION_ICON}{" "}
                              <span className="positions-position-location">
                                {position.department?.location}
                              </span>
                            </span>
                          </Row>
                          <Row>
                            <span className="positions-position-date">
                              {moment(position.date).fromNow()}{" "}
                            </span>
                          </Row>
                        </Col>
                      </Col>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </Col>

        <Col
          xl={19}
          l={19}
          md={24}
          sm={24}
          xs={24}
          className="positions-right-column"
        >
          <Card>
            <Row justify="space-between">
              <Col>
                <Row gutter={[35, 15]}>
                  <Col>
                    <span className="positions-current-position-icon">
                      {POSITION_ICON}
                    </span>
                  </Col>
                  <Col>
                    <Row>
                      <Col span={24}>
                        <span className="positions-current-position-name">
                          {currentPosition?.name}{" "}
                        </span>
                      </Col>
                      <Col span={24}>
                        <span className="positions-current-position-icon">
                          {LOCATION_ICON}
                        </span>
                        <span className="positions-current-position-location">
                          {currentPosition?.department?.location}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <span className="positions-current-position-date">
                        {moment(currentPosition?.date).format("L")}
                      </span>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col>
                <span className="position-switch">Close</span>
                <Switch
                  checked={currentPosition?.isOpen}
                  onChange={changeJobOpening}
                ></Switch>
                <span className="position-switch">Open</span>
              </Col>
            </Row>
          </Card>

          <Col>
            <Tabs defaultActiveKey="1" onChange={onTabChanged} type="line" a>
              <TabPane tab="Candidates" key="1">
                <CandidateByPosition
                  positionCandidatesList={currentCandidatesList}
                  currentPosition={currentPosition}
                />
              </TabPane>
              <TabPane tab="Email" key="2"></TabPane>
              <TabPane tab="Description" key="3">
                <PositionDescription
                  description={currentPosition?.desc}
                  jobId={currentPosition?.id}
                />
              </TabPane>
              <TabPane tab="Link" key="4">
                <PositionLink
                  positionName={currentPosition?.name}
                  companyName={companyName}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  positions: state.positions,
  candidates: state.candidates,
});

export default connect(mapStateToProps)(Positions);
