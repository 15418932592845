import React, { useEffect, useState } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Checkbox, Row } from "antd";
import { showEmail } from "../Redux/Actions/UserAction";
import { greyTextStyle } from "../Resources/Styles";
import { StatusFormatter } from "../JSUtils/Candidates/CandidateFormatter";
import ActionsSkeletons from "./Skeletons/ActionsSkeletons";

function Action(props) {
  const MAIL_ICON = (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
        fill="#042C77"
      />
    </svg>
  );

  const CANDIDATES_ICON = (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 7C8.93 7 10.5 5.43 10.5 3.5C10.5 1.57 8.93 0 7 0C5.07 0 3.5 1.57 3.5 3.5C3.5 5.43 5.07 7 7 7ZM7 2C7.83 2 8.5 2.67 8.5 3.5C8.5 4.33 7.83 5 7 5C6.17 5 5.5 4.33 5.5 3.5C5.5 2.67 6.17 2 7 2ZM7.05 12H2.77C3.76 11.5 5.47 11 7 11C7.11 11 7.23 11.01 7.34 11.01C7.68 10.28 8.27 9.68 8.98 9.2C8.25 9.07 7.56 9 7 9C4.66 9 0 10.17 0 12.5V14H7V12.5C7 12.33 7.02 12.16 7.05 12ZM14.5 9.5C12.66 9.5 9 10.51 9 12.5V14H20V12.5C20 10.51 16.34 9.5 14.5 9.5ZM15.71 7.68C16.47 7.25 17 6.44 17 5.5C17 4.12 15.88 3 14.5 3C13.12 3 12 4.12 12 5.5C12 6.44 12.53 7.25 13.29 7.68C13.65 7.88 14.06 8 14.5 8C14.94 8 15.35 7.88 15.71 7.68Z"
        fill="#042C77"
      />
    </svg>
  );

  const CANDIDATE_ICON = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99935 2.40841C9.06268 2.40841 9.92435 3.27008 9.92435 4.33341C9.92435 5.39675 9.06268 6.25841 7.99935 6.25841C6.93602 6.25841 6.07435 5.39675 6.07435 4.33341C6.07435 3.27008 6.93602 2.40841 7.99935 2.40841ZM7.99935 10.6584C10.7218 10.6584 13.591 11.9967 13.591 12.5834V13.5917H2.40768V12.5834C2.40768 11.9967 5.27685 10.6584 7.99935 10.6584ZM7.99935 0.666748C5.97352 0.666748 4.33268 2.30758 4.33268 4.33341C4.33268 6.35925 5.97352 8.00008 7.99935 8.00008C10.0252 8.00008 11.666 6.35925 11.666 4.33341C11.666 2.30758 10.0252 0.666748 7.99935 0.666748ZM7.99935 8.91675C5.55185 8.91675 0.666016 10.1451 0.666016 12.5834V15.3334H15.3327V12.5834C15.3327 10.1451 10.4468 8.91675 7.99935 8.91675Z"
        fill="#042C77"
      />
    </svg>
  );

  const EYE_ICON = (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.875C11.8425 1.875 14.3775 3.4725 15.615 6C14.3775 8.5275 11.85 10.125 9 10.125C6.15 10.125 3.6225 8.5275 2.385 6C3.6225 3.4725 6.1575 1.875 9 1.875ZM9 0.375C5.25 0.375 2.0475 2.7075 0.75 6C2.0475 9.2925 5.25 11.625 9 11.625C12.75 11.625 15.9525 9.2925 17.25 6C15.9525 2.7075 12.75 0.375 9 0.375ZM9 4.125C10.035 4.125 10.875 4.965 10.875 6C10.875 7.035 10.035 7.875 9 7.875C7.965 7.875 7.125 7.035 7.125 6C7.125 4.965 7.965 4.125 9 4.125ZM9 2.625C7.14 2.625 5.625 4.14 5.625 6C5.625 7.86 7.14 9.375 9 9.375C10.86 9.375 12.375 7.86 12.375 6C12.375 4.14 10.86 2.625 9 2.625Z"
        fill="black"
        fill-opacity="0.54"
      />
    </svg>
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.user.userCompany);
  const emailsData = useSelector((state) => state.positions.actions);
  const newApplicants = useSelector((state) => state.candidates.newApplicants);
  const isActionsLoading = useSelector(
    (state) => state.positions.isActionsLoading
  );

  const [emailsLength, setEmailsLength] = useState(0);
  const [currentPositionsLength, setCurrentPositionsLength] = useState(0);
  const [positionDict, setPositionDict] = useState({});
  const [openEmails, setOpenEmails] = useState({});

  var currentPositionsDict = {};
  var openEmail = {};
  var positionsLength = 0;
  var emailsNumber = 0;

  const checkBoxChanged = (event) => {
    let checked = event.target.checked;
    let object = event.target.value;
    positionsLength = currentPositionsLength;
    if (checked) {
      positionsLength += object.value;
      let compEmails = props.positions.actions;
      for (const [key, value] of Object.entries(compEmails)) {
        if (key === object.key[0]) {
          if (!currentPositionsDict[key]) positionDict[key] = [];
          // currentPositionsDict[key] = [];

          let emailsInfo = compEmails[key].Candidates.emailsInfo;
          positionDict[key].push(emailsInfo);
          for (let i = 0; i < emailsInfo.length; i++) {
            compEmails[key].Candidates[emailsInfo[i].applicationId] = false;
          }
        }
      }
    } else {
      positionsLength -= object.value;
      delete positionDict[object.key[0]];
    }
    setCurrentPositionsLength(positionsLength);
    // setPositionDict(currentPositionsDict)
    setOpenEmails(openEmail);
  };

  const toggleEmail = (event, applicationId, positionName) => {
    dispatch(showEmail(applicationId, positionName));
    setOpenEmails({});
  };

  const goToCandidate = (event, applicationId, link) => {
    event.stopPropagation();
    var candidateId = link.split("/")[1];
    navigate(`/candidate/${companyId}/${candidateId}/${applicationId}`);
  };

  useEffect(() => {
    openEmail = {};
    currentPositionsDict = {};
    setCurrentPositionsLength(0);
    setEmailsLength(emailsNumber);
    setOpenEmails({});
    setPositionDict({});
    positionsLength = 0;
  }, []);

  useEffect(() => {
    for (const [key, value] of Object.entries(props.positions.actions)) {
      emailsNumber += value.Candidates.number;
    }
    openEmail = {};
    currentPositionsDict = {};
    setPositionDict({});
    setEmailsLength(emailsNumber);
    setOpenEmails({});
  }, [emailsData]);

  return isActionsLoading ? (
    <ActionsSkeletons />
  ) : (
    <div className="actions">
      <Card
        title={
          <>
            <span className="actions-mail-icon">{MAIL_ICON}</span>
            <span className="actions-mails-title">Emails</span>
            <span className="actions-circle-number">{emailsLength}</span>
            <div>
              <span className="actions-mails-text">
                {" "}
                you have {emailsLength} emails you need to reply them{" "}
              </span>
            </div>
          </>
        }
        className="actions-mails-card"
      >
        <Card.Grid className="actions-grid-card">
          {Object.entries(emailsData).map((key, value) => {
            let a = { key: key, value: key[1].Candidates.number };
            if (key[1].Candidates.number > 0) {
              return (
                <div className="actions-grid-position">
                  <Checkbox
                    className="actions-positions-checkbox"
                    onChange={checkBoxChanged}
                    key={key[0]}
                    value={a}
                  ></Checkbox>
                  <span className="actions-grid-position-name">{key[0]}</span>
                  <span className="actions-grid-position-mails">
                    {key[1].Candidates.number}
                  </span>
                </div>
              );
            }
          })}
        </Card.Grid>
      </Card>
      <Card
        title={
          <>
            <span className="actions-candidates-icon">{CANDIDATES_ICON}</span>
            <span className="actions-candidates-title">Candidates</span>
            <span id="PositionsLength" className="actions-circle-number">
              {currentPositionsLength}
            </span>
            <div>
              <span className="actions-candidates-text">
                {" "}
                you have {currentPositionsLength} new emails from candidates,
                you need to reply them{" "}
              </span>
            </div>
          </>
        }
      >
        {Object.keys(positionDict).map((key, i) => {
          return (
            <>
              <Card
                title={<span className="actions-candidate-title">{key}</span>}
              >
                {positionDict[key].map((el) => {
                  return (
                    <>
                      {el.map((element) => {
                        return (
                          <Card.Grid className="actions-grid-card">
                            <div
                              className="actions-grid-position"
                              onClick={(event) =>
                                toggleEmail(event, element.applicationId, key)
                              }
                            >
                              <span className="actions-candidates-icon">
                                {CANDIDATE_ICON}
                              </span>
                              <span
                                className="actions-candidate-name"
                                onClick={(event) =>
                                  goToCandidate(
                                    event,
                                    element.applicationId,
                                    element.link
                                  )
                                }
                              >
                                {element.candidateName}
                              </span>
                              <span className="actions-candidates-preview">
                                {EYE_ICON}
                              </span>
                              {element.show ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: element.body,
                                  }}
                                />
                              ) : (
                                <span className="actions-candidate-created">
                                  {element.subject}{" "}
                                </span>
                              )}
                            </div>
                          </Card.Grid>
                        );
                      })}
                    </>
                  );
                })}
              </Card>
              <Card
                title={
                  <span className="actions-candidate-title">
                    New {key} Candidates
                  </span>
                }
              >
                {Object.keys(newApplicants).map((currentKey, i) => {
                  if (currentKey === key) {
                    return (
                      <>
                        {newApplicants[currentKey].map((el) => {
                          return (
                            <Card.Grid className="actions-grid-card">
                              <Row className="actions-grid-position">
                                <span className="actions-candidates-icon">
                                  {CANDIDATE_ICON}
                                </span>
                                <span className="actions-candidate-name">
                                  {el.name}
                                </span>
                              </Row>
                              <Row>
                                <span style={greyTextStyle}>
                                  {" "}
                                  {el.appliedDate}
                                </span>
                                <span style={{ marginLeft: "7px" }}>
                                  {" "}
                                  {" - "}
                                </span>
                                <span style={{ marginLeft: "7px" }}>
                                  {StatusFormatter(el.status)}
                                </span>
                              </Row>
                            </Card.Grid>
                          );
                        })}
                      </>
                    );
                  }
                })}
              </Card>
            </>
          );
        })}
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  positions: state.positions,
  candidates: state.candidates,
});

export default connect(mapStateToProps)(Action);
