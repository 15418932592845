import { GET, POST } from "../Constants/HttpRequest/HttpRequestType";
import {
  APPLICATION_URL,
  CHANGE_JOB_URL,
  CREATE_URL,
  DELETED_URL,
  DELETE_URL,
  EDIT_URL,
  EMAILS_VIEWER_URL,
  EMAIL_URL,
  INFO_URL,
  LINK_TO_APPLICATION_URL,
  LINK_TO_CANDIDATE_URL,
  LINK_TO_POSITION_URL,
  MOVE_TO_NEW_URL,
  NEW_EMAILS_URL,
  POSITION_URL,
  RESCHEDULE_URL,
  RESTORE_URL,
  RETRY_URL,
  START_NEW_PROCESS_URL,
} from "../Constants/Urls/Urls";
import HandleRequest from "../JSUtils/Http/HttpRequest";

/*
todo:
    -getCompany
    -addLog


*/

export const getAllEmails = (companyId) => {
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(companyId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getNewEmails = (fromI, toI, companyId) => {
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(
      NEW_EMAILS_URL,
      fromI,
      "/",
      toI,
      "/",
      companyId,
      "/"
    );
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        // console.log("Get New Emails");
        // console.log(res);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDeletedEmails = (fromI, toI) => {
  return new Promise((resolve, reject) => {
    getCompany().then((res) => {
      let companyId = res.id;
      const url = EMAIL_URL.concat(
        DELETED_URL,
        fromI,
        "/",
        toI,
        "/",
        companyId,
        "/"
      );
      HandleRequest({ url: url, method: GET })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
};

export const getEmailsData = (companyId) => {
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(EMAILS_VIEWER_URL, companyId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const moveEmailtoNewEmails = (emailId) => {
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(MOVE_TO_NEW_URL, emailId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const retrySendingEmail = (emailId) => {
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(RETRY_URL);
    let form = new FormData();
    form.append("emailId", emailId);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEmailById = (emailId) => {
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(emailId, "/", INFO_URL);
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEmailsByPosition = (positionId) => {
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(POSITION_URL, positionId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEmailsByApplication = (applicationId) => {
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(APPLICATION_URL, applicationId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const linkEmailToCandidate = (emailId, applicationId) => {
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(
      LINK_TO_APPLICATION_URL,
      emailId,
      "/",
      applicationId,
      "/"
    );
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const StartNewProcess = (
  emailId,
  positionId,
  candidateId,
  linkAction,
  emailContent,
  sendRejection
) => {
  let form = new FormData();
  form.append("emailId", emailId);
  form.append("positionId", positionId);
  form.append("candidateId", candidateId);
  form.append("linkAction", linkAction);
  form.append("emailContent", emailContent);
  form.append("sendRejection", sendRejection);

  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(START_NEW_PROCESS_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        addLog(
          "Started new process for candidate (candidateId) application - (applicationId) position, with the email emailId",
          candidateId,
          null,
          emailId,
          positionId
        );
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const linkToPosition = (emails, positionId) => {
  let form = new FormData();
  for (let i = 0; i < emails.length; i++) {
    form.append("emailIds", emails[i].id);
  }
  form.append("positionId", positionId);

  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(LINK_TO_POSITION_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        for (let i = 0; i < emails.length; i++) {
          addLog(
            "Linked the email (emailId) to (applicationId) position",
            null,
            null,
            emails[i].id,
            positionId
          );
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteEmailById = (emails, reasonToDeleted) => {
  let form = new FormData();
  for (let i = 0; i < emails.length; i++) {
    form.append("emailIds", emails[i]);
  }
  form.append("reason", reasonToDeleted);
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(DELETE_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        // for (let i = 0; i < emails.length; i++) {
        //   addLog(
        //     "Deleted the email with subject emailId",
        //     null,
        //     null,
        //     emails[i],
        //     null
        //   );
        // }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const restoreEmailById = (emails) => {
  let form = new FormData();
  for (let i = 0; i < emails.length; i++) {
    form.append("emailIds", emails[i].id);
  }
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(RESTORE_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        for (let i = 0; i < emails.length; i++) {
          addLog(
            "Restored the email with subject emailId",
            null,
            null,
            emails[i].id,
            null
          );
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendEmail = (
  emailId,
  applicationId,
  toEmail,
  subject,
  templateId,
  stageId,
  messageContent,
  attachments,
  sendNow,
  sendDate,
  sendTime,
  createEvent,
  eventLocation,
  eventDuration,
  companyId
) => {
  console.log("sending api");
  console.log("stage stage");
  console.log(stageId);
  let form = new FormData();
  if (emailId != null) {
    form.append("emailId", emailId);
  }

  form.append("applicationId", applicationId);
  form.append("toEmail", toEmail);
  form.append("subject", subject);
  form.append("templateId", templateId);
  form.append("stageId", stageId);
  form.append("messageContent", messageContent);

  form.append("sendNow", sendNow);
  form.append("sendDate", sendDate);
  form.append("sendTime", sendTime);
  form.append("createEvent", createEvent);
  form.append("eventLocation", eventLocation);
  form.append("eventDuration", eventDuration);
  for (let i = 0; i < attachments.length; i++) {
    form.append("extraAttachments", attachments[i]);
  }
  return new Promise((resolve, reject) => {
    form.append("companyId", companyId);
    const url = EMAIL_URL.concat(CREATE_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        // addLog(
        //   "Sent new email to candidate candidateId. applicationId position, Subject: ".concat(
        //     subject
        //   ),
        //   null,
        //   applicationId,
        //   null,
        //   null
        // );
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        console.log("ERRRORORORRO");
        reject(err);
      });
  });
};

export const editEmail = (
  emailId,
  applicationId,
  toEmail,
  subject,
  templateId,
  stageId,
  messageContent,
  attachments,
  sendNow,
  sendDate,
  sendTime,
  createEvent
) => {
  let form = new FormData();
  form.append("emailId", emailId);
  form.append("applicationId", applicationId);
  form.append("toEmail", toEmail);
  form.append("subject", subject);
  form.append("templateId", templateId);
  form.append("stageId", stageId);
  form.append("messageContent", messageContent);

  form.append("sendNow", sendNow);
  form.append("sendDate", sendDate);
  form.append("sendTime", sendTime);
  form.append("createEvent", createEvent);

  for (let i = 0; i < attachments.length; i++) {
    form.append("extraAttachments", attachments[i]);
  }
  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(EDIT_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        addLog(
          "Edited the email emailId sent to candidate candidateId. applicationId position",
          null,
          applicationId,
          emailId,
          null
        );

        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const linkEmailToNewCandidate = (
  emailId,
  positionId,
  candidateName,
  candidateEmail,
  stageId,
  appliedDate,
  source,
  attachmentsTypes,
  linkAction,
  emailContent,
  sendRejection,
  rejectionReason
) => {
  let form = new FormData();
  form.append("emailId", emailId);
  form.append("candidateName", candidateName);
  form.append("candidateEmail", candidateEmail);
  form.append("source", source);
  form.append("appliedDate", appliedDate);
  form.append("stageId", stageId);
  form.append("positionId", positionId);
  form.append("linkAction", linkAction);
  form.append("emailContent", emailContent);
  form.append("sendRejection", sendRejection);
  form.append("rejectionReason", rejectionReason);

  for (let i = 0; i < attachmentsTypes.length; i++) {
    form.append(attachmentsTypes[i].id, attachmentsTypes[i].type);
  }

  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(LINK_TO_CANDIDATE_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        addLog(
          "Linked the email emailId to new candidate with the name ".concat(
            candidateName,
            ". applicationId position"
          ),
          null,
          null,
          emailId,
          positionId
        );
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const rescheduleSendDate = (emailId, sendDate, sendTime) => {
  let form = new FormData();
  form.append("emailId", emailId);
  form.append("sendDate", sendDate);
  form.append("sendTime", sendTime);

  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(RESCHEDULE_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        addLog(
          "Rescheduled the email emailId for candidate: (candidateId), (applicationId) position to date: ".concat(
            sendDate,
            " ",
            sendTime
          ),
          null,
          null,
          emailId,
          null
        );
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changeEmailJob = (emailId, positionId) => {
  let form = new FormData();
  form.append("emailId", emailId);
  form.append("positionId", positionId);

  return new Promise((resolve, reject) => {
    const url = EMAIL_URL.concat(CHANGE_JOB_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        addLog(
          "Changed the email emailId position to applicationId",
          null,
          null,
          emailId,
          positionId
        );
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
