import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Input, Button, Card } from "antd";
import { editDepartmentById } from "../../Redux/Actions/Company/CompanyAction";
import { inputStyle, labelStyle } from "../../Resources/Styles";
import { DEPARTMENT_ICON } from "../../Resources/Icons";
import AddDepartmentModal from "../Modals/AddDepartmentModal";

function Departments(props) {
  const textStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#042C77",
    marginLeft: "10px",
  };

  const buttonStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: "#042C77",
    color: "white",
    textTransform: "uppercase",
    marginTop: "10px",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  };

  const dispatch = useDispatch();
  const [departmentModal, setDepartmentModal] = useState(false);

  const changeDepartmentName = (event, index) => {
    var array = [...props.departments];
    array[index].name = event.target.value;
    props.setDepartments(array);
  };

  const changeDepartmentLocation = (event, index) => {
    var array = [...props.departments];
    array[index].location = event.target.value;
    props.setDepartments(array);
  };

  const saveDepartment = (event, index, departmentId) => {
    let name = props.departments[index].name;
    let location = props.departments[index].location;
    dispatch(editDepartmentById(departmentId, name, location));
  };

  const addDepartment = () => {
    setDepartmentModal(true);
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      {departmentModal ? (
        <AddDepartmentModal
          visible={departmentModal}
          setDepartmentModal={setDepartmentModal}
        />
      ) : null}
      {props.departments.map((department, i) => {
        return (
          <Row gutter={[15, 15]} style={{ backgroundColor: "white" }}>
            <Col span={12}>
              <Card
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "10px",
                    marginBottom: "0px",
                  }}
                >
                  <Row>
                    {DEPARTMENT_ICON}{" "}
                    <p style={textStyle}>
                      {props?.initialDepartments
                        ? props?.initialDepartments[i]?.name
                          ? props?.initialDepartments[i]?.name
                          : ""
                        : null}
                    </p>
                  </Row>
                </div>
                <div style={{ padding: "10px" }}>
                  <Row>
                    <p style={labelStyle}>
                      {department.name ? "Department Name*" : null}
                    </p>
                  </Row>
                  <Row>
                    <Input
                      style={{ ...inputStyle, marginBottom: "8px" }}
                      value={department.name}
                      key={i}
                      onChange={(e) => changeDepartmentName(e, i)}
                    />
                  </Row>
                </div>
                <div style={{ padding: "10px" }}>
                  <Row>
                    <p style={labelStyle}>
                      {department.location ? "Department Location*" : null}
                    </p>
                  </Row>
                  <Row>
                    <Input
                      style={{ ...inputStyle, marginBottom: "8px" }}
                      value={department.location}
                      key={i}
                      onChange={(e) => changeDepartmentLocation(e, i)}
                    />
                  </Row>
                  <Row>
                    <Button
                      style={buttonStyle}
                      onClick={(event) =>
                        saveDepartment(event, i, department.id)
                      }
                    >
                      {" "}
                      Save
                    </Button>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        );
      })}
      <Row gutter={[15, 15]}>
        <Col span={12}>
          <Button
            style={{ ...buttonStyle, marginLeft: "10px", marginBottom: "20px" }}
            onClick={() => addDepartment()}
          >
            Add new
          </Button>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  company: state.company,
});

export default connect(mapStateToProps)(Departments);
