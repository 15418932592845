import { GET, POST } from "../../Constants/HttpRequest/HttpRequestType";
import {
  ADD_NEW_COMPANY_URL,
  ADD_URL,
  COMPANY_LOG_URL,
  CONSTANTS_URL,
  CONSTANT_COMPANY_URL,
  DEPARTMENT_URL,
  EDIT_URL,
  USER_URL,
} from "../../Constants/Urls/Urls";
import HandleRequest from "../../JSUtils/Http/HttpRequest";

export const getCompanyLogs = () => {
  return new Promise((resolve, reject) => {
    getCompany().then((res) => {
      let companyId = res.id;

      const url = COMPANY_LOG_URL.concat(companyId, "/");
      HandleRequest({ url: url, method: GET })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
};

export const getCompanyById = (companyId) => {
  return new Promise((resolve, reject) => {
    const url = CONSTANT_COMPANY_URL.concat(companyId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const editCompany = (
  companyId,
  name,
  domains,
  timeZone,
  jobsEmail,
  emailsPassword,
  execludedEmails,
  sendingServer,
  sendingPort,
  sendingProtocol,
  emailUserName,
  receivingProtocol,
  receivingPort,
  receivingServer,
  attachments
) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("companyId", companyId);

    form.append("companyName", name);
    form.append("domains", domains);
    form.append("timeZone", timeZone);
    form.append("jobsEmail", jobsEmail);
    form.append("jobsPassword", emailsPassword);
    form.append("sendingServer", sendingServer);
    form.append("execludedEmails", execludedEmails);
    form.append("sendingPort", sendingPort);
    form.append("sendingProtocol", sendingProtocol);
    form.append("jobsEmailUsername", emailUserName);
    form.append("receivingProtocol", receivingProtocol);

    form.append("receivingPort", receivingPort);
    form.append("receivingServer", receivingServer);
    console.log(attachments);
    if (attachments) form.append("logo", attachments);

    const url = CONSTANT_COMPANY_URL + EDIT_URL;
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addNewCompany = (name, domain, email, location, time_zone) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("name", name);
    form.append("location", location);
    form.append("domain", domain);
    form.append("email", email);
    form.append("time_zone", time_zone);

    const url = CONSTANT_COMPANY_URL + ADD_NEW_COMPANY_URL;
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserCompanies = (userEmail) => {
  return new Promise((resolve, reject) => {
    const url = CONSTANT_COMPANY_URL.concat(USER_URL, userEmail, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const editDepartmentById = (id, name, location) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("id", id);
    form.append("name", name);
    form.append("location", location);

    const url = CONSTANTS_URL.concat(DEPARTMENT_URL, EDIT_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDepartment = (companyId, name, location) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("name", name);
    form.append("location", location);
    form.append("companyId", companyId);

    const url = CONSTANTS_URL.concat(DEPARTMENT_URL, ADD_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        console.log(res);
        resolve(res["data"]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
