import { StatusMapping } from "../../Constants/Status";
import {
  SET_USER_COMPANYS,
  SET_USER_COMPANY,
  GET_COMPANY_EMAILS,
  GET_EMAILS_DATA,
  GET_USER_NOTIFICATION,
  SHOW_EMAIL,
  GET_CANDIDATES,
  GET_STAGE,
  GET_POSITIONS,
  EDIT_POSITION_OPENING,
  EDIT_POSITION_DESC,
  REJECT_CANDIDATE,
  CANDIDATES_LOADING,
  SET_POSITIONS_LOADING,
} from "../types";

const initialState = {
  userCompanys: [],
  userCompany: -1,
  companyEmails: [],
  notifications: [],
  allCandidates: [],
  stages: [],
  positions: [],
  isCandidateLoading: false,
  isPositionsLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_COMPANY:
      state.userCompany = action.payload;
      return { ...state };
    case SET_USER_COMPANYS:
      state.userCompanys = action.payload;
      state.userCompany = 1; //change it later to state.userCompanys[0].id
      return { ...state };
    case GET_COMPANY_EMAILS:
    case GET_EMAILS_DATA:
      state.companyEmails = action.payload;

      return {
        ...state,
      };
    case GET_USER_NOTIFICATION:
      var notifications = action.payload;
      notifications.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.created) - new Date(a.created);
      });

      state.notifications = notifications;
      return { ...state };

    case GET_CANDIDATES:
      state.allCandidates = action.payload;
      return {
        ...state,
      };
    case GET_STAGE:
      state.stages = action.payload;
      return {
        ...state,
      };
    case GET_POSITIONS:
      state.positions = action.payload;
      return {
        ...state,
      };
    case EDIT_POSITION_OPENING:
      var { id, isOpen, res } = action.payload;
      if (res.data.Code === 1) {
        for (let i in state.positions) {
          if (state.positions[i].id === id) {
            state.positions[i].isOpen = isOpen;
          }
        }
      }
      return {
        ...state,
      };
    case EDIT_POSITION_DESC:
      console.log(action.payload);
      var { res, jobId, content } = action.payload;
      if (res.data.Code === 1) {
        for (let i in state.positions) {
          if (state.positions[i].id === jobId) {
            state.positions[i].desc = content;
          }
        }
      }
      return {
        ...state,
      };
    case REJECT_CANDIDATE:
      for (let i in state.allCandidates) {
        if (state.allCandidates[i].id === action.payload.applicationId) {
          state.allCandidates[i].status = StatusMapping.REJECTED;
          break;
        }
      }
      return {
        ...state,
      };

    case CANDIDATES_LOADING:
      state.isCandidateLoading = action.payload;
      return { ...state };

    case SET_POSITIONS_LOADING:
      state.isPositionsLoading = action.payload;
      return { ...state };

    default:
      return state;
  }
}
