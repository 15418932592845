import {getStages as get, addNewStage  , editStageById as editStage, deleteStage as delStage} from "../../../Api/Stage/Stage"
import {GET_STAGE,EDIT_STAGE,ADD_STAGE,DELETE_STAGE, STAGE_ERROR} from "../../types"


export const getStages = (companyId,force = false) => (dispatch) => {
    return get(companyId,force).then(res => {
        dispatch({
            type:GET_STAGE,
            payload: res.data
        })
        return res;
    }).catch(err => {
        dispatch({
            type: STAGE_ERROR,
            payload: res
        })
        throw err;
    })
}

export const addStage = (companyId, name, location, order) => (dispatch) => {
    return addNewStage(companyId, name, location, order).then(res => {
        dispatch({
            type: ADD_STAGE,
            payload: res
        })
        return res;
    }).catch(err => {
        dispatch({
            type: STAGE_ERROR,
            payload: err
        })
        throw err;
    })
}

export const editStageById = (stageId,name,location,order) => (dispatch) => {
    return editStage(stageId, name, location, order).then(res => {
        dispatch({
            type: EDIT_STAGE,
            payload: res
        })
        return res;
    }).catch(err => {
        dispatch({
            type: STAGE_ERROR,
            payload: err
        })
        throw err;
    })
}

export const deleteStage = (deleteStageId, newStageId) => (dispatch) => {
    return delStage(deleteStageId,newStageId).then(res => {
        dispatch({
            type: DELETE_STAGE,
            payload: res
        })
        return res;
    }).catch(err => {
        dispatch({
            type: STAGE_ERROR,
            payload: err
        })
        throw err;
    })
}