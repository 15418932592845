import { Skeleton } from "antd";
function InputSkeleton(props) {
  return (
    <Skeleton.Input
      active={props.active ? props.active : true}
      size={props.size ? props.size : "default"}
      shape={props.shape ? props.shape : "square"}
      block={props.block ? props.block : true}
      className={props.className ? props.className : null}
    />
  );
}

export default InputSkeleton;
