import "antd/dist/antd.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getDocuments } from "./Api/Application/Documents";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import SideBar from "./Pages/Navbar/SideBar";
import Action from "./Pages/Actions";

import { Layout, Affix, Spin, Row, Divider, Col } from "antd";
import TopBar from "./Pages/Navbar/TopBar";
const { Header, Footer, Sider, Content } = Layout;

import axios from "./Lib/Axios";
import Candidates from "./Pages/Candidates";
import Positions from "./Pages/Positions";
import Emails from "./Pages/Emails";
import Admin from "./Pages/Admin";
import Candidate from "./Pages/Candidate";

export default function App() {
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    logout,
    user,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [isLarge, setIsLarge] = useState(false);

  useEffect(() => {
    let width = window.innerWidth;
    let large = width > 900;
    setIsLarge(large);
  }, []);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN;
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const { user_metadata } = await metadataResponse.json();
        setUserMetadata(user_metadata);
      } catch (e) {
        console.log("error");
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  if (isLoading) {
    return <h1>Loading</h1>;
  } else if (isAuthenticated) {
    return (
      <Router>
        <Layout>
          <Affix offsetTop={0}>
            <TopBar googleUser={user} logout={logout} />
          </Affix>
          <Layout className="ant-layout">
            <Affix offsetTop={46} style={{ height: "100% 100vh" }}>
              <Sider className="sidebar">
                <SideBar style={{ height: "90%" }} />
              </Sider>
              <Divider type="vertical" />
            </Affix>
            <Content className="content">
              <Routes>
                <Route exact path="/" element={<Action />}></Route>
                <Route exact path="/action" element={<Action />}></Route>
                <Route
                  exact
                  path="/candidates"
                  element={<Candidates />}
                ></Route>
                <Route exact path="/positions" element={<Positions />}></Route>
                <Route exact path="/emails" element={<Emails />}></Route>
                <Route exact path="/admin" element={<Admin />}></Route>
                <Route
                  exact
                  path="/candidate/:companyId/:candidateId/:applicationId"
                  element={isLarge ? <Candidates /> : <Candidate />}
                ></Route>
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Router>
    );
  } else {
    return loginWithRedirect();
  }
}
