import { GET, POST } from "../../Constants/HttpRequest/HttpRequestType";
import {
  ALL_URL,
  CANDIDATE_URL,
  CONSTANTS_URL,
  CREATE_URL,
  EDIT_URL,
  LINK_URL,
  MATCHING_URL,
  NEW_APPLICANTS_URL,
  POSITION_URL,
  REJECT_URL,
  STATISTICS_URL,
  STATUS_URL,
} from "../../Constants/Urls/Urls";
import { formatCandidates } from "../../JSUtils/Candidates/CandidateFormatter";
import HandleRequest from "../../JSUtils/Http/HttpRequest";
export const linkCandidateToAnotherCandidate = (oldId, newId) => {
  return new Promise((resolve, reject) => {
    let url = CANDIDATE_URL.concat(LINK_URL, oldId, "/", newId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMatchingCandidates = (email, name) => {
  return new Promise((resolve, reject) => {
    getCompany().then((res) => {
      let companyId = res.Id;
      let url = CANDIDATE_URL.concat(
        MATCHING_URL,
        email,
        "/",
        name,
        "/",
        companyId,
        "/"
      );
      HandleRequest({ url: url, method: GET })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
};

export const rejectCandidate = (
  candidateId,
  applicationId,
  rejectionEmail,
  sendRejection,
  rejectReason
) => {
  let form = new FormData();
  form.append("candidateId", candidateId);
  form.append("applicationId", applicationId);
  form.append("rejectionEmail", rejectionEmail);
  form.append("sendRejection", sendRejection);
  form.append("rejectionReason", rejectReason);

  return new Promise((resolve, reject) => {
    let url = CANDIDATE_URL + REJECT_URL;
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCandidates = (positionId = null, companyId) => {
  return new Promise((resolve, reject) => {
    let url = CANDIDATE_URL.concat(ALL_URL, companyId, "/");
    if (positionId)
      url = CANDIDATE_URL.concat(POSITION_URL, positionId, "/", companyId, "/");

    HandleRequest({ url: url, method: GET })
      .then((res) => {
        let result = formatCandidates(res.data);
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCandidatesByStatus = (status, id) => {
  return new Promise((resolve, reject) => {
    getCompany().then((res) => {
      let companyId = res.Id;
      let url = CANDIDATE_URL.concat(STATUS_URL, status, "/", companyId, "/");
      if (id) {
        url = CANDIDATE_URL.concat(
          POSITION_URL,
          STATUS_URL,
          id,
          "/",
          status,
          "/"
        );
      }
      HandleRequest({ url: url, method: GET })
        .then((res) => {
          res = formatCandidates(res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
};

export const addNewCandidate = (
  companyId,
  positionId,
  candidateName,
  candidateEmail,
  stageId,
  appliedDate,
  source
) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("candidateName", candidateName);
    form.append("candidateEmail", candidateEmail);
    form.append("source", source);
    form.append("appliedDate", appliedDate);
    form.append("stageId", stageId);
    form.append("positionId", positionId);
    form.append("companyId", companyId);

    const url = CANDIDATE_URL.concat(CREATE_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCandidateById = (candidateId, companyId) => {
  return new Promise((resolve, reject) => {
    let url = CANDIDATE_URL.concat(candidateId, "/", companyId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const editCandidate = (candidateId, candidateName, candidateEmails) => {
  let form = new FormData();
  form.append("candidateId", candidateId);
  if (candidateName != null) {
    form.append("candidateName", candidateName);
  }
  if (candidateEmails != null) {
    for (let i = 0; i < candidateEmails.length; i++) {
      form.append("candidateEmails", candidateEmails[i]);
    }
  }

  let url = CANDIDATE_URL + EDIT_URL;

  return new Promise((resolve, reject) => {
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getNewApplicants = (companyId) => {
  let url = CONSTANTS_URL.concat(
    STATISTICS_URL,
    NEW_APPLICANTS_URL,
    companyId,
    "/"
  );
  return new Promise((resolve, reject) => {
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
