import axios from "axios";

const SERVER_URL =
  "https://serverrecruitment.algoadvisory.ai:5121/RecruitmentTool/";
const ONLINE_SERVER_URL =
  "https://backend-dot-recruitment-tool-315708.ue.r.appspot.com/RecruitmentTool/";

const axiosAPI = axios.create({
  // baseURL: "http://localhost:8000",
  baseURL: SERVER_URL,
});

export default axiosAPI;
