import { Switch } from "antd";

export default function MySwitch(props) {
  return (
    <>
      <span className="position-switch">
        {props.isNotCheckedText ? props.isNotCheckedText : "Close"}
      </span>
      <Switch checked={props.checked} onChange={props.onChange}></Switch>
      <span className="position-switch">
        {props.isCheckedText ? props.isCheckedText : "Open"}
      </span>
    </>
  );
}
