import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Dropdown,
  Table,
  Menu,
  Card,
  Input,
  Button,
  Col,
  Row,
  Modal,
} from "antd";

import { getStatus } from "../../Constants/Status";
import { SearchOutlined } from "@ant-design/icons";

import "../../App.css";
import AddCandidateModal from "../AddCandidateModal";
import { THREE_DOTS_ICON } from "../../Resources/Icons";
import CandidatesTableSkeleton from "../../Pages/Skeletons/CandidatesTableSkeleton";

function CandidateByPosition(props) {
  const navigate = useNavigate();
  const [candidatesList, setCandidatesList] = useState(props.candidatesList);
  const [originalCandidatesList, setOriginalCandidatesList] = useState([]);
  const stages = useSelector((state) => state.user.stages);
  const companyId = useSelector((state) => state.user.userCompany);
  const isCandidateLoading = useSelector(
    (state) => state.user.isCandidateLoading
  );

  const [stagesList, setStagesList] = useState([]);
  const [status, setStatus] = useState([]);
  const [searchText, setSearchtext] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dropdDownMenu = (
    <Menu className="candidates-action-menu">
      <Menu.Item key="Reject">Reject</Menu.Item>
      <Menu.Item key="edit Application">Edit application</Menu.Item>
    </Menu>
  );

  const colums = [
    {
      title: "Candidate",
      dataIndex: "candidate__name",
      key: "candidate__name",
      width: "25%",
      sorter: (a, b) => {
        return a.candidate__name.localeCompare(b.candidate__name);
      },
      // ...getColumnSearchProps('candidate__name'),
    },
    {
      title: "Email",
      dataIndex: "candidate__emails",
      key: "candidate__emails",
      width: "25%",
      sorter: (a, b) => {
        return a.candidate__emails.localeCompare(b.candidate__emails);
      },
      // ...getColumnSearchProps('candidate__emails'),
    },
    {
      title: "Stage",
      dataIndex: "stage__name",
      key: "stage__name",
      width: "15%",
      sorter: (a, b) => {
        return a.stage__name.localeCompare(b.stage__name);
      },
      filters: stagesList,
      filterMode: "tree",
      onFilter: (value, record) => record.stage__name.startsWith(value),
    },
    {
      title: "Applied",
      dataIndex: "last_updated",
      key: "last_updated",
      width: "15%",
      sorter: (a, b) => {
        const c = new Date(a.last_updated);
        const d = new Date(b.last_updated);
        return c - d;
      },
      // ...getColumnSearchProps('last_updated'),
    },
    {
      title: "Status",
      dataIndex: "statusFormat",
      key: "statusFormat",
      width: "15%",
      filters: status,
      filterMode: "tree",
      onFilter: (value, record) => record.status.startsWith(value),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: "5%",
      render: () => (
        <div>
          <Dropdown overlay={dropdDownMenu} placement="bottomLeft" arrow>
            <span className="candidates-dropdown-button">
              {THREE_DOTS_ICON(12, 24)}
            </span>
          </Dropdown>
        </div>
      ),
    },
  ];

  useEffect(() => {
    var statusList = [];
    getStatus().map((status) => {
      statusList.push({ text: status, value: status });
    });
    setStatus(statusList);
    setCandidatesList(props.positionCandidatesList);
  }, []);

  useEffect(() => {
    setCandidatesList(props.positionCandidatesList);
    setOriginalCandidatesList(props.positionCandidatesList);
  }, [props]);

  useEffect(() => {
    var currentStages = [];
    for (let i in Object.entries(stages)) {
      currentStages.push({ text: stages[i].name, value: stages[i].name });
    }
    setStagesList(currentStages);
  }, [stages]);

  const onSearch = (e) => {
    setSearchtext(e.target.value);
    let filtered = [];
    let searchInput = e.target.value.toUpperCase();
    if (!e.target.value) {
      filtered = originalCandidatesList;
    } else {
      originalCandidatesList.forEach((candidate) => {
        if (candidate.candidate__name.toUpperCase().includes(searchInput)) {
          filtered.push(candidate);
        }
      });
    }
    setCandidatesList(filtered);
  };

  const addCandidate = () => {
    setIsModalVisible(true);
  };

  const goToCandidate = (record, rowIndex) => {
    return {
      onClick: () => {
        navigate(
          `/candidate/${companyId}/${record.candidate__id}/${record.id}`
        );
      }, // click row
    };
  };

  return (
    <Card>
      {isCandidateLoading ? (
        <CandidatesTableSkeleton tableItems={colums} numberOfRows={10} />
      ) : (
        <>
          <Row gutter={[15, 15]}>
            <Col>
              <Button className="candidates-button-add" onClick={addCandidate}>
                + ADD CANDIDATE
              </Button>
              <AddCandidateModal
                modalVisible={isModalVisible}
                setModalVisible={setIsModalVisible}
                currentPosition={props.currentPosition}
              />
            </Col>
            <Col>
              <Input
                onChange={onSearch}
                placeholder="Search Records"
                value={searchText}
                className="candidates-search-bar"
                prefix={<SearchOutlined />}
              />
            </Col>
          </Row>
          <Col>
            <Table
              columns={colums}
              key={props.positionCandidatesList}
              dataSource={candidatesList}
              onRow={goToCandidate}
            />
          </Col>
        </>
      )}
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    positions: state.positions,
    candidates: state.candidates,
  };
};

export default connect(mapStateToProps)(CandidateByPosition);
