import {
  linkCandidateToAnotherCandidate as link,
  getMatchingCandidates as match,
  rejectCandidate as reject,
  getCandidates,
  getCandidatesByStatus as getByStatus,
  addNewCandidate as add,
  getCandidateById as getById,
  editCandidate as edit,
  getNewApplicants,
} from "../../../Api/Candidate/Candidate";
import {
  CANDIDATE_ERROR,
  EDIT_CANDIDATE,
  GET_CANDIDATE,
  GET_CANDIDATES,
  GET_NEW_APPLICANTS,
  LINK_CANDIDATE,
  REJECT_CANDIDATE,
  SET_CANDIDATE,
  CANDIDATES_LOADING,
  NEW_CANDIDATES_LOADING,
} from "../../types";

export const linkCandidate = (oldId, newId) => (dispatch) => {
  return link(oldId, newId)
    .then((res) => {
      dispatch({
        type: LINK_CANDIDATE,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: CANDIDATE_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const getMatchingCandidates = (email, name) => (dispatch) => {
  return match(email, name)
    .then((res) => {
      dispatch({
        type: GET_CANDIDATE,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: CANDIDATE_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const rejectCandidate =
  (candidateId, applicationId, rejectionEmail, sendRejection, rejectReason) =>
  (dispatch) => {
    return reject(
      candidateId,
      applicationId,
      rejectionEmail,
      sendRejection,
      rejectReason
    )
      .then((res) => {
        dispatch({
          type: REJECT_CANDIDATE,
          payload: { applicationId: applicationId },
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: CANDIDATE_ERROR,
          payload: err,
        });
        throw err;
      });
  };

export const getCandidatesByPosition =
  (positionId = null, companyId) =>
  (dispatch) => {
    dispatch({
      type: CANDIDATES_LOADING,
      payload: true,
    });
    return getCandidates(positionId, companyId)
      .then((res) => {
        dispatch({
          type: GET_CANDIDATES,
          payload: res,
        });
        dispatch({
          type: CANDIDATES_LOADING,
          payload: false,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: CANDIDATE_ERROR,
          payload: err,
        });
        dispatch({
          type: CANDIDATES_LOADING,
          payload: false,
        });
        throw err;
      });
  };

export const getCandidatesByStatus = (status, companyId) => (dispatch) => {
  return getByStatus(status, companyId)
    .then((res) => {
      dispatch({
        type: GET_CANDIDATES,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: CANDIDATE_ERROR,
        payload: err,
      });
      throw err;
    });
};

export const addNewCandidate =
  (
    companyId,
    positionId,
    candidateName,
    candidateEmail,
    stageId,
    appliedDate,
    source
  ) =>
  (dispatch) => {
    return add(
      companyId,
      positionId,
      candidateName,
      candidateEmail,
      stageId,
      appliedDate,
      source
    )
      .then((res) => {
        dispatch({
          type: SET_CANDIDATE,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: CANDIDATE_ERROR,
          payload: err,
        });
        throw err;
      });
  };

export const getCandidateById = (candidateId, companyId) => (dispatch) => {
  return getById(candidateId, companyId)
    .then((res) => {
      dispatch({
        type: GET_CANDIDATE,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: CANDIDATE_ERROR,
        payload: err,
      });

      throw err;
    });
};

export const editCandidate =
  (candidateId, candidateName, candidateEmail) => (dispatch) => {
    return edit(candidateId, candidateName, candidateEmail)
      .then((res) => {
        dispatch({
          type: EDIT_CANDIDATE,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: CANDIDATE_ERROR,
          payload: err,
        });

        return err;
      });
  };

export const getAllNewApplicants = (companyId) => (dispatch) => {
  dispatch({
    type: NEW_CANDIDATES_LOADING,
    payload: true,
  });
  return getNewApplicants(companyId)
    .then((res) => {
      dispatch({
        type: GET_NEW_APPLICANTS,
        payload: res["data"],
      });
      dispatch({
        type: NEW_CANDIDATES_LOADING,
        payload: false,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        CANDIDATE_ERROR,
        payload: err,
      });
      dispatch({
        type: NEW_CANDIDATES_LOADING,
        payload: false,
      });
      throw err;
    });
};
