import { DELETE, GET, POST, PUT } from "../../Constants/HttpRequest/HttpRequestType";
import axios from "../../Lib/Axios";
import { ErrorHandler } from "./HttpErrorHandler";


    
export default function HandleRequest({url, method, headers={},params={},data={}})  {
        switch(method){
            case GET:
                return GetRequest(url,headers,params,data);
            case POST:
                return PostRequest(url,headers,data);
            case PUT:
                return PutRequest(url,headers,params,data);
            case DELETE:
                return DeleteRequest(url,headers,params,data);
            default:
                return null;
        }
    }
    

const GetRequest = (url, headers,params, data) => {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                data: data,
                headers: headers,
                params: params
                
            })
            .then(res => {
                resolve(res);
            }).catch(err => {
                reject(err)
                ErrorHandler(err,url,GET,headers,params,data)
            })
        });
    }

const PostRequest = (url, headers, data) => {
            
            return new Promise((resolve, reject) => {
                axios.post(url, data, {
                    data: data,
                    headers: headers,

                })
                .then(res => {
                    resolve(res);
                }).catch(err => {
                    ErrorHandler(err,method=GET,url=url,header=headers,params=params,data=data)
                })
            });
    }

const PutRequest = (url, headers,params, data) => {
        return new Promise((resolve, reject) => {
            axios.put(url, data, {
                data: data,
                headers: headers,
                params: params
            })
            .then(res => {
                resolve(res);
            }).catch(err => {
                ErrorHandler(err,method=GET,url=url,header=headers,params=params,data=data)
            })
        });
    }

const DeleteRequest = (url, headers,params, data) => {
        return new Promise((resolve, reject) => {
            axios.delete(url, {
                data: data,
                headers: headers,
                params: params

            })
            .then(res => {
                resolve(res);
            }).catch(err => {
                ErrorHandler(err,method=GET,url=url,header=headers,params=params,data=data)
            })
        });
    }