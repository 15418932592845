export const greyTextStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "24px",
  color: "rgba(0, 0, 0, 0.54)",
};

export const blackTextStyle = {
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "16px",
};

export const buttonTextStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "16px",
  textTransform: "uppercase",
  color: "#FFFFFF",
};

export const inputStyle = {
  color: "#042C77",
  borderBottom: "2px solid #042C77",
  borderTop: "0px",
  borderLeft: "0px",
  borderRight: "0px",
};

export const labelStyle = {
  color: "#042C77",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  margin: "0px",
  lineHeight: "18px",
};

export const titleStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "23px",
  color: "#042C77",
};

export const buttonStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  backgroundColor: "#042C77",
  color: "white",
  textTransform: "uppercase",
  marginTop: "10px",
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
};
