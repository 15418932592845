import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'antd/dist/antd.css';
import {  Menu  } from 'antd';


const Sidebar = () => {
    
    
    const ACTION_ICON = <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0C7.03 0 3 4.03 3 9H0L3.89 12.89L3.96 13.03L8 9H5C5 5.13 8.13 2 12 2C15.87 2 19 5.13 19 9C19 12.87 15.87 16 12 16C10.07 16 8.32 15.21 7.06 13.94L5.64 15.36C7.27 16.99 9.51 18 12 18C16.97 18 21 13.97 21 9C21 4.03 16.97 0 12 0ZM11 5V10L15.25 12.52L16.02 11.24L12.5 9.15V5H11Z" fill="#042C77"/>
                </svg>
    const CANDIDATE_ICON = <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 7C8.93 7 10.5 5.43 10.5 3.5C10.5 1.57 8.93 0 7 0C5.07 0 3.5 1.57 3.5 3.5C3.5 5.43 5.07 7 7 7ZM7 2C7.83 2 8.5 2.67 8.5 3.5C8.5 4.33 7.83 5 7 5C6.17 5 5.5 4.33 5.5 3.5C5.5 2.67 6.17 2 7 2ZM7.05 12H2.77C3.76 11.5 5.47 11 7 11C7.11 11 7.23 11.01 7.34 11.01C7.68 10.28 8.27 9.68 8.98 9.2C8.25 9.07 7.56 9 7 9C4.66 9 0 10.17 0 12.5V14H7V12.5C7 12.33 7.02 12.16 7.05 12ZM14.5 9.5C12.66 9.5 9 10.51 9 12.5V14H20V12.5C20 10.51 16.34 9.5 14.5 9.5ZM15.71 7.68C16.47 7.25 17 6.44 17 5.5C17 4.12 15.88 3 14.5 3C13.12 3 12 4.12 12 5.5C12 6.44 12.53 7.25 13.29 7.68C13.65 7.88 14.06 8 14.5 8C14.94 8 15.35 7.88 15.71 7.68Z" fill="black" fill-opacity="0.54"/>
    </svg>
    
    const POSITIONS_ICON = <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4V2H8V4H12ZM2 6V17H18V6H2ZM18 4C19.11 4 20 4.89 20 6V17C20 18.11 19.11 19 18 19H2C0.89 19 0 18.11 0 17L0.00999999 6C0.00999999 4.89 0.89 4 2 4H6V2C6 0.89 6.89 0 8 0H12C13.11 0 14 0.89 14 2V4H18Z" fill="black" fill-opacity="0.56"/>
    </svg>
    
    const NEW_EMAILS_ICON = <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z" fill="black" fill-opacity="0.54"/>
    </svg>
    
    const ADMIN_ICON = <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.6097 17.99L13.5297 8.90997C14.4597 6.56997 13.9797 3.80997 12.0897 1.90997C9.78972 -0.390029 6.20972 -0.600029 3.65972 1.25997L7.49972 5.10997L6.07972 6.51997L2.24972 2.68997C0.389717 5.22997 0.599717 8.81997 2.89972 11.11C4.75972 12.97 7.46972 13.46 9.78972 12.59L18.8997 21.7C19.2897 22.09 19.9197 22.09 20.3097 21.7L22.6097 19.4C23.0097 19.02 23.0097 18.39 22.6097 17.99ZM19.6097 19.59L10.1497 10.13C9.53972 10.58 8.85972 10.85 8.14972 10.95C6.78972 11.15 5.35972 10.74 4.31972 9.69997C3.36972 8.75997 2.92972 7.49997 2.99972 6.25997L6.08972 9.34997L10.3297 5.10997L7.23972 2.01997C8.47972 1.94997 9.72972 2.38997 10.6797 3.32997C11.7597 4.40997 12.1697 5.89997 11.9197 7.28997C11.7997 7.99997 11.4997 8.65997 11.0397 9.24997L20.4897 18.7L19.6097 19.59Z" fill="black" fill-opacity="0.54"/>
    </svg>
    
  

    
  return (

   
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          className="sidebar-menu"
        >
            
            <Menu.Item key="2" icon={ACTION_ICON} >     <Link to= "/action">  Actions</Link></Menu.Item>
            <Menu.Item key="3" icon={CANDIDATE_ICON}>   <Link to= "/candidates"> Candidates</Link></Menu.Item>
            <Menu.Item key="4" icon={POSITIONS_ICON}>   <Link to= "/positions"> Positions</Link></Menu.Item>
            <Menu.Item key="5" icon={NEW_EMAILS_ICON}>  <Link to= "/emails"> Emails</Link></Menu.Item>
            <Menu.Item key="6" icon={ADMIN_ICON}>       <Link to= "/admin"> Admin</Link></Menu.Item>
          
        </Menu>
  );
};

export default Sidebar;