import { Card, Dropdown, Row, Col, Menu, Input, Button } from "antd";
import DropdownButton from "antd/lib/dropdown/dropdown-button";
import React, { useState, useEffect } from "react";
import { getSources } from "../../Constants/Constants";
import {
  COPY_ICON,
  LINKEDIN_ICON,
  LINK_ICON,
  STACKOVERFLOW_ICON,
} from "../../Resources/Icons";

function PositionLink(props) {
  const [sources, setSources] = useState([]);
  const [positionName, setPositionName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [currentSource, setCurrentSource] = useState("");
  const [positionLink, setPositionLink] = useState("");

  const changeSource = (event) => {
    setCurrentSource(event.key);
    changePositionLink(companyName, positionName, currentSource);
  };

  const menu = (
    <Menu>
      {sources.map((source) => {
        return (
          <Menu.Item key={source} onClick={changeSource}>
            {source}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const cardTitle = (
    <Col>
      <Row>
        <Col>{LINK_ICON}</Col>
        <Col className="position-link-generateLink">Generate Link</Col>
        <Col>
          <Dropdown
            overlay={menu}
            placement="bottomLeft"
            arrow
            className="position-link-dropdown"
          >
            <Button className="position-link-dropdown-button">
              {currentSource}{" "}
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <span className="position-link-explanation">
          Replace {"{Source}"} with the software you are posting your job to it.{" "}
        </span>{" "}
      </Row>
    </Col>
  );

  const changePositionLink = (companyName, positionName, currentSource) => {
    setPositionLink(
      "https://" +
        companyName +
        "-careers.hire.do/Positions/" +
        positionName +
        "/" +
        currentSource
    );
  };

  const copyText = () => {
    navigator.clipboard.writeText(positionLink);
  };

  useEffect(() => {
    var sourcesList = getSources();
    setSources(sourcesList);
    setCurrentSource(sourcesList[0]);
  }, []);

  useEffect(() => {
    setPositionName(props.positionName);
    setCompanyName(props.companyName);
    changePositionLink(props.positionName, props.companyName, sources[0]);
  }, [props]);

  return (
    <Card title={cardTitle}>
      <Row>
        <Col span={18}>
          <Input
            value={
              "https://" +
              companyName +
              "-careers.hire.do/Positions/" +
              positionName +
              "/" +
              currentSource
            }
            disabled
          />
        </Col>
        <Col>
          <Button onClick={copyText} className="position-link-copyButton">
            <span>{COPY_ICON}</span> <span>Copy</span>
          </Button>
        </Col>
      </Row>

      <Row>
        <span>For example:</span>
      </Row>

      <Row>{LINKEDIN_ICON}Linked In</Row>
      <Row>
        <Col span={18}>
          <Input
            value={
              "https://" +
              companyName +
              "-careers.hire.do/Positions/" +
              positionName +
              "/Linked In"
            }
            disabled
          />
        </Col>
        <Col>
          <Button onClick={copyText} className="position-link-copyButton">
            <span>{COPY_ICON}</span> <span>Copy</span>
          </Button>
        </Col>
      </Row>
      <Row>{STACKOVERFLOW_ICON}Stackoverflow</Row>
      <Row>
        <Col span={18}>
          <Input
            value={
              "https://" +
              companyName +
              "-careers.hire.do/Positions/" +
              positionName +
              "/Stackoverflow"
            }
            disabled
          />
        </Col>
        <Col>
          <Button onClick={copyText} className="position-link-copyButton">
            <span>{COPY_ICON}</span> <span>Copy</span>
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

export default PositionLink;
