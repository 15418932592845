import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Row } from "antd";
import { buttonStyle, titleStyle } from "../../Resources/Styles";
import FormInputField from "../FormInputField";
import {
  addDepartment,
  getCompanyById,
} from "../../Redux/Actions/Company/CompanyAction";

export default function AddDepartmentModal(props) {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.user.userCompany);
  const [departmentName, setDepartmentName] = useState("");
  const [departmentLocation, setDepartmentLocation] = useState("");

  const closeModal = () => {
    setDepartmentName("");
    setDepartmentLocation("");
    props.setDepartmentModal(!props.visible);
  };

  const onCancel = () => {
    closeModal();
  };

  const saveDepartment = () => {
    dispatch(addDepartment(companyId, departmentName, departmentLocation)).then(
      (res) => {
        dispatch(getCompanyById(companyId));
        closeModal();
      }
    );
  };

  return (
    <Modal
      visible={props.visible}
      title={<span style={titleStyle}>Add Department</span>}
      footer={null}
      onCancel={onCancel}
    >
      <Row>
        <FormInputField
          value={departmentName}
          onChange={setDepartmentName}
          placeholder={"Department Name"}
          title={"Department Name*"}
        />
      </Row>
      <Row>
        <FormInputField
          value={departmentLocation}
          onChange={setDepartmentLocation}
          placeholder={"Department Location"}
          title={"Department Location*"}
        />
      </Row>

      <Row justify="end">
        <Button style={buttonStyle} onClick={saveDepartment}>
          Save Department
        </Button>
      </Row>
    </Modal>
  );
}
