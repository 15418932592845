import { Row, Input, Dropdown } from "antd";
import { inputStyle, labelStyle } from "../Resources/Styles";

export default function DropDownInputField(props) {
  return (
    <div style={{ padding: "10px" }}>
      <Row>
        <p style={labelStyle}>{props.title}</p>
      </Row>
      <Row>
        <Dropdown overlay={props.overlay} arrow placement="bottomLeft">
          <Input
            width={props.width}
            placeholder={props.placeholder}
            style={{ ...inputStyle, marginBottom: "15px" }}
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
          />
        </Dropdown>
      </Row>
    </div>
  );
}
