import { BAD_REQUEST, TOO_MANY_REQUESTS } from "../../Constants/HttpRequest/HttpRequestStatusCode";
import HandleRequest from "./HttpRequest";


export const   ErrorHandler = (err,url,method,header,params,data) => {
    switch(statusCode){
        case BAD_REQUEST:
            
        case TOO_MANY_REQUESTS:
            return new Promise((resolve,reject) => {
                HandleRequest(url,method,header,params,data,false).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                })
            })
        default:
            return null;
    }
}