import { connect, useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import {
  Menu,
  Form,
  Dropdown,
  Input,
  Row,
  Col,
  Modal,
  Layout,
  DatePicker,
  Button,
} from "antd";
import { getSources } from "../Constants/Constants";
import {
  addNewCandidate,
  getCandidatesByPosition,
} from "../Redux/Actions/Candidate/CandidateActions";
import { inputStyle, labelStyle } from "../Resources/Styles";
import { DOWN_ARROW } from "../Resources/Icons";

function AddCandidateModal(props) {
  const dispatch = useDispatch();

  const companyId = useSelector((state) => state.user.userCompany);
  const stages = useSelector((state) => state.user.stages);
  const positions = useSelector((state) => state.user.positions);
  const [positionsList, setPositionsList] = useState([]);
  const [stagesList, setStagesList] = useState([]);
  const [sources, setSources] = useState([]);

  const [userName, setUserName] = useState("");
  const [userPosition, setUserPosition] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userDate, setUserDate] = useState("");
  const [userSource, setUserSource] = useState("");
  const [userStage, setUserStage] = useState("");

  const positionMenu = (
    <Menu>
      {positionsList.map((position) => {
        return (
          <Menu.Item
            key={position.text}
            onClick={(e) => setUserPosition(e.key)}
          >
            {position.text}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const stageMenu = (
    <Menu>
      {stagesList.map((stage) => {
        return (
          <Menu.Item key={stage.text} onClick={(e) => setUserStage(e.key)}>
            {stage.text}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const sourceMenu = (
    <Menu>
      {sources.map((source) => {
        return (
          <Menu.Item key={source} onClick={(e) => setUserSource(e.key)}>
            {source}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const handleOk = () => {
    var positionId = -1;
    for (let i in positions) {
      if (positions[i].name === userPosition) {
        positionId = positions[i].id;
      }
    }
    var stageId = -1;
    for (let i in stages) {
      if (stages[i].name === userStage) {
        stageId = stages[i].id;
      }
    }

    dispatch(
      addNewCandidate(
        companyId,
        positionId,
        userName,
        userEmail,
        stageId,
        userDate,
        userSource
      )
    ).then((res) => {
      props.setModalVisible(false);
      dispatch(getCandidatesByPosition(null, companyId));
    });
  };

  const handleCancel = () => {
    props.setModalVisible(false);
  };

  useEffect(() => {
    var currentPositions = [];
    for (let i in Object.entries(positions)) {
      currentPositions.push({
        text: positions[i].name,
        value: positions[i].name,
      });
    }
    setPositionsList(currentPositions);
  }, [positions]);

  useEffect(() => {
    var currentStages = [];
    for (let i in Object.entries(stages)) {
      currentStages.push({ text: stages[i].name, value: stages[i].name });
    }
    setStagesList(currentStages);
  }, [stages]);

  useEffect(() => {
    var sourcesList = getSources();
    setSources(sourcesList);
  }, []);
  useEffect(() => {
    if (props.currentPosition) setUserPosition(props.currentPosition.name);
  }, [props]);

  return (
    <Modal
      visible={props.modalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Add"
      style={{ color: "#04C277" }}
    >
      <span className="modal-title">Add Candidate</span>

      <Form style={{ marginTop: "30px" }}>
        <Row justify="space-evenly" gutter={[30, 25]}>
          <Col span={12}>
            <Row>
              <p style={labelStyle}>{"Full Name"}</p>
            </Row>
            <Input
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              style={inputStyle}
            />
          </Col>
          <Col span={12}>
            <Row>
              <p style={labelStyle}>{"Position"}</p>
            </Row>
            <Dropdown overlay={positionMenu} arrow placement="bottomLeft">
              <Input
                value={userPosition}
                onChange={(e) => setUserPosition(e.target.value)}
                disabled
                suffix={DOWN_ARROW}
                style={inputStyle}
              />
            </Dropdown>
          </Col>
          <Col span={12}>
            <Row>
              <p style={labelStyle}>{"Date"}</p>
            </Row>
            <DatePicker
              placeholder=""
              onChange={(date, dateString) => {
                setUserDate(dateString);
              }}
              style={{ ...inputStyle, width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <Row>
              <p style={labelStyle}>{"Email"}</p>
            </Row>
            <Input
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              style={inputStyle}
            />
          </Col>
          <Col span={12}>
            <Row>
              <p style={labelStyle}>{"Stage"}</p>
            </Row>
            <Dropdown overlay={stageMenu} arrow placement="bottomLeft">
              <Input
                value={userStage}
                onChange={(e) => setUserStage(e.target.value)}
                disabled
                suffix={DOWN_ARROW}
                style={inputStyle}
              />
            </Dropdown>
          </Col>
          <Col span={12}>
            <Row>
              <p style={labelStyle}>{"Source"}</p>
            </Row>
            <Dropdown overlay={sourceMenu} arrow placement="bottomLeft">
              <Input
                style={inputStyle}
                value={userSource}
                disabled
                suffix={DOWN_ARROW}
              />
            </Dropdown>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    positions: state.positions,
    candidates: state.candidates,
  };
};

export default connect(mapStateToProps)(AddCandidateModal);
