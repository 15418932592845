import { GET, POST } from "../Constants/HttpRequest/HttpRequestType";
import {
  ACTIONS_URL,
  ALL_URL,
  CHANGE_JOB_OPENING_URL,
  CHANGE_NAME_URL,
  COMPANY_URL,
  CREATE_URL,
  EDIT_URL,
  POSITION_URL,
} from "../Constants/Urls/Urls";
import HandleRequest from "../JSUtils/Http/HttpRequest";

/*
todo:
    -getCompany
    -getStoragePositions
    -setStoragePositions
*/

export const getPositions = (companyId) => {
  return new Promise((resolve, reject) => {
    const url = POSITION_URL.concat(ALL_URL, companyId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createPosition = (
  name,
  description,
  timeDuration,
  departmentId,
  isOpen,
  companyId
) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("name", name);
    form.append("description", description);
    form.append("timeDuration", timeDuration);
    form.append("companyId", companyId);
    form.append("departmentId", departmentId);
    form.append("isOpen", isOpen);

    const url = POSITION_URL.concat(CREATE_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const chagneJobOpenings = (id, isOpen) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("id", id);
    form.append("isOpen", isOpen);
    const url = POSITION_URL.concat(CHANGE_JOB_OPENING_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const editDescription = (jobId, content) => {
  let form = new FormData();
  form.append("jobId", jobId);
  form.append("content", content);
  return new Promise((resolve, reject) => {
    const url = POSITION_URL.concat(EDIT_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPositionById = (id) => {
  return new Promise((resolve) => {
    getCompany().then((res) => {
      let companyId = res.id;

      const url = POSITION_URL.concat(id, "/", companyId, "/");
      HandleRequest({ url: url, method: GET })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
};

export const editPositionName = (id, name) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("id", id);
    form.append("name", name);

    let url = POSITION_URL.concat(CHANGE_NAME_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getActionsPerPosition = (companyId) => {
  return new Promise((resolve, reject) => {
    const url = ACTIONS_URL.concat(COMPANY_URL, companyId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
