import { Skeleton, Card, Row, Checkbox } from "antd";
import SkeletonAvatar from "antd/lib/skeleton/Avatar";
import LineSkeleton from "../../Components/Skeletons/LineSkeleton";
import { CANDIDATE_ICON, EYE_ICON, MAIL_ICON } from "../../Resources/Icons";

function ActionsSkeletons(props) {
  const skeletonArray = [1, 2, 3];
  return (
    <div className="actions">
      <Card
        title={
          <>
            <span className="actions-mail-icon">{MAIL_ICON}</span>
            <span className="actions-mails-title">Emails</span>
            <span className="actions-circle-number">
              <Skeleton.Avatar active={true} size="small" shape="circle" />
            </span>
          </>
        }
        className="actions-mails-card"
      >
        <Card.Grid className="actions-grid-card">
          {skeletonArray.map((value) => {
            return (
              <div>
                <span className="actions-grid-position-name">
                  <LineSkeleton block={false} />
                </span>
              </div>
            );
          })}
        </Card.Grid>
      </Card>
      <Card
        title={
          <>
            <span className="actions-candidates-icon">{CANDIDATE_ICON}</span>
            <span className="actions-candidates-title">Candidates</span>
            <span id="PositionsLength" className="actions-circle-number">
              <Skeleton.Avatar active={true} size="small" shape="circle" />
            </span>
            <div>
              <span className="actions-candidates-text">
                {" "}
                you have{" "}
                <Skeleton.Avatar
                  size={"small"}
                  shape="circle"
                  active={true}
                />{" "}
                new emails from candidates, you need to reply them{" "}
              </span>
            </div>
          </>
        }
      >
        {skeletonArray.map((i) => {
          return (
            <>
              <Card
                title={
                  <span className="actions-candidate-title">
                    <LineSkeleton />
                  </span>
                }
              >
                {skeletonArray.map((j) => {
                  return (
                    <>
                      <Card.Grid className="actions-grid-card">
                        <div className="actions-grid-position">
                          <span className="actions-candidates-icon">
                            {CANDIDATE_ICON}
                          </span>
                          <span className="actions-candidate-name">
                            <LineSkeleton />
                          </span>
                          <span className="actions-candidates-preview">
                            {EYE_ICON}
                          </span>
                        </div>
                      </Card.Grid>
                    </>
                  );
                })}
              </Card>
            </>
          );
        })}
      </Card>
    </div>
  );
}

export default ActionsSkeletons;
