import { GET, POST } from "../Constants/HttpRequest/HttpRequestType";
import {
  AUTOMATED_URL,
  COMPANY_URL,
  CONSTANT_COMPANY_URL,
  CREATE_URL,
  EDIT_URL,
  REJECT_URL,
  REQUEST_URL,
  TEMPLATE_URL,
} from "../Constants/Urls/Urls";
import HandleRequest from "../JSUtils/Http/HttpRequest";

/*
todo:
    -getCompany
    -getStorageTemplates
    -setStorageTemplates
*/

export const getRejectTemplate = (companyId) => {
  return new Promise((resolve, reject) => {
    const url = TEMPLATE_URL.concat(REJECT_URL, companyId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAutomatedTemplate = (companyId) => {
  return new Promise((resolve) => {
    const url = TEMPLATE_URL.concat(AUTOMATED_URL, companyId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRequestTemplate = (companyId) => {
  return new Promise((resolve) => {
    const url = TEMPLATE_URL.concat(REQUEST_URL, companyId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTemplates = (companyId, force = false) => {
  return new Promise((resolve, reject) => {
    const url = TEMPLATE_URL.concat(COMPANY_URL, companyId, "/");
    HandleRequest({ url: url, method: GET })
      .then((res) => {
        let templates = res["data"];
        templates.sort(function (a, b) {
          return a.name === b.name ? 0 : a.name > b.name ? 1 : -1;
        });
        resolve(templates);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const editTemplate = (
  id,
  name,
  content,
  withEvent,
  position,
  stage,
  type,
  attachments,
  timeDuration,
  templatesAttachmentsToDelete,
  companyId
) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("name", name);
    form.append("content", content);
    form.append("withEvent", withEvent);
    form.append("positionId", position);
    form.append("stageId", stage);
    form.append("type", type);
    form.append("timeDuration", timeDuration);
    form.append("companyId", companyId);
    form.append("templateId", id);
    for (let i = 0; i < templatesAttachmentsToDelete.length; i++) {
      form.append(
        "template_attachments_ids_toDelete",
        templatesAttachmentsToDelete[i]
      );
    }

    for (let i = 0; i < attachments.length; i++) {
      form.append("template_attachments", attachments[i]);
    }
    const url = TEMPLATE_URL.concat(EDIT_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createTemplate = (
  name,
  content,
  withEvent,
  position,
  stage,
  type,
  timeDuration,
  companyId
) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("name", name);
    form.append("content", content);
    form.append("withEvent", withEvent);
    form.append("positionId", position);
    form.append("stageId", stage);
    form.append("type", type);
    form.append("timeDuration", timeDuration);
    form.append("companyId", companyId);
    for (let i = 0; i < attachments.length; i++) {
      form.append("template_attachments", attachments[i]);
    }
    const url = TEMPLATE_URL.concat(CREATE_URL);
    HandleRequest({ url: url, method: POST, data: form })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
