import { Button } from "antd";
import { UPLOAD_ICON } from "../Resources/Icons";

export default function UploadButton(props) {
  const uploadButtonStyle = {
    border: "1px solid #042C77",
    borderRadius: "4px",
  };

  const uploadTextStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#042C77",
  };

  return (
    <Button onClick={props.onClick} style={uploadButtonStyle}>
      <span style={uploadTextStyle}>
        <UPLOAD_ICON fill="#042C77" />
      </span>
      <span style={{ marginLeft: "7px" }}>
        {props.text ? props.text : "Upload"}
      </span>
    </Button>
  );
}
