import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Button,
  Menu,
  Card,
  DatePicker,
  TimePicker,
  Upload,
} from "antd";
import FormInputField from "../FormInputField";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  addTime,
  currentDate,
  currentTime,
  formatTime,
} from "../../JSUtils/Time";
import DropDownInputField from "../DropDownInputField";
import UploadButton from "../UploadButton";
import {
  getEmailsByApplication,
  sendEmail,
} from "../../Redux/Actions/EmailsAction";
import { greyTextStyle, inputStyle, titleStyle } from "../../Resources/Styles";
import TextEditor from "../TextEditor";
import {
  ATTACHMENT_ICON,
  CLOSE_ICON,
  PLUS_BUTTON,
} from "../../Resources/Icons";
import AddTemplateModal from "../Modals/AddTemplateModal";

function EmailModal(props) {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.user.userCompany);

  const [receiverEmail, setReceiverEmail] = useState("");
  const [currentStage, setCurrentStage] = useState({});
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [subject, setSubject] = useState("");
  const [emailText, setEmailText] = useState("");
  const [showDateAndTime, setShowDateAndTime] = useState(false);
  const [testDate, setTestDate] = useState("");
  const [testTime, setTestTime] = useState("");
  const [candidateEmails, setCandidateEmails] = useState([]);
  const [replaceableDate, setReplaceableDate] = useState("");
  const [replaceableTime, setReplaceableTime] = useState("");
  const [filesList, setFilesList] = useState([]);

  const [templateModal, setTemplateModal] = useState(false);

  const stagesList = useSelector((state) => state.user.stages);
  const execludedEmails = useSelector(
    (state) => state.company?.execludedEmails
  );

  const stageMenu = (
    <Menu>
      {stagesList.map((stage) => {
        return (
          <Menu.Item key={stage.id} onClick={(e) => setCurrentStage(stage)}>
            {stage.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const templateMenu = (
    <Menu>
      {props.templates?.map((template) => {
        return (
          <Menu.Item
            key={template.name}
            onClick={() => changeTemplate(template)}
          >
            {template.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const candidateEmailsMenu = (
    <Menu>
      {candidateEmails.map((email) => {
        return (
          <Menu.Item key={email} onClick={() => setReceiverEmail(email)}>
            {email}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const attachmentItem = (name, closeIcon) => {
    return (
      <Col>
        <span>{ATTACHMENT_ICON}</span>
        <span className="text.black" style={{ marginLeft: "7px" }}>
          {name}
        </span>
      </Col>
    );
  };

  const changeTemplate = (template) => {
    if (!template) return;
    setCurrentTemplate({ ...template });
    replaceEmail(template);
  };

  const replaceEmail = (template = {}) => {
    if (template === {}) template = currentTemplate;
    if (!currentTemplate) return;
    let newTemplateContent = template.content.replace(
      "${PositionName}",
      props.position.name
    );
    newTemplateContent = newTemplateContent.replace(
      "${Name}",
      props.candidateName
    );
    let newTime = addTime(
      template.timeDuration ? template.timeDuration : 2,
      "hours"
    );
    newTemplateContent = newTemplateContent.replace("${TimeToFinish}", newTime);
    if (template.type === "Request") {
      let newStartDate = addTime(0, "days", "dddd MMM Do");
      newTemplateContent = newTemplateContent.replace(
        "${StartDate}",
        newStartDate
      );
      let newEndDate = addTime(
        template.timeDuration ? template.timeDuration : 10,
        "days",
        "dddd MMM Do"
      );
      newTemplateContent = newTemplateContent.replace("${EndDate}", newEndDate);
      resetDateAndTime();
    } else if (template.type === "Confirmation") {
      setShowDateAndTime(true);
      //   let newTestDate = formatTime(testDate, "MMMM Do YYYY");
      //   newTemplateContent = newTemplateContent.replace("${Date}", newTestDate);
      setReplaceableDate("${Date}");

      //   let newTestTime = formatTime(testTime, "MMMM Do YYYY");
      //   newTemplateContent = newTemplateContent.replace("${Time}", newTestTime);
      setReplaceableTime("${Time}");
    } else {
      resetDateAndTime();
    }
    setEmailText(newTemplateContent);
  };

  const resetDateAndTime = () => {
    setShowDateAndTime(false);
    setTestDate("");
    setTestTime("");
    setReplaceableDate("");
    setReplaceableTime("");
  };

  const changeTestTime = (time) => {
    setTestTime(time);

    var newEmailText = emailText;
    let newTestTime = formatTime(time, "h:mm a");
    newEmailText = newEmailText.replace(replaceableTime, newTestTime);
    setReplaceableTime(newTestTime);
    setEmailText(newEmailText);
  };

  const changeTestDate = (date) => {
    setTestDate(date);

    var newEmailText = emailText;
    let newTestDate = formatTime(date, "MMMM Do YYYY");
    newEmailText = newEmailText.replace(replaceableDate, newTestDate);
    setReplaceableDate(newTestDate);
    setEmailText(newEmailText);
  };

  const beforeUpload = (file) => {
    console.log(file);
    var tempFiles = filesList;
    tempFiles.push(file);
    setFilesList([...tempFiles]);
  };

  const onRemove = (file) => {
    var tempFiles = filesList;
    var index = -1;
    for (let i in tempFiles) {
      if (tempFiles[i].name === file.name) {
        index = i;
        break;
      }
    }
    console.log(tempFiles);
    tempFiles.splice(index, 1);
    console.log(tempFiles);
    setFilesList([...tempFiles]);
  };

  const onModalClose = () => {
    setFilesList([]);
    props.setEmailModal(!props.visible);
  };

  const send = () => {
    var sendDate = currentDate();
    var sendTime = currentTime();
    dispatch(
      sendEmail(
        props.emailId,
        props.applicationId,
        receiverEmail,
        subject,
        currentTemplate.id,
        currentStage.id,
        emailText,
        filesList,
        true,
        sendDate,
        sendTime,
        false,
        "",
        0,
        companyId
      )
    ).then((res) => {
      props.setEmailModal(!props.visible);
      dispatch(getEmailsByApplication(props.applicationId)).then(
        (newEmails) => {
          props.setEmails(newEmails.data);
        }
      );
      console.log(res);
    });
  };

  useEffect(() => {
    if (props.templates) {
      changeTemplate(props.templates[0]);
    }
  }, [props.templates]);

  useEffect(() => {
    if (props.subject) setSubject(props.subject);
  }, [props.subject]);

  useEffect(() => {
    if (!execludedEmails) return;
    let emailsArray = [];
    if (Array.isArray(props.receiverEmail))
      emailsArray.push(...props.receiverEmail);
    else emailsArray.push(props.receiverEmail);

    if (Array.isArray(props.senderEmail))
      emailsArray.push(...props.senderEmail);
    else emailsArray.push(props.senderEmail);

    for (let i = 0; i < emailsArray.length; i++) {
      if (execludedEmails.indexOf(emailsArray[i]) != -1) {
        emailsArray.splice(i, 1);
      }
      setCandidateEmails(emailsArray);
      setReceiverEmail(emailsArray[0]);
    }
  }, [props.receiverEmail, props.senderEmail, execludedEmails]);

  return (
    <Modal
      visible={props.visible}
      title={<span style={titleStyle}> Compose </span>}
      onCancel={() => onModalClose()}
      maskClosable={false}
      okText="Send"
      onOk={() => send()}
    >
      <Row justify="space-between" gutter={[15, 15]}>
        <Col span={12}>
          <DropDownInputField
            title="To: "
            value={receiverEmail}
            onChange={setReceiverEmail}
            overlay={candidateEmailsMenu}
          ></DropDownInputField>
        </Col>

        <Col span={12}>
          <DropDownInputField
            title="Stage"
            overlay={stageMenu}
            value={currentStage.name}
          />
        </Col>

        {showDateAndTime ? (
          <>
            <Col span={12}>
              <DatePicker
                placeholder=""
                onChange={(date, dateString) => {
                  changeTestDate(date);
                }}
                style={{ ...inputStyle, width: "100%" }}
              />
            </Col>
            <Col span={12}>
              <TimePicker
                placeholder=""
                showSecond={false}
                onChange={(time, timeString) => {
                  changeTestTime(time);
                }}
                style={{ ...inputStyle, width: "100%" }}
              />{" "}
            </Col>
          </>
        ) : null}
      </Row>
      <Row gutter={[15, 15]}>
        <Card style={{ marginTop: "10px" }}>
          <Row>
            <div
              style={{
                backgroundColor: "rgba(70, 84, 167, 0.05)",
                width: "100%",
              }}
            >
              <Row align="middle" justify="space-betw">
                <Col span={22}>
                  <DropDownInputField
                    title="Template"
                    value={currentTemplate?.name}
                    overlay={templateMenu}
                  />
                </Col>
                <Col span={2}>
                  <span
                    style={{ padding: "10px" }}
                    onClick={() => setTemplateModal(true)}
                  >
                    {PLUS_BUTTON}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col span={22}>
                  <FormInputField
                    title="Subject"
                    value={subject}
                    onChange={setSubject}
                    placeholder="subject..."
                  />
                </Col>
              </Row>
            </div>
          </Row>

          <Row style={{ marginTop: "7px" }}>
            <TextEditor value={emailText} onChange={setEmailText} />
          </Row>
        </Card>
      </Row>
      {currentTemplate?.attachments ? (
        <Row>
          <span style={greyTextStyle}>Extra Attachments</span>
        </Row>
      ) : null}
      <Row
        gutter={[15, 15]}
        justify="space-between"
        style={{ width: "100%", marginBottom: "20px" }}
      >
        {currentTemplate?.attachments?.map((attachment) => {
          return attachmentItem(attachment.name, false);
        })}
      </Row>
      <Row gutter={[15, 15]}>
        <Col>
          <Upload
            name="attachment"
            showUploadList={true}
            beforeUpload={beforeUpload}
            onRemove={onRemove}
          >
            <UploadButton />
          </Upload>
        </Col>
      </Row>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(EmailModal);
