import moment from "moment";
import "../../App.css";

export const getCandidateApplication = (application) => {
  return {
    id: application["id"],
    position: {
      name: application["position__name"],
      id: application["position__id"],
      duration: application["position__time_duration"],
    },
    source: application["source"],
    status: application["status"],
    stage: { id: application["stage__id"], name: application["stage__name"] },
    numberOfExperience: application["no_of_year_of_experience"],
    notes: application["notes"],
    rejectionReason: application["rejectionReason"],
    applied_date: application["applied_date"],
    waitingForSupport: application["waiting_for_support"],
    lastEmailDate: application["last_updated"],
  };
};
export const formatCandidates = (applications) => {
  // let dictRes = {}

  for (let i in applications) {
    let app = applications[i];
    applications[i]["last_updated"] = moment(app["last_updated"]).format("L");
    applications[i]["candidate__emails"] = app["candidate__emails"].slice(
      2,
      -2
    );

    if (app["status"] === "In Process")
      applications[i]["statusFormat"] = (
        <span>
          In Process
          <span className="dot" style={{ backgroundColor: "#5ECD4C" }} />{" "}
        </span>
      );
    else if (app["status"] === "Rejected")
      applications[i]["statusFormat"] = (
        <span>
          Rejected
          <span className="dot" style={{ backgroundColor: "#FB6949" }} />{" "}
        </span>
      );
    else if (app["status"] === "On Hold")
      applications[i]["statusFormat"] = (
        <span>
          On Hold
          <span className="dot" style={{ backgroundColor: "#FBDE49" }} />{" "}
        </span>
      );
    else if (app["status"] === "Hired")
      applications[i]["statusFormat"] = (
        <span>
          Hired
          <span className="dot" style={{ backgroundColor: "#49A5FB" }} />{" "}
        </span>
      );
    else
      applications[i]["statusFormat"] = (
        <span>
          In Process
          <span className="dot" style={{ backgroundColor: "#5ECD4C" }} />{" "}
        </span>
      );
  }
  function compare(a, b) {
    const x = new Date(a.last_updated);
    const y = new Date(b.last_updated);

    if (x > y) {
      return -1;
    }
    if (x < y) {
      return 1;
    }
    return 0;
  }

  applications.sort(compare);
  return applications;
};

export const StatusFormatter = (status) => {
  if (status === "In Process")
    return (
      <span>
        In Process
        <span className="dot" style={{ backgroundColor: "#5ECD4C" }} />{" "}
      </span>
    );
  else if (status === "Rejected")
    return (
      <span>
        Rejected
        <span className="dot" style={{ backgroundColor: "#FB6949" }} />{" "}
      </span>
    );
  else if (status === "On Hold")
    return (
      <span>
        On Hold
        <span className="dot" style={{ backgroundColor: "#FBDE49" }} />{" "}
      </span>
    );
  else if (status === "Hired")
    return (
      <span>
        Hired
        <span className="dot" style={{ backgroundColor: "#49A5FB" }} />{" "}
      </span>
    );
  else
    return (
      <span>
        In Process
        <span className="dot" style={{ backgroundColor: "#5ECD4C" }} />{" "}
      </span>
    );
};

export const StatusDotFormatter = (status) => {
  if (status === "In Process")
    return <span className="dot" style={{ backgroundColor: "#5ECD4C" }} />;
  else if (status === "Rejected")
    return <span className="dot" style={{ backgroundColor: "#FB6949" }} />;
  else if (status === "On Hold")
    return <span className="dot" style={{ backgroundColor: "#FBDE49" }} />;
  else if (status === "Hired")
    return <span className="dot" style={{ backgroundColor: "#49A5FB" }} />;
  else return <span className="dot" style={{ backgroundColor: "#5ECD4C" }} />;
};
